<nav class="navbar navbar-inverse fixed-bottom navbar-light bg-light mt-5">
  <!-- <a class="navbar-brand" href="#">Navbar</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> -->
  <div class="navbar-collapse">
    <ul
      class="navbar-nav d-flex align-items-center justify-content-between mb-0 mt-2"
    >
      <li class="nav-item">
        <a class="nav-link" routerLink="/analysis" routerLinkActive="active">
          <img
            src="../../../assets/img/bottomNavbar/analysis.svg"
            alt="Analysis"
            class="img-fluid"
          />

          <br />Analysis</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/vyas" routerLinkActive="active"
          ><img
            src="../../../assets/img/bottomNavbar/vyas.svg"
            alt="budget"
            class="img-fluid"
          /><br />Vyas</a
        >
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="/budget"
          ><img
            src="../../../assets/img/bottomNavbar/budget1.svg"
            alt="budget"
            class="img-fluid"
          /><br />Budget</a
        >
      </li> -->
      <li class="nav-item">
        <a class="nav-link" routerLink="/dashboard" routerLinkActive="active"
          ><img
            src="../../../assets/img/bottomNavbar/ohm.svg"
            alt="budget"
            class="img-fluid ml-3"
            style="width: 30px; height: auto" /><br
        /></a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="https://community.ohmassistant.com/"
          target="_blank"
          ><img
            src="../../../assets/img/bottomNavbar/community.svg"
            alt="budget"
            class="img-fluid"
          /><br />Community</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/more" routerLinkActive="active"
          ><img
            src="../../../assets/img/bottomNavbar/more.svg"
            alt="contact"
            class="img-fluid"
          /><br />Explore</a
        >
        <!-- <a class="nav-link" routerLink="/support"><img src="../../../assets/icons/support.svg" alt="budget"
                          class="img-fluid"><br>Support</a> -->
      </li>
    </ul>
  </div>
</nav>
