import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vyas-onboarding-page-two',
  templateUrl: './vyas-onboarding-page-two.component.html',
  styleUrls: ['./vyas-onboarding-page-two.component.scss'],
})
export class VyasOnboardingPageTwoComponent implements OnInit {
  applianceMap: any;
  constructor(private http: HttpClient) {}

  @Input() applianceCounts;
  ngOnInit(): void {
    this.http
      .get('../../../../assets/json/vyasApplianceList.json')
      .subscribe((applianceMap) => {
        this.applianceMap = applianceMap;
      });
  }

  incrementCount(applianceName, applianceType) {
    this.applianceCounts[applianceName][applianceType]++;
  }

  decrementCount(applianceName, applianceType) {
    this.applianceCounts[applianceName][applianceType]--;
  }
}
