<section>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-3 red__background">
        <h1 class="text-center text-light font-weight-bold">2</h1>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <h4 class="font-weight-bold text-center">Profile</h4>
        <p class="mt-3">
          The more detailed your information is, the better the analysis and
          recomendation for you. At Ohm, we value your privacy. Please read more
          here.
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12 mt-3">
        <form
          #form_profile="ngForm"
          class="form_profile"
          id="form_profile"
          name="form_profile"
        >
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Enter Name"
              id="name"
              [(ngModel)]="name"
              name="name"
              required
            />
          </div>
          <div class="input-group mb-3">
            <input
              type="email"
              class="form-control"
              placeholder="Enter Email"
              id="email"
              [(ngModel)]="email"
              name="email"
              required
            />
          </div>

          <div class="row mt-5">
            <div class="col-md-12">
              <button type="button" class="btn w-100 mt-5" (click)="profile()">
                <img src="../../../../assets/img/Login/button.png" alt="" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
