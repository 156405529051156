import { Component, Input, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { CommonApiService } from "src/app/service/common-api.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-create-ticket",
  templateUrl: "./create-ticket.component.html",
  styleUrls: ["./create-ticket.component.scss"],
})
export class CreateTicketComponent implements OnInit {
  @Input() meter_id: string;
  user: any;
  meters: any;
  record_type_id: any;
  sub_type: any;
  description: any;
  subject: any;
  issueType: any;
  selectedIssueType;
  ngForm: NgForm;
  selectSubType: any;
  constructor(private _api: CommonApiService, private _router: Router) {}

  ngOnInit(): void {
    this.meter_id = this._api.getSelectedMeterId();
    this.user = JSON.parse(localStorage.getItem("user"));
    this.meters = this.user["meters"];
    this.issueType = [
      { name: "Firmware", value: "a0P0w000003vRvgEAE" },
      { name: "General", value: "0120w00000179tfAAA" },
      { name: "Hardware", value: "0120w00000179tLAAQ" },
      { name: "Onboarding", value: "0120w00000179taAAA" },
      { name: "Software", value: "0120w00000179tVAAQ" },
      { name: "Others", value: "0120w00000179tkAAA" },
    ];
    // this.selectSubType = ["Frequent disconnection", "Data storage", "None"];
    if(this.issueType[0]) {
      this.selectSubType = ["Frequent disconnection", "Data storage", "None"];
    }
  }

  selectedSubType(event) {
    this.selectSubType = event.target.value;
    console.log(event.target.value);
  }

  issueTypeSelect(event) {
    console.log(event.target.value);
    this.selectedIssueType = event.target.value;

    // this.issueType.forEach((element) => {
    //  console.log(element.target.value);

    if (event.target.value === "a0P0w000003vRvgEAE") {
      this.sub_type = ["Frequent disconnection", "Data storage", "None"];
    }
    if (event.target.value === "0120w00000179tfAAA") {
      this.sub_type = [
        "Replacement",
        "Return/refund",
        "Product training",
        "None",
      ];
    }
    if (event.target.value === "0120w00000179tLAAQ") {
      this.sub_type = [
        "No Hotspot",
        "NaN values",
        "EC reset",
        "Installation",
        "None",
      ];
    }
    if (event.target.value === "0120w00000179taAAA") {
      this.sub_type = [
        "Wifi pairing",
        "Mapping",
        "Installation support",
        "None",
      ];
    }
    if (event.target.value === "0120w00000179tkAAA") {
      this.sub_type = ["Others"];
    }
    if (event.target.value === "0120w00000179tVAAQ") {
      this.sub_type = [
        "Data discrepancy",
        "Analysis",
        "Budget",
        "Vyas",
        "energy statement",
        "new feature request",
        "None",
      ];
    }
    // });
  }
  onSubmit(f: NgForm) {
    if (f.valid) {
      let formData = {
        subject: this.subject,
        description: this.description,
        meter_id: this.meter_id,
        record_type_id: this.record_type_id,
        sub_type: this.selectSubType,
      };

      this._api
        .createTicket(formData)
        .pipe(first())
        .subscribe((res) => {
          Swal.fire({
            text: "Successfully created",
            icon: "success",
            confirmButtonColor: "#E50021",
          });
          this._router.navigate(["/track-issue"]);
        });

      console.log(this.meter_id);
      
    }
  }
}
