<nav class="navbar navbar-expand-lg navbar-light transparent d-flex align-item-center justify-content-between mt-md-2">
  <div class="col-6 p-0 d-flex align-items-center">
    <!-- <a routerLink="/dashboard">
      <img
        src="../../../assets/img/dashboard/sidebar.png"
        alt=""
        class="img-fluid"
    /></a> -->
    <!-- <div id="overlay"></div> -->
    <div id="mySidenav" class="sidenav">
      <!-- <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a> -->
      <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">
        <img src="../../../assets/img/sidebar/close.png" alt="" class="img-fluid">
      </a>
      <div class="container" style="margin-top: -15px">
        <div class="col-5 d-flex justify-content-center align-items-center">
          <img src="https://www.w3schools.com/howto/img_avatar.png" alt="Avatar" class="avatar img-fluid d-block m-auto" />
        </div>
        <div class="col-7 d-flex flex-column justify-content-center mt-3 p-0 mt-2">
          <h6 class="text-light font-weight-bolder" *ngIf="user">
            {{ user.displayName }}
          </h6>
          <p class="text-light">Ohm Assistant</p>
        </div>

        <div class="mt-4 sidebar__list">
          <ul>
            <li>
              <a routerLink="/user-profile">
                <img src="../../../assets/img/sidebar/account.svg" alt="account" />
                <span>My Account</span></a>
            </li>
            <hr />
            <li>
              <a routerLink="/my-bot">
                <img src="../../../assets/img/sidebar/bot.png" alt="account" />
                <span>My Bot(s)</span></a>
            </li>
            <hr />
            <!-- <li>
              <a>
                <img src="../../../assets/img/sidebar/settings.png" alt="account" />
                <span>Settings </span></a>
            </li>
            <hr /> -->

            <li>
              <a href="https://ohmassistant.com/Privacy-Policy-SustLabs" target="_blank">
                <img src="../../../assets/img/sidebar/privacy.svg" alt="account" />
                <span>Privacy</span></a>
            </li>
            <hr />
              <li>
              <a href="https://ohmassistant.com/Terms-and-Conditions" target="_blank">
                <img src="../../../assets/img/sidebar/privacy.svg" alt="account" />
                <span>Terms and Conditions</span></a>
            </li>
            <hr />

            <!-- <li>
              <a>
                <img src="../../../assets/img/sidebar/faq.svg" alt="account" />
                <span>Help & Support</span></a>
            </li>
            <hr />
            <li>
              <a>
                <img src="../../../assets/img/sidebar/faq.svg" alt="account" />
                <span>Voice Assistant Pairing</span></a>
            </li>
            <hr /> -->
            <li>
              <a (click)="logout()" style="cursor: pointer">
                <img src="../../../assets/img/sidebar/logout.svg" alt="account" />
                <span>Logout </span></a>
            </li>
            <hr />
            <p class="text-light">Version: 0.7.16</p>
            <!-- <p>Last Updated on</p>
            <p>23 Sept,2020 at 10:30am</p> -->
          </ul>
        </div>
      </div>
    </div>
    <span style="font-size: 24px; cursor: pointer; margin: 0px 10px 0px 10px" (click)="openNav()">&#9776;
    </span>
  </div>
  <div class="form-inline my-2 my-lg-0 mt-md-0 mt-3">
    <div class="form-group">
      <!-- <span class="font-weight-bold">Device 01 </span> -->
      <select id="exampleFormControlSelect1" class="font-weight-bold minimal" [ngModel]="selectedMeterId"
        (ngModelChange)="onMeterChange($event)">
        <option *ngFor="let meter of meters" value="{{ meter }}">
          Device {{ meter }}
        </option>
      </select>
    </div>
  </div>
</nav>