<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-6 p-0 d-flex align-items-center">
            <a routerLink="/programs">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get"
              >Program Training</a
            >
          </div>
          <div class="form-inline my-2 my-lg-0">
            <!-- <img src="../../../assets/img/menu.png" alt="" /> -->
          </div>
        </nav>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12 more__card mt-4 px-4">
        <h3 class="mt-2">Product Training</h3>
        <p class="mb-0 mt-5">
          Confusion with Ohm Assistant features?
          <span>Not anymore</span>
        </p>
        <p>We now have a product demo room which opens</p>

        <h5 class="mt-5">Every Saturday · 11:00am – 12:00pm</h5>

        <h5 class="mt-5 ptmd-5">See you here!</h5>

        <a href="https://meet.google.com/mst-pkqi-jup" target="_blank">
          https://meet.google.com/mst-pkqi-jup
        </a>
      </div>
      <div class="col-md-12 mt-5 btn__bottom">
        <button class="btn">Join Us</button>
      </div>
    </div>
  </div>
</section>
