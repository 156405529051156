<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-6 p-0 d-flex align-items-center">
            <a routerLink="/programs">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get"
              >Energy Efficiency</a
            >
          </div>
          <div class="form-inline my-2 my-lg-0">
            <!-- <img src="../../../assets/img/menu.png" alt="" /> -->
          </div>
        </nav>
      </div>
    </div>
    <div class="row mt-5 more__card">
      <div class="col-md-12 mt-4 px-3">
        <img
          src="../../../../assets/img/more/energy.png"
          alt=""
          class="img-fluid d-block m-auto"
        />
        <h5 class="font-weight-bold mt-4 text-center">
          Energy efficiency program
        </h5>
        <br />
        <p class="text-center">
          Reduce your consumption and we provide you incentives based on
          percentage saved
        </p>
        <h6>Assistance provided:</h6>
        <ul>
          <li>2 person team</li>
          <li>1 mandatory woman participant</li>
          <li>Drive to reduce energy consumption</li>
        </ul>
        <br />
        <h6>Requirements:</h6>
        <ul>
          <li>Individual consultation available</li>
          <li>Performance report every 15 days</li>
          <li>Tips to reduce your energy consumption</li>
        </ul>

        <p class="text-center mt-5" style="color: #80868b">
          **the savings will be calculated after factoring in lockdown, climate
          etc
        </p>
      </div>

      <div
        class="col-md-12 btn__bottom d-flex justify-content-center mt-4 mb-3"
      >
        <button class="btn" (click)="joinus()">Enroll Now</button>
      </div>
    </div>
  </div>
</section>
