<app-loader *ngIf="loader"></app-loader>
<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent d-flex justify-content-between">
          <div class="col-6 p-0 d-flex align-items-center">
            <a routerLink="/vyas">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-md-3 pr-3 d-lg-inline-block title_get">
              Details
            </a>
          </div>
          <div class="form-inline my-2 my-lg-0 mr-2" (click)="deleteAppliance(appliance._id)">
            <a style="cursor: pointer">
              <img
                src="../../../../../assets/img/vyas/delete1.svg"
                alt="delete"
                class="img_fluid delete_img"
            /></a>
          </div>
        </nav>
      </div>
    </div>
    <div class="row top__row">
      <div class="col-12 mt-5">
        <div class="mt-3 d-flex justify-content-around">
          <button
            class="btn top_btn"
            [ngClass]="{ active: currentStep == 0 }"
            data-toggle="tab"
            (click)="gotoStep(0)"
          >
            Profile
          </button>
          <button
            class="btn top_btn"
            [ngClass]="{ active: currentStep == 1 }"
            data-toggle="tab"
            (click)="gotoStep(1)"
          >
            Warranty
          </button>
          <button
            class="btn top_btn"
            [ngClass]="{ active: currentStep == 2 }"
            data-toggle="tab"
            (click)="gotoStep(2)"
          >
            Services
          </button>
          <button
            class="btn top_btn"
            [ngClass]="{ active: currentStep == 3 }"
            data-toggle="tab"
            (click)="gotoStep(3)"
            *ngIf="
              appliance.applianceName === 'AC' ||
              appliance.applianceName === 'GEYSER'
            "
          >
            Health
          </button>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <div *ngIf="currentStep == 0">
          <app-appliance-profile-details
            [(appliance)]="appliance"
          ></app-appliance-profile-details>
        </div>
        <div *ngIf="currentStep == 1">
          <app-appliance-warranty-details
            [(appliance)]="appliance"
          ></app-appliance-warranty-details>
        </div>
        <div *ngIf="currentStep == 2">
          <app-appliance-service-details
            [(appliance)]="appliance"
          ></app-appliance-service-details>
        </div>
        <div *ngIf="currentStep == 3">
          <app-appliance-health-details
            [(appliance)]="appliance"
          ></app-appliance-health-details>
        </div>
      </div>
    </div>
  </div>
</section>
