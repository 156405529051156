<app-loader *ngIf="loader"></app-loader>
<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <app-navbar></app-navbar>
      </div>
    </div>
    <div class="row top__row mt-5">
      <div class="col-6 d-flex justify-content-center mt-3">
        <button
          class="btn top_btn"
          (click)="getYesterday()"
          [ngClass]="{ active: clicked === true }"
          data-toggle="tab"
          (click)="clicked = true; clicked1 = false"
        >
          Today
        </button>
      </div>
      <div class="col-6 d-flex justify-content-center mt-3">
        <button
          class="btn top_btn"
          (click)="getLast30days()"
          [ngClass]="{ active: clicked1 === true }"
          data-toggle="tab"
          (click)="clicked = false; clicked1 = true"
        >
          {{ currentMonth }}
        </button>
      </div>
    </div>
    <div class="row" style="margin-top: 120px">
      <div class="col-md-12">
        <canvas id="myAnalysisChart"></canvas>
        <p
          *ngIf="total_energy"
          class="total_energy"
          routerLink="/detailed-analysis"
          style="cursor: pointer"
        >
          Today <br /><span>{{ total_energy.toFixed(0) }} Units</span>
        </p>
      </div>
      <div class="col-md-12">
        <p *ngIf="!total_energy" class="text-center">No datafound</p>
      </div>
    </div>

    <div class="row mt-5 percentage_data">
      <div
        class="col-6 d-flex justify-content-center align-items-center flex-column"
      >
        <!-- <h5>LOW</h5> -->
        <h5>---</h5>
        <p>Confidence</p>
      </div>
      <div
        class="col-6 d-flex justify-content-center align-items-center flex-column"
      >
        <h5 *ngIf="uptimePercentage">{{ uptimePercentage.toFixed(0) }}%</h5>
        <h5 *ngIf="!uptimePercentage">---</h5>
        <p>Bot Live</p>
      </div>
    </div>
    <div class="row analysis__row mt-5">
      <div class="col-7 offset-md-1">
        <h6 *ngIf="applianceUsage.length !== 0">Appliance Activity</h6>
        <!-- <h6 class="text-center" *ngIf="applianceUsage.length === 0">
          Appliance Activity(0)
        </h6> -->
      </div>
      <div
        class="col-md-12 mt-4"
        *ngFor="let applianceUsage of applianceUsage"
        style="cursor: pointer"
        data-toggle="modal"
        (click)="applianceTagModal(applianceUsage)"
      >
        <div class="row analysis__list pb-3 align-items-center">
          <div
            class="col-md-1 offset-md-1 col-2 d-flex align-items-center pr-md-0 p-md-1"
          >
            <img
              src="../../../assets/img/appliance/gyser.svg"
              alt=""
              class="img-fluid"
              *ngIf="applianceUsage.applianceName == 'GEYSER'"
            />
            <img
              src="../../../assets/img/appliance/spiltAC.svg"
              alt=""
              class="img-fluid"
              *ngIf="applianceUsage.applianceName == 'AC'"
            />
            <img
              src="../../../assets/img/appliance/wm.svg"
              alt=""
              class="img-fluid"
              *ngIf="applianceUsage.applianceName == 'WM'"
            />
          </div>
          <div class="col-4 p-0">
            <p class="text-left mb-0">{{ applianceUsage.appliance }}</p>
          </div>
          <div class="col-6">
            <p class="text-right mb-0" style="color: #80868b">
              {{ applianceUsage.start | date: "MMM dd YYYY, hh:mm a" }}
            </p>
          </div>
        </div>

        <!-- Model -->
        <div
          class="modal fade modal-bottom pb-5"
          id="energySelectDateModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div
              class="modal-content pb-5"
              style="
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
              "
            >
              <div class="modal-body" *ngIf="applianceTag">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <h4 class="font-weight-bold">
                        {{ applianceTag.appliance }}
                      </h4>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-8">
                      It look’s like an {{ applianceTag.appliance }}
                    </div>
                    <div class="col-4 text-right">
                      {{ applianceTag.start | date: "shortTime" }}
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div
                      class="col-md-12 d-flex justify-content-between align-items-center"
                    >
                      <button
                        class="btn btn_yes"
                        (click)="tagAppliance(applianceTag)"
                        [disabled]="applianceTag.answered === true"
                      >
                        Tag an appliance
                      </button>
                      <button
                        class="btn text-dark"
                        (click)="wrongDetection(applianceTag)"
                      >
                        Wrong detection
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="applianceUsage.length === 0">
        <p class="mt-5 text-center">No Data Found</p>
      </div>
    </div>
  </div>

  <app-bottom-navbar></app-bottom-navbar>
</section>
