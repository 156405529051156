import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { CommonApiService } from "src/app/service/common-api.service";

@Component({
  selector: "app-wrong-appliance-tagged",
  templateUrl: "./wrong-appliance-tagged.component.html",
  styleUrls: ["./wrong-appliance-tagged.component.scss"],
})
export class WrongApplianceTaggedComponent implements OnInit {
  loader: boolean = false;

  question_id: any;
  constructor(
    public _router: Router,
    private route: ActivatedRoute,
    private _api: CommonApiService
  ) {}

  ngOnInit(): void {
    this.question_id = this.route.snapshot.params["question_id"];
  }

  notSure() {
    this._api
      .verifyApplianceUsageWrong(this.question_id, "no")
      .pipe(first())
      .subscribe((res) => {
        if (res) {
          try {
            this._router.navigate(["/analysis"]);
          } catch (error) {
            console.log(error);
          }
        }
      });
  }

  selectAppliance(applianceName) {
    this._router.navigateByUrl(
      `/select-appliance/wrong_detection/${this.question_id}/${applianceName}`
    );
  }
}
