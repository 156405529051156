<app-loader *ngIf="loader"></app-loader>
<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-12 p-0 d-flex align-items-center">
            <a routerLink="/analysis">
              <img src="../../../../assets/img/arrow-left.png" alt=""
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get text-light"
              >Detailed Analysis</a
            >
          </div>
          <div class="form-inline my-2 my-lg-0"></div>
        </nav>
      </div>
    </div>
    <div class="row top__row mt-5">
      <div class="col-6 d-flex justify-content-center mt-3">
        <button
          class="btn top_btn"
          [ngClass]="{ active: clicked === true }"
          data-toggle="tab"
          (click)="clicked = true; clicked1 = false"
          (click)="getAnalysis()"
        >
          Daily
        </button>
      </div>
      <div class="col-6 d-flex justify-content-center mt-3">
        <button
          class="btn top_btn"
          [ngClass]="{ active: clicked1 === true }"
          data-toggle="tab"
          (click)="clicked = false; clicked1 = true"
          (click)="getMonthAnalysis()"
        >
          Monthly
        </button>
      </div>
    </div>
    <div class="row" style="margin-top: 120px">
      <div class="col-12">
        <h3 class="text-center font-weight-bold" *ngIf="total_energy">
          {{ total_energy }} Units
        </h3>
        <!-- <h3 class="text-center font-weight-bold" *ngIf="energy_consumption">
          {{ energy_consumption }}
        </h3> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <canvas id="myDetailedAnalysisChart"></canvas>
      </div>
    </div>
    <div class="row date__row mt-4" *ngIf="clicked === true">
      <div class="col-md-12 d-flex align-items-center justify-content-center">
        <button (click)="leftDailyClick()" *ngIf="weekOfSet > -6">
          <i class="fa fa-chevron-left"></i>
        </button>
        <!-- <small
          >{{ startTime | date: "mediumDate" }} -
          {{ endTime | date: "mediumDate" }}</small
        > -->
        <small>{{ selectedDate }}</small>
        <button (click)="rightDailyClick()" *ngIf="weekOfSet != 0">
          <i class="fa fa-chevron-right"></i>
        </button>
      </div>
    </div>
    <div class="row date__row mt-4" *ngIf="clicked1 === true">
      <div class="col-md-12 d-flex align-items-center justify-content-center">
        <button>
          <i
            class="fa fa-chevron-left"
            (click)="leftMonthClick()"
            *ngIf="yearOfSet > yearsFrom2018"
          ></i>
        </button>
        <!-- <small>{{ startTime }} - {{ endTime }}</small> -->
        <small>{{ selectedMonth }}</small>
        <button>
          <i
            class="fa fa-chevron-right"
            (click)="rightMonthClick()"
            *ngIf="yearOfSet != 0"
          ></i>
        </button>
      </div>
    </div>
    <div class="row mt-5 percentage_data">
      <div
        class="col-6 d-flex justify-content-center align-items-center flex-column"
      >
        <!-- <h5>LOW</h5> -->
        <h5>---</h5>
        <p>Events</p>
      </div>
      <div
        class="col-6 d-flex justify-content-center align-items-center flex-column"
      >
        <h5 *ngIf="uptimePercentage">{{ uptimePercentage.toFixed(0) }}%</h5>
        <h5 *ngIf="!uptimePercentage">---</h5>
        <p>Bot Live</p>
      </div>
    </div>

    <div class="row analysis__row mt-5 mb-5">
      <div class="col-12 px-md-5">
        <h6 class="text-start">Total Consumption</h6>
      </div>
      <div
        class="col-md-12 mt-4"
        *ngFor="let usage of breakDownData; let i = index"
      >
        <div class="row analysis__list align-items-center pb-3">
          <div
            class="col-md-1 offset-md-1 col-2 d-flex align-items-center pr-md-0 p-md-1"
          >
            <img
              src="../../../../assets/img/appliance/gyser.svg"
              alt=""
              class="img-fluid"
              *ngIf="usage.device_name == 'Geyser'"
            />
            <img
              src="../../../../assets/img/appliance/wm.svg"
              alt=""
              class="img-fluid"
              *ngIf="usage.device_name == 'Washing Machine'"
            />
            <img
              src="../../../../assets/img/appliance/fridge.svg"
              alt=""
              class="img-fluid"
              *ngIf="usage.device_name == 'Fridge'"
            />
            <img
              src="../../../../assets/img/appliance/others.svg"
              alt=""
              class="img-fluid"
              *ngIf="usage.device_name == 'Others'"
            />
          </div>
          <div class="col-5 p-0">
            <p class="text-start mb-0">{{ usage.device_name }}</p>
          </div>
          <div class="col-4">
            <!-- <div
              style="width: 25px; height: 8px; border-radius: 50px"
              [ngStyle]="{ 'background-color': usage.color }"
            ></div>

            <div
              style="width: 25px; height: 8px; border-radius: 50px"
              [ngStyle]="{ 'background-color': breakDownData.color }"
            ></div> -->
            <p class="text-center mt-2 mb-0" style="color: #80868b">
              {{ usage.consumption }} units
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
