<form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
  <div class="row">
    <div class="col-12">
      <div class="form-group mt-2">
        <label for="nickName" *ngIf="mode == 'EDIT'">Nickname</label>
        <input
          type="text"
          class="form-control"
          id="nickName"
          aria-describedby="emailHelp"
          placeholder="Nickname"
          [(ngModel)]="appliance.applianceNickname"
          name="applianceNickname"
          required
        />
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="model" *ngIf="mode == 'EDIT'">Model</label>
        <input
          type="text"
          class="form-control"
          id="model"
          aria-describedby="emailHelp"
          placeholder="Model"
          [(ngModel)]="appliance.applianceModelNo"
          name="applianceModelNo"
          data-toggle="modal"
          data-target="#modal-fullscreen-xl"
          data-backdrop="false"
          data-keyboard="false"
          
        />
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="manufactureName" *ngIf="mode == 'EDIT'">Brand name</label>
        <input
          type="text"
          class="form-control"
          id="manufactureName"
          aria-describedby="emailHelp"
          placeholder="Brand Name"
          [(ngModel)]="appliance.applianceBrand"
          name="applianceBrand"
          
        />
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="typeAppliance" *ngIf="mode == 'EDIT'">Appliance Type</label>
        <select
          class="form-control"
          id="typeAppliance"
          [(ngModel)]="appliance.applianceType"
          name="applianceType"
          required
        >
          <option disabled selected value="undefined">Type</option>
          <option *ngFor="let type of type" [ngValue]="type.value">
            {{ type.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="capacity" *ngIf="mode == 'EDIT'">Capacity</label>
        <input
          type="number"
          class="form-control"
          id="capacity"
          aria-describedby="emailHelp"
          placeholder="Capacity"
          [(ngModel)]="appliance.applianceCapacity"
          name="applianceCapacity"
        />
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="rating" *ngIf="mode == 'EDIT'">Star Rating</label>
        <!-- <input
            type="text"
            class="form-control"
            id="rating"
            aria-describedby="emailHelp"
            placeholder="Star Rating"
            [(ngModel)]="appliance.applianceStar"
            name="applianceStarRating"
            required
          /> -->
        <select
          class="form-control"
          id="rating"
          [(ngModel)]="appliance.applianceStar"
          name="applianceStar"
        >
          <option disabled selected value="undefined">Star Rating</option>
          <option *ngFor="let rating of rating" [ngValue]="rating.value">
            {{ rating.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="purchasing" *ngIf="mode == 'EDIT'"
          >Year of purchasing</label
        >
        <select
          id="years"
          class="form-control"
          [(ngModel)]="appliance.applianceDatePurchase"
          name="purchasing"
        >
          <option disabled selected value="undefined">
            Year of purchasing
          </option>
        </select>
        <!-- <input
            type="text"
            class="form-control"
            id="purchasing"
            aria-describedby="emailHelp"
            placeholder="Year of purchasing"
            [(ngModel)]="appliance.applianceDatePurchase"
            name="applianceDatePurchase"
            required
          /> -->
      </div>
    </div>
  </div>

  <div class="row mt-3 submit_row">
    <div class="col-12">
      <button class="btn d-block m-auto w-50" type="submit" [disabled]="!f.form.valid">
        Submit & Next
      </button>
    </div>
  </div>
</form>

<!-- MODAL -->
<div
  class="modal modal-fullscreen-xl mt-5"
  id="modal-fullscreen-xl"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  *ngIf="modal_show"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <nav class="navbar navbar-expand-lg navbar-light transparent">
        <div class="col-6 p-0 d-flex align-items-center">
          <a
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style="opacity: 1 !important; cursor: pointer"
          >
            <img
              src="../../../../../assets/img/arrow-left.png"
              alt="back_button"
          /></a>
          <a class="mb-0 pl-2 pr-2 d-lg-inline-block">Search Appliance</a>
        </div>
      </nav>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <!-- <div class="card">
              <div class="card-body"> -->
            <div class="form-group mt-2">
              <label for="searchModel">Search Model Number</label>
              <input
                type="text"
                class="form-control"
                id="searchModel"
                aria-describedby="searchModel"
                name="searchModel"
                [autofocus]="true"
                (keyup)="applianceFetch($event)"
                required
              />
            </div>
            <button
              class="btn d-block m-auto w-50"
              type="submit"
              (click)="modal_number($event)"
            >
              Submit
            </button>
            <div class="row">
              <div class="col-12">
                <div
                  class="card sub_search_card mt-2"
                  *ngFor="let search of searchResult"
                >
                  <div class="card-body" (click)="searchAppliance(search)">
                    <div class="row">
                      <div class="col-6 p-0">
                        <small>Brand Name: {{ search.applianceBrand }}</small>
                      </div>
                      <div class="col-6 p-0">
                        <small>Model No: {{ search.applianceModelNo }}</small>
                      </div>
                      <div class="col-6 p-0">
                        <small>Capacity: {{ search.applianceCapacity }}</small>
                      </div>
                      <div class="col-6 p-0">
                        <small>Type: {{ search.applianceType }}</small>
                      </div>
                      <div class="col-6 p-0">
                        <small>Star Rating: {{ search.applianceStar }}</small>
                      </div>
                      <div class="col-6 p-0">
                        <small
                          >Measure: {{ search.applianceCapacityMeasure }}</small
                        >
                      </div>
                      <a>
                        <i class="fa fa-chevron-right"></i>
                      </a>
                      <!-- <a
                        ><img
                          src="../../../../../assets/img/back_button.svg"
                          alt="back_button"
                          style="transform: rotate(180deg); height: 15px"
                      /></a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
