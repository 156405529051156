<div *ngIf="applianceMap">
    <div *ngFor="let item of applianceCounts | keyvalue">
        <h6 class="header_title mb-3">{{ applianceMap[item.key].readableName }}</h6>
        <div *ngFor="let i of item.value | keyvalue">
            <div class="container">
                <div class="row mt-2">
                    <div class="col-2 mb-2">
                        <img src="{{ applianceMap[item.key][i.key].imageUrl }}" alt="" class="img-fluid appliance__image d-block m-auto" />
                    </div>
                    <div class="col-4 d-flex align-items-center">
                        <small>{{ applianceMap[item.key][i.key].readableName }}</small>
                    </div>
                    <div class="col-6 d-flex justify-content-end align-items-center">
                        <div class="quantity">
                            <a class="quantity__minus" (click)="decrementCount(item.key, i.key)"><span>-</span></a>
                            <input name="quantity" type="text" class="quantity__input text-danger" value="{{ i.value }}" disabled />
                            <a class="quantity__plus" (click)="incrementCount(item.key, i.key)"><span>+</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
</div>