import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from './../../../../service/common-api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-details-edit',
  templateUrl: './home-details-edit.component.html',
  styleUrls: ['./home-details-edit.component.scss'],
})
export class HomeDetailsEditComponent implements OnInit {
  loader: boolean = false;
  meter_id: any;
  home: any;
  address: any;
  pincode: any;
  houseType: any;
  ssid: any;
  city: any;
  constructor(
    public _api: CommonApiService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loader = true;
    this.meter_id = this.route.snapshot.params['meter_id'];
    this.getBotInfo();
  }

  getBotInfo() {
    this.loader = true;
    this._api
      .botInfoGet(this.meter_id)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.home = res;
      });
  }

  saveDetails() {
    this.loader = true;
    let formdata = {
      address: this.home.address,
      pincode: this.home.pincode,
      houseType: this.home.houseType,
      ssid: this.home.ssid,
      city: this.home.city,
    };

    this._api
      .botDetailsUpdate(this.meter_id, formdata)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.router.navigateByUrl(`/bot-home-details/${this.meter_id}`);
      });
  }
}
