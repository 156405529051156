<nav class="navbar navbar-expand-lg navbar-light transparent">
  <div class="col-6 p-0 d-flex align-items-center">
    <a routerLink="/dashboard">
      <img
        src="../../../assets/img/arrow-left.png"
        alt=""
        class="img-fluid d-block m-auto"
    /></a>
    <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get">{{ getTitle() }}</a>
  </div>
  <div class="form-inline my-2 my-lg-0">
    <!-- <img src="../../../assets/img/menu.png" alt="" /> -->
  </div>
</nav>
