import { first } from "rxjs/operators";
import { CommonApiService } from "./../../../service/common-api.service";
import { Chart, registerables } from "chart.js";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import * as _ from "lodash";

@Component({
  selector: "app-detailed-analysis",
  templateUrl: "./detailed-analysis.component.html",
  styleUrls: ["./detailed-analysis.component.scss"],
})
export class DetailedAnalysisComponent implements OnInit {
  public clicked: boolean = true;
  public clicked1: boolean = false;
  myChart: Chart;
  data: any;
  dataAnalysis: any;
  total_energy: any;
  energy_consumption: any;
  startDate: any;
  endDate: any;
  breakDownData: any = [];
  uptimePercentage: any;
  yearsFrom2018 = (moment().year() - 2018) * -1;
  breakDownBackground = ["#FF9860", "#F9C74F", "#5DD39E", "#6DDAFF", "#B298DC"];
  weekBgColors = [
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
  ];
  yearBgColors = [
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
  ];
  disableLeftClickWeek = false;
  disableLeftClickMonth = false;
  startTime: any;
  endTime: any;
  background: any;
  weekOfSet = 0;
  monthOfSet = 0;
  yearOfSet = 0;
  dailyRightClick = false;
  loader: boolean = false;
  page: Number = 0;
  count: Number = 6;
  applianceUsage: any;
  todayDate: any;
  todayMonth: any;
  selectedMonth: any;
  selectedDate: any;
  constructor(private _api: CommonApiService) {}

  ngOnInit(): void {
    this.loader = true;
    this.getAnalysis();

    this.selectedDate = moment()
      .add(this.weekOfSet * 7, "day")
      .startOf("day")
      .format("DD MMM, YYYY");
    this.selectedMonth = moment()
      .add(this.yearOfSet, "year")
      .startOf("month")
      .format("MMMM YY");
    console.log(this.selectedMonth);
  }

  renderDailyGraph(labels, data, background) {
    if (this.myChart != undefined) {
      this.myChart.destroy();
    }

    var canvas: any = document.getElementById("myDetailedAnalysisChart");

    var ctx = canvas.getContext("2d");
    Chart.register(...registerables);
    this.myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            borderColor: background,
            backgroundColor: this.weekBgColors,
            borderWidth: 1,
            barPercentage: 0.4,
          },
        ],
      },
      options: {
        onClick: this.graphClickEventDaily,
        plugins: {
          title: {
            display: true,
            text: "Total consumption",
          },
          legend: {
            display: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            beginAtZero: true,

            grid: {
              display: false,
            },
          },
          y: {
            stacked: false,
          },
        },
      },
    });
  }
  renderMonthGraph(labels, data, background) {
    if (this.myChart != undefined) {
      this.myChart.destroy();
    }

    var canvas: any = document.getElementById("myDetailedAnalysisChart");

    var ctx = canvas.getContext("2d");
    Chart.register(...registerables);
    this.myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            borderColor: background,
            backgroundColor: this.yearBgColors,
            borderWidth: 1,
            barPercentage: 0.4,
          },
        ],
      },
      options: {
        onClick: this.graphClickEventMonth,
        plugins: {
          title: {
            display: true,
            text: "Total consumption",
          },
          legend: {
            display: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            beginAtZero: true,

            grid: {
              display: false,
            },
          },
          y: {
            stacked: false,
          },
        },
      },
    });
  }

  graphClickEventDaily = (event, legendItem) => {
    this.total_energy = Math.round(
      this.dataAnalysis[legendItem[0].index]["energy"]
    );
    if (legendItem.length > 0) {
      for (let i = 0; i < this.weekBgColors.length; i++) {
        this.weekBgColors[i] = "#5DD39E";
      }
      this.weekBgColors[legendItem[0].index] = "#1F6B3A";
      this.myChart.update();
      let dayDate = this.dataAnalysis[legendItem[0].index];
      let startDayDate = moment(dayDate["date"]).startOf("day").toISOString();
      let endDayDate = moment(dayDate["date"]).endOf("day").toISOString();
      this.selectedDate = moment(dayDate["date"])
        .startOf("day")
        .format("DD MMM, YYYY");
      this.getBreakdown(startDayDate, endDayDate);
      this._api
        .getUpTime(startDayDate, endDayDate)
        .pipe(first())
        .subscribe((res) => {
          this.loader = false;
          this.uptimePercentage = res["uptimePercent"];
        });
    }
  };

  graphClickEventMonth = (event, legendItem) => {
    this.total_energy = Math.round(
      this.dataAnalysis[legendItem[0].index]["energy"]
    );

    if (legendItem.length > 0) {
      for (let i = 0; i < this.yearBgColors.length; i++) {
        this.yearBgColors[i] = "#5DD39E";
      }
      this.yearBgColors[legendItem[0].index] = "#1F6B3A";
      this.myChart.update();
      let startMonthDate = moment()
        .add(this.yearOfSet, "year")
        .month(legendItem[0].index)
        .startOf("month")
        .toISOString();
      let endMonthDate = moment()
        .add(this.yearOfSet, "year")
        .month(legendItem[0].index)
        .endOf("month")
        .toISOString();

      this.selectedMonth = moment()
        .add(this.yearOfSet, "year")
        .month(legendItem[0].index)
        .startOf("month")
        .format("MMMM YYYY");
      this.getBreakdown(startMonthDate, endMonthDate);

      this._api
        .getUpTime(startMonthDate, endMonthDate)
        .pipe(first())
        .subscribe((res) => {
          this.loader = false;
          this.uptimePercentage = res["uptimePercent"];
        });
    }
  };

  getBreakdown(startDate, endDate) {
    this._api
      .getAnalysis(startDate, endDate)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.background = "#348AA7";

        this.breakDownData = res["devices"];
        // this.total_energy = Math.round(res["total_energy"]);
        for (let index = 0; index < this.breakDownData.length; index++) {
          this.breakDownData[index].color = this.breakDownBackground[index];
        }
      });
  }

  leftDailyClick() {
    this.weekOfSet = this.weekOfSet - 1;
    this.getAnalysis();
  }
  rightDailyClick() {
    this.weekOfSet = this.weekOfSet + 1;
    this.getAnalysis();
  }

  leftMonthClick() {
    this.yearOfSet = this.yearOfSet - 1;
    this.getMonthAnalysis();
  }

  rightMonthClick() {
    this.yearOfSet = this.yearOfSet + 1;
    this.getMonthAnalysis();
  }

  getBreakdownOfAppliances(start, end) {
    this._api
      .getAnalysis(start, end)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.breakDownData = res["devices"];
        // this.total_energy = res["total_energy"].toFixed(2);
        // this.total_energy = Math.round(res["total_energy"]);

        for (let index = 0; index < this.breakDownData.length; index++) {
          this.breakDownData[index].color = this.breakDownBackground[index];
        }
      });
  }
  getAnalysis() {
    // GET START OF WEEK AND END OF WEEK

    var endDate = moment().startOf("day").toISOString(); //the current time
    var startDate = moment().subtract(1, "day").startOf("day").toISOString();

    // this.startTime = moment()
    //   .startOf("week")
    //   .add(this.weekOfSet * 7, "day")
    //   .toISOString();
    // this.endTime = moment()
    //   .endOf("week")
    //   .add(this.weekOfSet * 7, "day")
    //   .toISOString();
    this.startTime = moment()
      .startOf("week")
      .add(this.weekOfSet * 7 + 1, "day")
      .toISOString();
    this.endTime = moment()
      .endOf("week")
      .add(this.weekOfSet * 7 + 1, "day")
      .toISOString();

    // Fetch data for all the days in week
    this._api
      .getAnalysisToday(this.startTime, this.endTime)
      .pipe(first())
      .subscribe((res: Array<any>) => {
        this.dataAnalysis = res;

        let labels = [];
        let data = [];

        // enable or disable left/right click buttons

        if (res.length < 7) {
          this.disableLeftClickWeek = true;
        } else {
          this.disableLeftClickWeek = false;
        }
        this.loader = false;
        let background = "#DFDFDF";

        // maintain a flag for last colored (pre-selected item)
        let preselectedDay = false;
        this.todayDate = moment()
          .add(this.weekOfSet * 7, "day")
          .format("ll");
        // res.push(res.shift());
        res.forEach((element, index) => {
          data.push(element["energy"].toFixed(2));
          labels.push(element["day"]);

          // If day in array is today, preselect (colored)
          if (
            moment(element["date"]).format("YYYYMMDD") ==
            moment().format("YYYYMMDD")
          ) {
            this.selectedDate = moment().format("DD MMM, YYYY");
            this.weekBgColors[index] = "#1F6B3A";
            this.total_energy = element["energy"].toFixed(0);
            startDate = moment().startOf("day").toISOString();
            endDate = moment().endOf("day").toISOString();
            this.getBreakdownOfAppliances(
              moment().startOf("day"),
              moment().endOf("day")
            );

            // set preselectedDay true for next iterations
            preselectedDay = true;
          } else {
            this.weekBgColors[index] = "#5DD39E";
          }
        });

        if (!preselectedDay) {
          this.weekBgColors[res.length - 1] = "#1F6B3A";
          this.selectedDate = moment(res[res.length - 1]["date"]).format(
            "DD MMM, YYYY"
          );

          startDate = moment(res[res.length - 1]["date"])
            .startOf("day")
            .toISOString();
          endDate = moment(res[res.length - 1]["date"])
            .endOf("day")
            .toISOString();
          this.getBreakdownOfAppliances(
            moment(res[res.length - 1]["date"]).startOf("day"),
            moment(res[res.length - 1]["date"]).endOf("day")
          );
        }

        // this.renderGraph(labels, data);

        this.renderDailyGraph(labels, data, background);

        this._api
          .getUpTime(startDate, endDate)
          .pipe(first())
          .subscribe((res) => {
            this.loader = false;
            this.uptimePercentage = res["uptimePercent"];
          });
      });
  }

  getMonthAnalysis() {
    // this.startTime = moment().subtract(30, 'day').format('MMYYYY');
    // this.endTime = moment().startOf('day').format('MMYYYY');

    this.startTime = moment()
      .add(this.yearOfSet, "year")
      .startOf("year")
      .format("MMYYYY");
    this.endTime = moment()
      .add(this.yearOfSet, "year")
      .endOf("year")
      .format("MMYYYY");

    let currentYear = moment().format("YYYY");
    this._api
      .getAnalysisMonth(this.startTime, this.endTime)
      .pipe(first())
      .subscribe((res: Array<any>) => {
        this.dataAnalysis = res;
        let labels = [];
        let data = [];

        this.loader = false;
        let background = "#DFDFDF";

        this.todayMonth = moment().format("MMM YY");

        res.forEach((element, index) => {
          data.push(element["energy"].toFixed(2));
          labels.push(element["month"].slice(0, 3));
          // console.log(element["month"], this.todayMonth, element["energy"])
          if (element["month"] === this.todayMonth) {
            this.yearBgColors[index] = "#1F6B3A";
            this.selectedMonth = moment(element["date"]).format("MMM YY");
            console.log(element["energy"]);

            this.total_energy = element["energy"].toFixed(0);
            this.getBreakdownOfAppliances(
              moment().startOf("month"),
              moment().endOf("month")
            );
          } else {
            this.yearBgColors[index] = "#5DD39E";
          }

          let lst = this.dataAnalysis.length - 1;

          this.startDate = moment(
            this.dataAnalysis[lst]["date"],
            "YYYYMMDD"
          ).toISOString();
          this.endDate = moment(this.dataAnalysis[lst]["date"], "YYYYMMDD")
            .add(30, "day")
            .toISOString();
        });

        if (moment(res[res.length - 1]["date"]).format("YYYY") != currentYear) {
          this.selectedMonth = moment(res[res.length - 1]["date"]).format(
            "MMM YY"
          );

          this.yearBgColors[res.length - 1] = "#1F6B3A";
          this.getBreakdownOfAppliances(
            moment(res[res.length - 1]["date"]).startOf("month"),
            moment(res[res.length - 1]["date"]).endOf("month")
          );
        }

        this.renderMonthGraph(labels, data, background);
        var endDate = moment().endOf("month").toISOString(); //the current time
        var startDate = moment().startOf("month").toISOString();

        this._api
          .getUpTime(startDate, endDate)
          .pipe(first())
          .subscribe((res) => {
            this.loader = false;
            this.uptimePercentage = res["uptimePercent"];
          });
      });
  }
}

// let m = moment("082021", "MMYYY")
