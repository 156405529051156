<section>
  <div class="container">
    <form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
      <div class="row mt-2 d-flex justify-content-between align-items-center">
        <div class="col-6">
          <img
            src="../../../../assets/img/support/cross.svg"
            alt=""
            class="img-fluid"
            style="width: 18px; cursor: pointer"
            routerLink="/support"
          />
        </div>
        <div class="col-6 d-flex justify-content-end">
          <button class="btn" [disabled]="!f.form.valid">
            Send
            <img src="../../../../assets/img/support/send.svg" alt="" />
          </button>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12 px-4">
          <div class="form-group">
            <select
              class="form-control"
              [(ngModel)]="meter_id"
              name="meter_id"
              required
            >
              <option disabled selected value="undefined">Bot Id</option>
              <option *ngFor="let meter of meters" value="{{ meter }}">
                {{ meter }}
              </option>
            </select>
          </div>
          <div class="form-group mt-4">
            <select
              class="form-control"
              [(ngModel)]="record_type_id"
              required
              (change)="issueTypeSelect($event)"
              name="record_type_id"
            >
              <option disabled selected value="undefined">Issue Type</option>
              <option *ngFor="let type of issueType" value="{{ type.value }}">
                {{ type.name }}
              </option>
            </select>
          </div>
          <div class="form-group mt-4">
            <select
              class="form-control"
              [(ngModel)]="sub_type"
              name="sub_type"
              required
              (change)="selectedSubType($event)"
            >
              <option disabled selected value="undefined">Sub-Type</option>
              <option *ngFor="let subType of sub_type" value="{{ subType }}">
                {{ subType }}
              </option>
            </select>
          </div>
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="subject"
              placeholder="Subject"
              name="subject"
            />
          </div>

          <div class="form-group mt-4">
            <textarea
              type="text"
              class="form-control"
              [(ngModel)]="description"
              placeholder="Description"
              rows="6"
              name="description"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
