import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
declare interface RouteInfo {
  path: string;
  title: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/analysis', title: 'Analysis' },
  { path: '/budget', title: 'Budget' },
  { path: '/vyas', title: 'Vyas' },
  { path: '/more', title: 'Explore' },
  { path: '/my-bot', title: 'My Bot(s)' },
  { path: '/detailed-analysis', title: 'Detailed Analysis' },
  { path: '/detailed-budget', title: 'Budget' },
  { path: '/train-your-bot', title: 'List of Appliance' },
  { path: '/programs', title: 'Programs' },
  { path: '/product-training', title: 'Program Training' },
  { path: '/energy-efficiency', title: 'Energy Efficiency' },
  { path: '/energy-efficiency-enroll', title: 'Energy Efficiency' },
];

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public menuItems: any[];
  public location: Location;
  titlee: any;
  version: any;
  profile: any;
  constructor(
    location: Location,
    private element: ElementRef,
    public router: Router,
    public afAuth: AngularFireAuth
  ) {}

  ngOnInit(): void {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }

  getTitle() {
    for (var item = 0; item < this.menuItems.length; item++) {
      if (this.menuItems[item].path === this.router.url) {
        return this.menuItems[item].title;
      }
    }
    return 'login';
  }
}
