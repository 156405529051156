import { NgForm } from "@angular/forms";
import { first } from "rxjs/operators";
import { CommonApiService } from "./../../service/common-api.service";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
  selector: "app-budget",
  templateUrl: "./budget.component.html",
  styleUrls: ["./budget.component.scss"],
})
export class BudgetComponent implements OnInit {
  public clicked: boolean = true;
  public clicked1: boolean = false;
  netMonthConsumption: any;
  form_statement: NgForm;
  form_email: NgForm;
  email: any;
  toDate: any;
  fromDate: any;
  today = new Date().toISOString().split("T")[0];
  selectDate__div: boolean = true;
  result__div: boolean = false;
  email__div: boolean = false;
  allDone__div: boolean = false;
  totalEC: any;
  loader: boolean = false;
  utility: boolean = false;
  calender: boolean = false;
  meter_id: any;
  estimatedCost: any;
  netCost: any;
  constructor(
    private _api: CommonApiService,
    private toastr: ToastrService,
    private _route: Router
  ) {}

  ngOnInit(): void {
    this.loader = true;
    this.calenderBilling();
    this.meter_id = this._api.getSelectedMeterId();
  }

  calenderBilling() {
    // fetch data from api
    this.calender = true;
    this.utility = false;
    this._api
      .getEstimatedConsumption(false)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.netMonthConsumption = res;
        this.estimatedCost = res["estimatedCost"];
        this.netCost = res["netCost"];
      });
  }

  utilityBilling() {
    // fetch data from api
    this.calender = false;
    this.utility = true;
    this._api
      .getEstimatedConsumption(true)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.netMonthConsumption = res;
        this.estimatedCost = res["estimatedCost"];
        this.netCost = res["netCost"];
      });
  }

  utilityRoute() {
    this._route.navigateByUrl(`/bot-utility-details/${this.meter_id}`);
  }

  getStatementResult() {
    if (Date.parse(this.fromDate) == Date.parse(this.toDate)) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "From data and To date can`t be equal",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      this._api
        .getSelectedTimeIntervalEnergyStatement(
          moment(this.fromDate).format("DD/MM/YYYY"),
          moment(this.toDate).format("DD/MM/YYYY")
        )
        .pipe(first())
        .subscribe((res) => {
          this.loader = false;
          this.selectDate__div = false;
          this.result__div = true;
          this.totalEC = res["totalEC"];
        });
    }
  }
  getDetails() {
    this.result__div = false;
    this.email__div = true;
  }
  getStatement() {
    this._api
      .getEnergyStatement(this.email, this.fromDate, this.toDate)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        setTimeout(() => {
          this.email__div = false;
          $("#form_email").trigger("reset");
          this.allDone__div = true;
          // $('[data-dismiss=modal]').trigger('click');
        }, 1500);

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Statement send to email id",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }
}
