import { Chart, registerables } from "chart.js";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { first, take, sample } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import * as _ from "lodash";
import { Subject, Observable } from "rxjs";
import { MqttService } from "src/app/service/mqtt.service";
import { CommonApiService } from "src/app/service/common-api.service";

@Component({
  selector: "app-diagnosis-initate",
  templateUrl: "./diagnosis-initate.component.html",
  styleUrls: ["./diagnosis-initate.component.scss"],
})
export class DiagnosisInitateComponent implements OnInit {
  meterId: string;
  mqttService: MqttService;
  appliance_id: string;
  appliance: any;
  liveChart: any;
  subject: Observable<any>;
  SAMPLE_SIZE_AC: number = 20;
  SAMPLE_SIZE_GEYSER: number = 8;
  redoButton: boolean = false;
  stabilityAverage: any;
  applianceName: any;
  THRESHOLD: number = 200;
  appliance_detected: boolean = false;

  constructor(
    public router: Router,
    public api: CommonApiService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.meterId = localStorage.getItem("selected_meter");
    this.appliance_id = this.route.snapshot.params["appliance_id"];
    this.stabilityAverage = this.route.snapshot.params["stabilityAverage"];
    this.applianceName = this.route.snapshot.params["applianceName"];

    // fetch appliance data
    this.api
      .getapplianceById(this.appliance_id)
      .pipe(first())
      .subscribe((res) => {
        this.appliance = res;
      });
    // init MQTT
    this.mqttService = new MqttService(environment.mqtt_host, [
      parseInt(this.meterId),
    ]);
    //init graph
    // start collecting data

    setTimeout(() => {
      this.graph();
      this.startDataCollection();
    }, 500);
    // this.startDataCollection();
  }

  apList: Array<any> = [];
  difference: any;
  timestampList: Array<any> = [];
  rawFrames: Array<any> = [];
  showSaveButton = false;

  startDataCollection() {
    if (this.applianceName === "AC") {
      this.subject = this.mqttService.listen();
      this.subject.pipe(take(this.SAMPLE_SIZE_AC)).subscribe((beacon) => {

        if (
          beacon.a - this.stabilityAverage < this.THRESHOLD &&
          !this.appliance_detected
        ) {
          return;
        }
        this.appliance_detected = true;

        this.rawFrames.push(beacon);

        this.difference =
          parseFloat(beacon.a.toFixed(2)) - this.stabilityAverage;

        this.apList.push(this.difference);
        let ts = moment(beacon.t * 1000).format("LT");
        this.timestampList.push(ts);
        this.liveChart.update();
        if (this.apList.length >= this.SAMPLE_SIZE_AC) {
          this.subject = null;
          this.onDataCollected();
        }
      });
    }
    if (this.applianceName === "GEYSER") {
      this.subject = this.mqttService.listen();
      this.subject.pipe(take(this.SAMPLE_SIZE_GEYSER)).subscribe((beacon) => {
        if (
          beacon.a - this.stabilityAverage < this.THRESHOLD &&
          !this.appliance_detected
        ) {
          return;
        }
        this.appliance_detected = true;
        this.rawFrames.push(beacon);

        this.difference =
          parseFloat(beacon.a.toFixed(2)) - this.stabilityAverage;

        this.apList.push(this.difference);
        let ts = moment(beacon.t * 1000).format("LT");
        this.timestampList.push(ts);
        this.liveChart.update();
        if (this.apList.length >= this.SAMPLE_SIZE_GEYSER) {
          this.subject = null;
          this.onDataCollected();
        }
      });
    }
  }

  /**
   * after collection data of size SAMPLE_SIZE
   */
  onDataCollected() {
    // this.THRESHOLD;
    // MAX(this.array) > THRESHOLD ? valid : invalid
    // valid ? hit api + result component : show error component + return to main-vyas
    // let max_value = _.max(this.apList);
    // let max_value = _.max(this.rawFrames);

    // if (max_value > this.THRESHOLD) {
    this.showSaveButton = true;
    this.redoButton = true;
    // } else {
    // TODO: Show error message as appliance not detected!
    // this.redoButton = true;
    // }
  }

  /**
   * Push diagnosis data to server and get health status
   */
  saveDiagnoseData() {
    let frames = {
      frames: this.rawFrames,
      stabilityAverage: this.stabilityAverage,
    };
    this.api
      .diagnosisDataSave(this.appliance_id, frames)
      .pipe(first())
      .subscribe((res) => {
        this.router.navigate(["/vyas"]);
      });
  }

  redo() {
    this.router.navigate(["/vyas"]);
    this.subject = null;
  }

  graph() {
    // var ctx = document.getElementById("myChart");
    var canvas: any = document.getElementById("myChart");
    var ctx = canvas.getContext("2d");
    Chart.register(...registerables);
    this.liveChart = new Chart(ctx, {
      // The type of chart we want to create
      type: "line",

      // The data for our dataset
      data: {
        labels: this.timestampList,
        datasets: [
          {
            data: this.apList,
            backgroundColor: "#EE2F53",
            borderColor: "#EE2F53",
            borderWidth: 1,
            fill: false,
            pointRadius: 0,
          },
        ],
      },

      // Configuration options go here
      options: {
        responsive: true,

        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            // display: false,
            grid: {
              // display: false,
            },
            beginAtZero: true,
          },
        },
      },
    });
  }
}
