import { CommonApiService } from './../../service/common-api.service';
import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  pincode: any;
  edit: boolean = true;
  save: boolean = false;
  displayName: any;
  email: any;
  profile: any;
  created: any;
  loader: boolean = false;
  bot: any;
  emailcheck: boolean = false;
  constructor(public _api: CommonApiService) {}

  ngOnInit(): void {
    this.loader = true;
    this.getCurrentUser();
  }

  getCurrentUser() {
    this._api
      .getUserData()
      .pipe(first())
      .subscribe((res) => {
        this.profile = res;
        this.created = moment(res['created']).format('LL');
        this.bot = res['meters'];

        this.loader = false;
      });
  }

  editProfile() {
    this.save = true;
    this.edit = false;
  }
}
