import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { CommonApiService } from './../../service/common-api.service';
import { environment } from './../../../environments/environment.prod';
import { Component, Input, OnInit, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent implements OnInit {
  @Input() selectedMeterId: string;
  @Output() meterChange = new EventEmitter<string>();

  version: any;
  meter_id: any;
  user: any;
  meters: any;

  constructor(
    private _api: CommonApiService,
    public afAuth: AngularFireAuth,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.version = environment.version;
    this.meter_id = this._api.meter_id;
    this.selectedMeterId = this._api.getSelectedMeterId();
    this.user = JSON.parse(localStorage.getItem('user'));
    this.meters = this.user['meters'];
  }

  onMeterChange(ev) {
    console.log(ev);
    this._api.setSelectedMeterId(ev);
    this.selectedMeterId = ev;
    this.meterChange.emit(ev);
  }
  logout() {
    this.afAuth.signOut();
    localStorage.clear();
    this.router.navigate(['/login']);
    localStorage.removeItem('user');
  }
  openNav() {
    // document.getElementById("mySidenav").style.width = "320px";
    document.getElementById('mySidenav').classList.add('mySidenav');
    document.body.style.backgroundColor = 'rgba(0,0,0,0.4)';
    // document.getElementById('overlay').style.display = 'block';
    // document.getElementById("main").style.marginRight = "250px";
  }

  closeNav() {
    document.getElementById('mySidenav').classList.remove('mySidenav');
    document.body.style.backgroundColor = 'white';
    // document.getElementById('overlay').style.display = 'none';
    // document.getElementById("main").style.marginRight= "0";
  }
}
