import { CommonApiService } from './../../../service/common-api.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
  opt_form: NgForm;
  code1: any;
  code2: any;
  code3: any;
  code4: any;
  code5: any;
  code6: any;
  otp: any;
  otpTimer: any;
  confirmationResultOtp: any;
  userSubscription: any;
  user: firebase.User;
  public recaptchaVerifier: any;
  meter_id: any;
  mobile: any;
  constructor(
    private _api: CommonApiService,
    public _router: Router,
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth
  ) {}

  ngOnInit(): void {
    this.resendText();
    this.route.queryParams.subscribe((params) => {
      this.mobile = params.mobile;
    });
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: function (response: any) {
          // reCAPTCHA solved - will proceed with submit function
        },
        'expired-callback': function () {
          // Reset reCAPTCHA?
        },
      }
    );
    this.recaptchaVerifier.render();
    this.userSubscription = this.afAuth.authState.subscribe((user) => {
      if (user) {
        localStorage.setItem('firebaseUser', JSON.stringify(user));
        this.user = user;
        this.userSubscription.unsubscribe();
      }
    });
  }
  // OTP
  getCodeBoxElement(index) {
    return document.getElementById('codeBox' + index);
  }
  onKeyUpEvent(index, event) {
    const eventCode = event.which || event.keyCode;
    if (this.getCodeBoxElement(index)['value'].length === 1) {
      if (index !== 6) {
        this.getCodeBoxElement(index + 1).focus();
      } else {
        this.getCodeBoxElement(index).blur();
        // Submit code
        this.otp =
          this.code1 +
          '' +
          this.code2 +
          '' +
          this.code3 +
          '' +
          this.code4 +
          '' +
          this.code5 +
          '' +
          this.code6 +
          '';
        // formData.append("code", otp);
      }
    }
    if (eventCode === 8 && index !== 1) {
      this.getCodeBoxElement(index - 1).focus();
    }
  }
  onFocusEvent(index) {
    for (let item = 1; item < index; item++) {
      const currentElement = this.getCodeBoxElement(item);
      if (!currentElement['value']) {
        currentElement.focus();
        break;
      }
    }
  }
  resendText() {
    let timeleft = 60;
    this.otpTimer = setInterval(function () {
      if (timeleft <= 0) {
        clearInterval(this.otpTimer);
        document.getElementById('countdownOpt').innerHTML = 'Resent OTP';
        document.getElementById('countdown').innerHTML = '';
      } else {
        document.getElementById('countdown').innerHTML =
          timeleft + ' seconds remaining';
        document.getElementById('countdownOpt').innerHTML = '';
      }
      timeleft -= 1;
    }, 1000);
  }

  async resendOTP() {
    const appVerifier = this.recaptchaVerifier;
    try {
      let result = await this.afAuth.signInWithPhoneNumber(
        this.mobile,
        appVerifier
      );
      this.confirmationResultOtp = result;
      this.resendText();

      // this.getNavigateTools();
      // this.toastr.success("Login successfull")
    } catch (error) {
    }
    // this.getOtp();
  }

  sendOTP() {
    this._api.confirmationResult
      .confirm(this.otp)
      .then((result) => {
        var user = result.user;
        user.getIdToken(false).then(console.log);
        this.updateUser();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateUser() {
    this._api
      .userUpdate({})
      .pipe(first())
      .subscribe((res) => {
        if (res) {
          localStorage.setItem('user', JSON.stringify(res));
          if (res['meters'].length === 0) {
            document.getElementById('countdown').innerHTML = '';
            document.getElementById('countdownOpt').innerHTML = '';
            clearInterval(this.otpTimer);
            // $("#modal_unregistered").removeClass("hidden").addClass("visble");
            this._router.navigate(['/unregister']);
            localStorage.clear();
            // this.showUnregisteredMessage();
          } else {
            // this.hideUnregisteredMessage();
            this.meter_id = res['meters'];
            this._api.setSelectedMeterId(res['meters'][0]);
            this._api.meter_id = this.meter_id;

            let displayName = Boolean(res['displayName']);

            if (displayName === false) {
              document.getElementById('countdown').innerHTML = '';
              document.getElementById('countdownOpt').innerHTML = '';
              clearInterval(this.otpTimer);
              this._router.navigate(['/profile']);
            } else {
              document.getElementById('countdown').innerHTML = '';
              document.getElementById('countdownOpt').innerHTML = '';
              clearInterval(this.otpTimer);
              this._router.navigate(['/dashboard']);
            }
          }
        }
      });
  }
  ngOnDestroy() {
    // document.getElementById('countdown').innerHTML = '';
    // document.getElementById('countdownOpt').innerHTML = '';
    // clearInterval(this.otpTimer);
  }
}
