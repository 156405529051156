import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { CommonApiService } from "src/app/service/common-api.service";
import { ActivatedRoute } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-utility",
  templateUrl: "./utility.component.html",
  styleUrls: ["./utility.component.scss"],
})
export class UtilityComponent implements OnInit {
  utility: any;
  consumerid: any;
  allStates: any;
  selectedUtility: any;
  selectedUtilityName: any;
  selectedState: any;
  selectedStateName: any;

  boardName: any;
  billingDate: Number = 1;
  state: any;
  slabs: any;
  boardNameSelected: any;
  boardSelected: any;
  meter_id: any;
  constructor(
    public api: CommonApiService,
    public router: Router,
    private http: HttpClient,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.meter_id = this.route.snapshot.params["meter_id"];
    this.http
      .get(" ../../../../assets/json/utility.json")
      .subscribe((utilityData) => {
        this.allStates = utilityData;
        this.selectedState = this.allStates[0];
        this.selectedStateName = this.selectedState["stateName"];

        this.selectedUtility = this.selectedState["utilityBoards"][0];
        this.selectedUtilityName = this.selectedUtility["boardName"];

        this.getInfoBot();
      });
  }

  stateSelect(e) {
    console.log(e.target.value);
    this.selectedStateName = e.target.value;
    this.allStates.forEach((item) => {
      if (item.stateName == this.selectedStateName) {
        this.selectedState = item;

        this.selectedUtility = this.selectedState["utilityBoards"][0];
        this.selectedUtilityName = this.selectedUtility["boardName"];

        console.log(item);
      }
    });
  }

  boardSelect(e) {
    this.selectedUtilityName = e.target.value;
    this.selectedState["utilityBoards"].forEach((item) => {
      if (this.selectedUtilityName == item["boardName"]) {
        this.selectedUtility = item;
      }
    });
  }
  dateModal() {
    console.log("modal");
    $("#exampleModal").modal().show();
  }

  dateSelected(date) {
    console.log(date);
    this.billingDate = date;
    $("#exampleModal").modal().hide();
    $(".modal-backdrop").remove();
  }

  getInfoBot() {
    this.api
      .botInfoGet(this.meter_id)
      .pipe(first())
      .subscribe((res) => {
        this.selectedStateName = res["state"];

        this.allStates.forEach((item) => {
          if (item.stateName == this.selectedStateName) {
            this.selectedState = item;
            this.selectedUtility = this.selectedState["utilityBoards"][0];
          }
        });

        this.billingDate = res["billingDate"];
        this.selectedUtility = res["utilityBoardInfo"];
        this.selectedUtilityName = this.selectedUtility["boardName"];
      });
  }

  botUpdate() {
    let info = {
      state: this.selectedStateName,
      utilityBoardInfo: this.selectedUtility,
      billingDate: this.billingDate,
      consumerid: this.consumerid,
    };
    console.log(info);

    this.api
      .botDetailsUpdate(this.meter_id, info)
      .pipe(first())
      .subscribe((res) => {
        console.log(res);
        this.router.navigate(["/login"]);
      });
  }
}
