<app-loader *ngIf="loader"></app-loader>
<section>
    <div class="container">
      <div class="row">
        <div class="col-md-12 p-0">
          <app-navbar></app-navbar>
        </div>
      </div>
      <div class="row top__row mt-5">
        <div class="col-6 d-flex justify-content-center mt-3">
          <button
            class="btn top_btn"
            [ngClass]="{ active: clicked === true }"
            data-toggle="tab"
            (click)="clicked = true; clicked1 = false"
          >
            Calender
          </button>
        </div>
        <div class="col-6 d-flex justify-content-center mt-3">
          <button
            class="btn top_btn"
            [ngClass]="{ active: clicked1 === true }"
            data-toggle="tab"
            (click)="clicked = false; clicked1 = true"
          >
            Utility
          </button>
        </div>
      </div>
   
    </div>
  </section>
  
