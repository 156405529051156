<section class="profile_section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-md-12">
              <nav class="navbar navbar-expand-lg navbar-light transparent">
                <div class="col-6 p-0 d-flex align-items-center">
                  <a routerLink="/my-bot">
                    <img
                      src="../../../assets/img/arrow-left.png"
                      alt=""
                      class="img-fluid d-block m-auto"
                  /></a>
                  <a class="mb-0 pl-3 pr-3 d-lg-inline-block"> Back </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid p-0 edit__container">
    <div class="row">
      <div class="col-12 mt-5 px-4">
        <div class="card">
          <div class="card-body">
            <div class="row top__row">
              <div class="col-6">
                <a routerLink="/my-bot" class="title_get">Cancel</a>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <a
                  class="title_get"
                  style="cursor: pointer"
                  (click)="saveDetails()"
                  >Done</a
                >
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <p class="font-weight-bold">Bot Info</p>
              </div>
            </div>
            <form>
              <div class="row profile__edit mt-3">
                <div class="col-4">Bot Name</div>
                <div
                  class="col-8 d-flex justify-content-end align-items-center"
                  *ngIf="bot"
                >
                  <input
                    type="text"
                    [(ngModel)]="bot.nickName"
                    name="nickName"
                    autofocus
                  />
                </div>
                <div
                  class="col-8 d-flex justify-content-end align-items-center"
                  *ngIf="!bot"
                >
                  <p>---</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
