import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { first } from "rxjs/operators";
import { CommonApiService } from "src/app/service/common-api.service";

@Component({
  selector: "app-appliance-warranty-details",
  templateUrl: "./appliance-warranty-details.component.html",
  styleUrls: ["./appliance-warranty-details.component.scss"],
})
export class ApplianceWarrantyDetailsComponent implements OnInit {
  @Input() appliance: any;
  duration: any;
  purchaseDate: any;
  warrantyEndDate: any;
  appliance_id: any;
  signedUrl: any;
  loader: boolean = false;
  public mode: String = "EDIT";
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private api: CommonApiService
  ) {}

  ngOnInit(): void {
    this.loader = true;
    this.appliance_id = this.route.snapshot.params["appliance_id"];
    if (this.appliance.purchaseDate) {
      this.purchaseDate = moment(this.appliance.purchaseDate).format(
        "DD MMM YYYY"
      );
    }
    if (this.appliance.warrantyEndDate) {
      this.warrantyEndDate = moment(this.appliance.warrantyEndDate).diff(
        moment(this.appliance.purchaseDate),
        "days"
      );
    }
    if (!Boolean(this.appliance._id)) {
      this.mode = "CREATE";
    } else {
      this.getWarranty();
    }
  }

  addWarranty() {
    this.router.navigateByUrl(`/edit-appliance/${this.appliance._id}`, {
      state: this.appliance,
    });
  }

  getWarranty() {
    this.api
      .getWarrantyImage(this.appliance_id)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.signedUrl = res["signedUrl"];
        document.getElementById("warrantImage").style.display = "none";
      });
  }

  view() {
    document.getElementById("details").style.display = "none";
    document.getElementById("warrantImage").style.display = "block";
  }

  close() {
    document.getElementById("details").style.display = "block";
    document.getElementById("warrantImage").style.display = "none";
  }
}
