<section>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-3 red__background">
        <h1 class="text-center text-light font-weight-bold">4</h1>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <h4 class="font-weight-bold text-center">Utility</h4>
        <p class="mt-3">
          Provide utility details to get better predictions and recommendation
          for you. At OHM, we value your privacy!
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12 mt-3">
        <form
          #form_profile="ngForm"
          class="form_profile"
          id="form_profile"
          name="form_profile"
        >
          <div class="input-group mb-3">
            <select
              name="state"
              [(ngModel)]="selectedStateName"
              (change)="stateSelect($event)"
              class="form-control"
            >
              <option *ngFor="let state of allStates">
                {{ state.stateName }}
              </option>
            </select>
          </div>
          <div class="input-group mb-3">
            <select
              class="mt-3 mb-3 form-control"
              name="boardName"
              [(ngModel)]="selectedUtilityName"
              (change)="boardSelect($event)"
            >
              <option disabled>Select Board Name</option>
              <option
                *ngFor="let board of selectedState.utilityBoards"
                value="{{ board.boardName }}"
              >
                {{ board.boardName }}
              </option>
            </select>
          </div>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Enter consumer ID"
              id="consumerid"
              [(ngModel)]="consumerid"
              name="consumerid"
              required
            />
          </div>
          <div class="input-group mb-3">
            <span
              name="billingDate"
              class="
                d-flex
                justify-content-between
                align-items-center
                form-control
              "
              style="color: #626469"
              >{{ billingDate
              }}<i
                class="fa fa-edit"
                (click)="dateModal()"
                style="cursor: pointer; color: #e50021; font-size: 20px"
              ></i
            ></span>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <button type="button" class="btn w-100 mt-5">
                <img src="../../../../assets/img/Login/button.png" alt="" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Billing Cycle</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex justify-content-center">
        <table>
          <tbody>
            <tr>
              <td (click)="dateSelected(1)">1</td>
              <td (click)="dateSelected(2)">2</td>
              <td (click)="dateSelected(3)">3</td>
              <td (click)="dateSelected(4)">4</td>
              <td (click)="dateSelected(5)">5</td>
              <td (click)="dateSelected(6)">6</td>
              <td (click)="dateSelected(7)">7</td>
            </tr>
            <tr>
              <td (click)="dateSelected(8)">8</td>
              <td (click)="dateSelected(9)">9</td>
              <td (click)="dateSelected(10)">10</td>
              <td (click)="dateSelected(11)">11</td>
              <td (click)="dateSelected(12)">12</td>
              <td (click)="dateSelected(13)">13</td>
              <td (click)="dateSelected(14)">14</td>
            </tr>
            <tr>
              <td (click)="dateSelected(15)">15</td>
              <td (click)="dateSelected(16)">16</td>
              <td (click)="dateSelected(17)">17</td>
              <td (click)="dateSelected(18)">18</td>
              <td (click)="dateSelected(19)">19</td>
              <td (click)="dateSelected(20)">20</td>
              <td (click)="dateSelected(21)">21</td>
            </tr>
            <tr>
              <td (click)="dateSelected(22)">22</td>
              <td (click)="dateSelected(23)">23</td>
              <td (click)="dateSelected(24)">24</td>
              <td (click)="dateSelected(25)">25</td>
              <td (click)="dateSelected(26)">26</td>
              <td (click)="dateSelected(27)">27</td>
              <td (click)="dateSelected(28)">28</td>
            </tr>
            <tr>
              <td (click)="dateSelected(29)">29</td>
              <td (click)="dateSelected(30)">30</td>
              <td (click)="dateSelected(31)">31</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
