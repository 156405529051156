import { CommonApiService } from './../../../service/common-api.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-vyas-onboarding',
  templateUrl: './vyas-onboarding.component.html',
  styleUrls: ['./vyas-onboarding.component.scss'],
})
export class VyasOnboardingComponent implements OnInit {
  selectApplianceTypes = {
    AC: {
      AC_SPLIT: {
        imageUrl: '../../../../assets/img/appliance/spiltSC.svg',
        selected: false,
      },
      AC_WINDOW: {
        imageUrl: '../../../../assets/img/appliance/windowAc.svg',
        selected: false,
      },
      AC_TOWER: {
        imageUrl: '../../../../assets/img/appliance/floorAc.svg',
        selected: false,
      },
      AC_CASSETTE: {
        imageUrl: '../../../../assets/img/appliance/cassetteAC.svg',
        selected: false,
      },
      AC_VRV: {
        imageUrl: '../../../../assets/img/appliance/rvAC.svg',
        selected: false,
      },
    },
    WM: {
      WM_TOP_LOAD: {
        imageUrl: '../../../../assets/img/appliance/TL.svg',
        selected: false,
      },
      WM_FRONT_LOAD: {
        imageUrl: '../../../../assets/img/appliance/wm.svg',
        selected: false,
      },
      WM_SEMI_AUTOMATIC: {
        imageUrl: '../../../../assets/img/appliance/SA.svg',
        selected: false,
      },
    },
    GEYSER: {
      GEYSER_INSTANT: {
        imageUrl: '../../../../assets/img/appliance/gyser.svg',
        selected: false,
      },
      GEYSER_STORAGE: {
        imageUrl: '../../../../assets/img/appliance/SG.svg',
        selected: false,
      },
    },
    FRIDGE: {
      DIRECT_COOL: {
        imageUrl: '../../../../assets/img/appliance/fridge.png',
        selected: false,
      },
      FROST_FREE: {
        imageUrl: '../../../../assets/img/appliance/SD.svg',
        selected: false,
      },
    },

    HEATING_DEVICE: {
      IRON: {
        imageUrl: '../../../../assets/img/appliance/iron.svg',
        selected: false,
      },
      KETTLE: {
        imageUrl: '../../../../assets/img/appliance/kettle.svg',
        selected: false,
      },
      MICROWAVE: {
        imageUrl: '../../../../assets/img/appliance/microwave.svg',
        selected: false,
      },
      OTG: {
        imageUrl: '../../../../assets/img/appliance/otg.svg',
        selected: false,
      },
      GRINDER: {
        imageUrl: '../../../../assets/img/appliance/grinder.svg',
        selected: false,
      },
      TOASTER: {
        imageUrl: '../../../../assets/img/appliance/toasteer.svg',
        selected: false,
      },
    },
    WATER_PUMP: {
      IMMERSIVE: {
        imageUrl: '../../../../assets/img/appliance/immersive.svg',
        selected: false,
      },
      'HEAT PUMPS': {
        imageUrl: '../../../../assets/img/appliance/heat.svg',
        selected: false,
      },
      PRESSURE: {
        imageUrl: '../../../../assets/img/appliance/pressure.svg',
        selected: false,
      },
    },
    OTHERS: {
      WATER_PURIFYER: {
        imageUrl: '../../../../assets/img/appliance/water_purifier.png',
        selected: false,
      },
    },
  };
  applianceCounts = {};
  applianceSelected = {};
  applianceList = [];
  step = 1;
  applianceMap: any;
  constructor(
    private http: HttpClient,
    public api: CommonApiService,
    public _router: Router
  ) {}

  ngOnInit(): void {
    this.http
      .get('/assets/json/vyasApplianceList.json')
      .subscribe((applianceMap) => {
        this.applianceMap = applianceMap;
      });
  }

  nextStep() {
    if (this.step == 1) {
      _.forEach(this.selectApplianceTypes, (value, applianceName) => {
        _.forEach(value, (value2, applianceType) => {
          if (value2['selected']) {
            if (!this.applianceCounts[applianceName]) {
              this.applianceCounts[applianceName] = {};
            }
            this.applianceCounts[applianceName][applianceType] = 1;
          }
        });
      });
    }

    if (this.step == 2) {
     
      _.forEach(this.applianceCounts, (appliance, applianceName) => {
        this.applianceSelected[applianceName] = [];
        _.forEach(appliance, (count, applianceType) => {
          for (let i = 0; i < count; i++) {
            let applianceObject = {
              applianceName: applianceName, // AC
              applianceType: applianceType, // AC_SPLIT
              applianceNickname: `${
                this.applianceMap[applianceName][applianceType]['readableName']
              } ${i + 1}`, // AC 2
            };
            this.applianceList.push(applianceObject);
            this.applianceSelected[applianceName].push(applianceObject);
          }
        });
      });
    }

    if (this.step == 3) {
      // 1. format data
      // 2. API call
      // 3. goto vyas home page
      this.submitAppliances();
    }

    if (this.step < 3) this.step++;
  }

  submitAppliances() {
    let finalList = [];
    _.forEach(this.applianceSelected, (applianceList, applianceName) => {
      finalList = finalList.concat(applianceList);
    });
    this.api
      .addMultipleAppliance(finalList)
      .pipe(first())
      .subscribe((res) => {
        if (res) {
          this._router.navigate(['/vyas']);
        }
      });
  }
}
