import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { CommonApiService } from "src/app/service/common-api.service";
declare var $: any;
import { Location } from "@angular/common";

@Component({
  selector: "app-appliance-tagged",
  templateUrl: "./appliance-tagged.component.html",
  styleUrls: ["./appliance-tagged.component.scss"],
})
export class ApplianceTaggedComponent implements OnInit {
  applianceName: any;
  loader: boolean = false;
  appliance: any = [];
  showButton: boolean = false;
  question_id: any;
  appliance_id: any;
  url_path: any;
  constructor(
    public _router: Router,
    private route: ActivatedRoute,
    private _api: CommonApiService
  ) {}

  ngOnInit(): void {
    this.applianceName = this.route.snapshot.params["applianceName"];
    this.question_id = this.route.snapshot.params["question_id"];
    this.selectApplianceList();

    let url = this._router.url;
    this.url_path = url.includes("wrong_detection");
    console.log(this.url_path);
  }

  selectApplianceList() {
    this._api
      .getSelectedAppliance(this.applianceName)
      .pipe(first())
      .subscribe((res) => {
        this.appliance = res;
        this.appliance.forEach((element) => {
          element.selected = false;
        });
      });
  }

  applianceSelect(i) {
    this.appliance.forEach((element) => {
      element.selected = false;
    });
    this.appliance[i].selected = true;
    this.appliance_id = this.appliance[i]["_id"];
    this.showButton = true;
  }
  tagAppliance() {
    if (this.url_path === true) {
      this._api
        .verifyApplianceUsageWrong(
          this.question_id,
          `no&appliance_id=${this.appliance_id}`
        )
        .pipe(first())
        .subscribe((res) => {
          if (res) {
            try {
              $("#selectedAppliance").modal().show();
            } catch (error) {
              $("#selectedApplianceError").modal().show();
            }
          }
        });
    } else {
      this._api
        .verifyApplianceUsageWrong(
          this.question_id,
          `yes&appliance_id=${this.appliance_id}`
        )
        .pipe(first())
        .subscribe((res) => {
          if (res) {
            try {
              $("#selectedAppliance").modal().show();
            } catch (error) {
              $("#selectedApplianceError").modal().show();
            }
          }
        });
    }
  }

  done() {
    this._router.navigate(["/analysis"]);
    $(".modal-backdrop").remove();
  }
}
