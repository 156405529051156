<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-6 p-0 d-flex align-items-center">
            <a routerLink="/dashboard">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-md-3 pr-3 d-lg-inline-block title_get">
              Account Info
            </a>
          </div>
          <div class="form-inline my-2 my-lg-0 mr-2">
            <a class="text-light" routerLink="/edit-profile">EDIT</a>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <div class="container profile__container">
    <div class="row mt-5">
      <div class="col-12 mt-5">
        <img
          src="https://www.w3schools.com/howto/img_avatar.png"
          alt="Avatar"
          class="avatar"
        />
        <p class="text-dark mb-0 mt-2 text-center" *ngIf="profile">
          {{ profile.displayName }}
        </p>
        <p class="text-dark mb-0 text-center" *ngIf="profile">
          {{ profile.mobile }}
        </p>
        <p class="text-dark mb-0 mt-2 text-center" *ngIf="!profile">---</p>
      </div>
    </div>
  </div>
  <div class="container profile__details__container mt-5 pb-5">
    <div class="row mt-3">
      <div class="col-6">User since</div>
      <div
        class="col-6 d-flex justify-content-end text__details"
        *ngIf="created"
      >
        {{ created }}
      </div>
      <div
        class="col-6 d-flex justify-content-end text__details"
        *ngIf="!created"
      >
        ---
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">Email ID</div>
      <div
        class="col-6 d-flex justify-content-end text__details"
        *ngIf="profile"
      >
        {{ profile.email }}
      </div>
      <div
        class="col-6 d-flex justify-content-end text__details"
        *ngIf="!profile"
      >
        ---
      </div>
    </div>
    <div class="row mt-3" *ngFor="let meter of bot; let i = index">
      <div class="col-6">Bot {{ i + 1 }}</div>
      <div class="col-6 d-flex justify-content-end text__details">
        {{ bot[i] }}
      </div>
    </div>
  </div>
</section>
