import { first } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { CommonApiService } from "src/app/service/common-api.service";

@Component({
  selector: "app-bot-profile",
  templateUrl: "./bot-profile.component.html",
  styleUrls: ["./bot-profile.component.scss"],
})
export class BotProfileComponent implements OnInit {
  address: any;
  pincode: any;
  member: any;
  houseType: any;
  loader: boolean = false;
  meter_id: any;
  bot: any;
  constructor(
    public _api: CommonApiService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loader = true;
    this.meter_id = this.route.snapshot.params["meter_id"];
    this.getBotInfo();
  }

  getBotInfo() {
    this.loader = true;
    this._api
      .botInfoGet(this.meter_id)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.bot = res;
      });
  }

  saveDetails() {
    this.loader = true;
    let formdata = {
      address: this.address,
      pincode: this.pincode,
      member: this.member,
      houseType: this.houseType,
    };

    this._api
      .botDetailsUpdate(this.meter_id, formdata)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.router.navigateByUrl(`/utility/${this.meter_id}`);
      });
  }
}
