import { Component, OnInit } from "@angular/core";
import { first } from "rxjs/operators";
import { CommonApiService } from "src/app/service/common-api.service";

@Component({
  selector: "app-track-issue",
  templateUrl: "./track-issue.component.html",
  styleUrls: ["./track-issue.component.scss"],
})
export class TrackIssueComponent implements OnInit {
  tickets: any = [];
  record_type_id: any;
  loader: boolean = false;
  noData: boolean = false;
  constructor(public _api: CommonApiService) {}

  ngOnInit(): void {
    this.loader = true;
    this.getAllTickets();
  }

  getAllTickets() {
    this.loader = true;
    this._api
      .getTicket()
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.tickets = res;
        this.tickets.forEach((e) => {
          if (e.record_type_id === "a0P0w000003vRvgEAE") {
            this.record_type_id = "Firmware";
          }
          if (e.record_type_id === "0120w00000179tfAAA") {
            this.record_type_id = "General";
          }
          if (e.record_type_id === "0120w00000179tLAAQ") {
            this.record_type_id = "Hardware";
          }
          if (e.record_type_id === "0120w00000179taAAA") {
            this.record_type_id = "Onboarding";
          }
          if (e.record_type_id === "0120w00000179tVAAQ") {
            this.record_type_id = "Software";
          }
          if (e.record_type_id === "0120w00000179tkAAA") {
            this.record_type_id = "OthersOpen";
          }
        });
      });
  }

  filterBy(e) {
    this.loader = true;

    this.tickets = this.tickets.filter((data) => {
      this.loader = false;
      if (data.status == null) {
        this.noData = true;
      } else {
        data.status == e.target.value;
      }
    });

    if (e.target.value == "All") {
      this.getAllTickets();
    }
  }
}
