import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from './../../../../service/common-api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bot-info-edit',
  templateUrl: './bot-info-edit.component.html',
  styleUrls: ['./bot-info-edit.component.scss'],
})
export class BotInfoEditComponent implements OnInit {
  loader: boolean = false;
  meter_id: any;
  bot: any;
  nickName: any;
  constructor(
    public _api: CommonApiService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loader = true;
    this.meter_id = this.route.snapshot.params['meter_id'];
    this.getBotInfo();
  }

  getBotInfo() {
    this.loader = true;
    this._api
      .botInfoGet(this.meter_id)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.bot = res;
      });
  }

  saveDetails() {
    this.loader = true;
    let formdata = {
      nickName: this.bot.nickName,
    };

    this._api
      .botDetailsUpdate(this.meter_id, formdata)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.router.navigateByUrl(`/bot-info-details/${this.meter_id}`);
      });
  }
}
