<app-loader *ngIf="loader"></app-loader>
<section>
  <div class="container-fluid">
    <div class="row">
      <app-navbar></app-navbar>
    </div>
    <div class="noAppliance" *ngIf="results.length == 0">
      <div class="row top__row mt-5">
        <div class="col-md-12 mt-4">
          <p class="text-light">Device diagnosis & maintenance</p>
        </div>
        <div class="col-md-12">
          <a routerLink="/vyas-onboarding">
            <img
              src="../../../assets/img/vyas/add.png"
              alt=""
              class="img-fluid add__btn"
            />
          </a>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">
          <img
            src="../../../assets/img/vyas/noAppliance.png"
            alt=""
            class="img-fluid d-block m-auto"
          />
        </div>
        <div class="col-md-12 mt-3">
          <img
            src="../../../assets/img/vyas/noApplianceText.png"
            alt=""
            class="img-fluid d-block m-auto"
            width="90%"
          />
        </div>
      </div>
    </div>
    <div class="appliance" *ngIf="results.length != 0">
      <div class="row top__row mt-5">
        <div class="col-md-12 mt-4">
          <p class="text-light">Device diagnosis & maintenance</p>
        </div>
        <div class="col-md-12">
          <img
            src="../../../assets/img/vyas/add.png"
            alt=""
            class="img-fluid add__btn"
            (click)="createAppliance()"
            style="cursor: pointer"
          />
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div
          class="col-6 card_items mt-3"
          *ngFor="let items of results; let index = odd"
        >
          <div class="card device_card">
            <div class="card-body p-3">
              <div class="top_details">
                <!-- <img src="../../../assets/img/ac.svg" alt="ac" class="img-fluid device_img"> -->
                <div class="row align-items-center">
                  <div class="col-5 p-0">
                    <img
                      src="../../../assets/img/vyas/appliance/ac.png"
                      alt="ac"
                      class="img-fluid device_img"
                      *ngIf="items.applianceName == 'AC'"
                    />
                    <img
                      src="../../../assets/img/vyas/appliance/geyser.png"
                      alt="ac"
                      class="img-fluid device_img"
                      *ngIf="items.applianceName == 'GEYSER'"
                    />
                    <img
                      src="../../../assets/img/vyas/appliance/wm.png"
                      alt="ac"
                      class="img-fluid device_img"
                      *ngIf="items.applianceName == 'WM'"
                    />
                    <img
                      src="../../../assets/img/vyas/appliance/heating.png"
                      alt="ac"
                      class="img-fluid device_img"
                      *ngIf="items.applianceName == 'HEATING_DEVICE'"
                    />
                    <img
                      src="../../../assets/img/vyas/appliance/fridge.png"
                      alt="ac"
                      class="img-fluid device_img"
                      *ngIf="items.applianceName == 'FRIDGE'"
                    />
                    <img
                      src="../../../assets/img/vyas/appliance/waterPump.png"
                      alt="ac"
                      class="img-fluid device_img"
                      *ngIf="items.applianceName == 'WATER_PUMP'"
                    />
                    <img
                      src="../../../assets/img/vyas/appliance/other.png"
                      alt="ac"
                      class="img-fluid device_img"
                      *ngIf="items.applianceName == 'OTHERS'"
                    />
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center p-0"
                  >
                    <img
                      src="../../../assets/img/vyas/appliance/successsmile.svg"
                      alt="successsmile"
                      class="img-fluid smile_icon mr-2"
                      *ngIf="items.healthStatus == 'HEALTHY'"
                      width="35%"
                    />
                    <div>
                      <img
                        src="../../../assets/img/vyas/menu.png"
                        alt=""
                        class="img-fluid w-auto"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="cursor: pointer"
                        (click)="vyasModal(items)"
                      />
                      <!-- <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Dropdown button
                      </button> -->
                      <!-- <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">Train your bot</a>
                        <a class="dropdown-item" (click)="diagnose(items._id)"
                          >Diagnosis</a
                        >
                      </div> -->
                    </div>
                  </div>
                </div>

                <div (click)="view_details(items)" style="cursor: pointer">
                  <h6 class="mt-5">
                    {{ items.applianceNickname }}
                  </h6>
                  <p class="mt-2">MODEL :{{ items.applianceModelNo }}</p>
                </div>
              </div>
              <!-- <div class="confidence">
                <small>Confidence level (78%)</small>
                <div class="progress mt-2">
                  <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                    style="width: 25%" aria-valuenow="25"></div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-bottom-navbar></app-bottom-navbar>
</section>

<div
  class="modal fade modal-bottom pb-5"
  id="vyasModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="vyasModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div
      class="modal-content pb-3"
      style="border-top-left-radius: 10px; border-top-right-radius: 10px"
    >
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>Appliance Options</h5>
            </div>
            <div
              class="col-md-12 mt-3 d-flex justify-content-between align-items-center"
            >
              <button class="btn train__btn" type="button" disabled>
                Train Appliance
              </button>
              <button
                *ngIf="selected__appliance"
                class="btn train__btn"
                type="button"
                (click)="diagnose()"
                [ngClass]="{
                  gray__btn: selected__appliance.canDiagnose === false
                }"
                [disabled]="selected__appliance.canDiagnose === false"
              >
                Diagnose
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
