import { first } from 'rxjs/operators';
import { CommonApiService } from './../../../../service/common-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-appliance-profile-details',
  templateUrl: './appliance-profile-details.component.html',
  styleUrls: ['./appliance-profile-details.component.scss'],
})
export class ApplianceProfileDetailsComponent implements OnInit {
  applianceDetails: any;
  diagnosisStatus: any;
  @Input() appliance: any;
  serviceData: any;
  appliance_id: any;
  constructor(
    public router: Router,
    public _api: CommonApiService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.appliance_id = this.route.snapshot.params['appliance_id'];
    this.service();

    this._api
      .diagnosisDataGet(this.appliance_id)
      .pipe(first())
      .subscribe((res) => {
        if (res == []) {
          this.diagnosisStatus = '---';
        } else {
          this.diagnosisStatus = res[0]['diagnosis'][0]['status'];
        }
      });
  }

  edit() {
    this.router.navigateByUrl(`/edit-appliance/${this.appliance._id}`, {
      state: this.appliance,
    });
  }

  deleteAppliance(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this._api
          .deleteAppliance(id)
          .pipe(first())
          .subscribe((res) => {
            this.router.navigate(['vyas']);
            // Swal.fire(
            //   "Deleted!",
            //   "Your Appliance has been deleted.",
            //   "success"
            // );
          });
      }
    });
  }

  service() {
    this._api
      .getServiceHistory(this.appliance_id)
      .pipe(first())
      .subscribe((res: Array<any>) => {
        if (res.length) {
          this.serviceData = res[0]['dateOfService'];
        }
      });
  }
}
