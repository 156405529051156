<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-12 p-0 d-flex align-items-center">
            <a (click)="navPrev()">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get"
              >Appliance training guidelines</a
            >
          </div>
          <div class="col-12 p-0">
            <div class="row ml-5 align-items-center">
              <div class="col-12 pl-1">
                <small *ngIf="appliance" class="text-light mb-0">
                  Let's train your {{ appliance.applianceName }}
                </small>
                <br />
                <small class="text-light" *ngIf="appliance"
                  >Modal no. {{ appliance.applianceModelNo }}
                </small>
              </div>
              <!-- <div class="col-3 p-0">
                <img
                  src="../../../../assets/img/appliance/spiltAC.svg"
                  alt=""
                  class="img-fluid"
                  style="filter: invert(100%)"
                />
              </div> -->
            </div>
          </div>
          <div class="form-inline my-2 my-lg-0"></div>
        </nav>
      </div>
    </div>
    <div class="row top__row">
      <div class="col-12">
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <!-- <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> -->
          </ol>
          <div class="carousel-inner mb-5">
            <div class="carousel-item active">
              <img
                src="../../../../assets/img/trainYourBot/Gif 1.gif"
                alt=""
                class="img-fluid d-block ml-auto mr-auto mt-5 mb-5"
              />
              <p class="font-weight-bold text-center">
                Leave only essential appliances ON, If they are being used.
              </p>
              <p class="font-weight-bold text-center">
                Essential appliances eg: Lights, Fans & Wifi
              </p>
            </div>
            <!-- <div class="carousel-item">
              <img
                src="../../../../assets/img/trainYourBot/gif-2.gif"
                alt=""
                class="img-fluid d-block ml-auto mr-auto mt-5 mb-5"
              />
              <p class="font-weight-bold text-center mt-5">Note:</p>
              <p class="text-center">
                Turn OFF , all heavy appliances to prevent erroneous
                results.This includes the appliances that you wish to diagnose.
              </p>
              <p class="text-center">For eg: Geysers, Ovens etc.</p>
            </div> -->
            <div class="carousel-item">
              <img
                src="../../../../assets/img/trainYourBot/Comp-1_2.gif"
                alt=""
                class="img-fluid d-block ml-auto mr-auto mt-5 mb-5"
              />

              <p class="font-weight-bold text-center">
                To avoid mistraining, turn OFF all heavy appliances. This covers
                the appliances you'd like to train.
              </p>
              <p class="font-weight-bold text-center">
                Eg: Geysers, ovens, air conditioners
              </p>
              <div class="custom-control custom-checkbox text-center">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck1"
                  (click)="showButton()"
                />
                <label
                  class="custom-control-label font-weight-bold"
                  for="customCheck1"
                  >I have turned OFF, all heavy appliances</label
                >
              </div>
            </div>
          </div>

          <!-- <a
            class="carousel-control-prev arrow"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true">
              <i class="fa fa-chevron-left"></i
            ></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next arrow"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true">
              <i class="fa fa-chevron-right"></i
            ></span>
            <span class="sr-only">Next</span>
          </a> -->
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button
          class="btn train__btn"
          id="train__btn"
          (click)="startTraining()"
        >
          <img
            src="../../../../assets/img/trainYourBot/button.svg"
            alt=""
            class="img-fluid"
          />
        </button>
      </div>
    </div>
  </div>
</section>
