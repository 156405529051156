<section>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-3 red__background">
        <h1 class="text-center text-light font-weight-bold">3</h1>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <h4 class="font-weight-bold text-center">Bot Profile</h4>
        <p class="mt-3">
          We need to know the bot details to help you with better analysis and
          recommendation. At Ohm, we value your privacy.
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12 mt-3">
        <form
          #form_profile="ngForm"
          class="form_profile"
          id="form_profile"
          name="form_profile"
        >
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Address"
              id="address"
              [(ngModel)]="address"
              name="address"
              required
            />
          </div>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Pincode"
              id="pincode"
              [(ngModel)]="pincode"
              name="pincode"
              required
            />
          </div>
          <div class="input-group mb-3">
            <select
              type="text"
              class="form-control"
              placeholder="member"
              id="member"
              [(ngModel)]="member"
              name="member"
              required
            >
              <option disabled selected value="undefined">
                No. of family members
              </option>
              <option value="1BHK">1BHK</option>
              <option value="2BHK">2BHK</option>
              <option value="3BHK">3BHK</option>
              <option value="Bunglow">Bunglow</option>
              <option value="Small Office">Small Office</option>
              <option value="Restaurant">Restaurant</option>
            </select>
          </div>
          <div class="input-group mb-3">
            <select
              type="text"
              class="form-control"
              placeholder="houseType"
              id="houseType"
              [(ngModel)]="houseType"
              name="houseType"
              required
            >
              <option disabled selected value="undefined">House type</option>
              <option value="0-4">0-4</option>
              <option value="5-8">5-8</option>
              <option value="9-12">9-12</option>
              <option value="Bunglow">Bunglow</option>
              <option value="12 & more">12 & more</option>
            </select>
          </div>

          <div class="row mt-5">
            <div class="col-md-12">
              <button
                type="button"
                class="btn w-100 mt-5"
                (click)="saveDetails()"
              >
                <img src="../../../../assets/img/Login/button.png" alt="" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
