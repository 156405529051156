import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import { CommonApiService } from "./../../service/common-api.service";
import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
declare var $: any;

@Component({
  selector: "app-vyas",
  templateUrl: "./vyas.component.html",
  styleUrls: ["./vyas.component.scss"],
})
export class VyasComponent implements OnInit {
  selected_meter_id: any;
  appliance: any = [];
  results: any = [];
  loader: boolean = false;
  odd: any;
  selected__appliance: any;
  constructor(public _api: CommonApiService, public router: Router) {}

  ngOnInit(): void {
    this.loader = true;
    this.selected_meter_id = localStorage.getItem("selected_meter");

    $(".close-icon").on("click", function () {
      $(this).closest(".card").fadeOut();
    });

    this.getAppliance();
  }
  getAppliance() {
    this._api
      .getAppliance()
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.results = res;
      });
  }

  view_details(appliance) {
    console.log(appliance);
    
    this.router.navigateByUrl(`/view-appliance/${appliance._id}`);
    // this.router.navigateByUrl(`/view-appliance/${appliance._id}`, { 'state': appliance })
  }

  deleteAppliance(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this._api
          .deleteAppliance(id)
          .pipe(first())
          .subscribe((res) => {
            this.getAppliance();
            Swal.fire(
              "Deleted!",
              "Your Appliance has been deleted.",
              "success"
            );
          });
      }
    });
  }

  editAppliance(items) {
    this.router.navigateByUrl("/add-appliance", { state: items });
  }

  diagnose() {
    // this.router.navigateByUrl('/diagnose', { 'state': items })
    this.router.navigateByUrl(`/diagnose/${this.selected__appliance["_id"]}`);
    $(".modal-backdrop").remove();
  }
  createAppliance() {
    this.router.navigateByUrl(`/create-appliance`, { state: {} });
  }

  game(id) {
    this.router.navigateByUrl(`/game-bot/${id}`);
  }

  vyasModal(items) {
    $("#vyasModal").modal().show();
    this.selected__appliance = items;
  }
}
