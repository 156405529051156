<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-6 p-0 d-flex align-items-center">
            <a routerLink="/train-your-bot">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get">{{
              applianceName
            }}</a>
          </div>
          <div class="form-inline my-2 my-lg-0"></div>
        </nav>
      </div>
    </div>
    <div class="row mt-5 mb-5">
      <div
        class="col-6 card_items mt-4"
        *ngFor="let items of appliance"
        (click)="selectAppliance(items)"
      >
        <div class="card device_card" style="cursor: pointer">
          <div class="card-body p-3">
            <div class="top_details">
              <!-- <img src="../../../assets/img/ac.svg" alt="ac" class="img-fluid device_img"> -->
              <div class="row">
                <div class="col-12 card__img p-0">
                  <img
                    src="../../../assets/img/vyas/appliance/ac.png"
                    alt="ac"
                    class="img-fluid device_img"
                    *ngIf="items.applianceName == 'AC'"
                  />
                  <img
                    src="../../../assets/img/vyas/appliance/geyser.png"
                    alt="ac"
                    class="img-fluid device_img"
                    *ngIf="items.applianceName == 'GEYSER'"
                  />
                  <img
                    src="../../../assets/img/vyas/appliance/wm.png"
                    alt="ac"
                    class="img-fluid device_img"
                    *ngIf="items.applianceName == 'WM'"
                  />
                  <img
                    src="../../../assets/img/vyas/appliance/heating.png"
                    alt="ac"
                    class="img-fluid device_img"
                    *ngIf="items.applianceName == 'HEATING_DEVICE'"
                  />
                  <img
                    src="../../../assets/img/vyas/appliance/fridge.png"
                    alt="ac"
                    class="img-fluid device_img"
                    *ngIf="items.applianceName == 'FRIDGE'"
                  />
                  <img
                    src="../../../assets/img/vyas/appliance/waterPump.png"
                    alt="ac"
                    class="img-fluid device_img"
                    *ngIf="items.applianceName == 'WATER_PUMP'"
                  />
                  <img
                    src="../../../assets/img/vyas/appliance/other.png"
                    alt="ac"
                    class="img-fluid device_img"
                    *ngIf="items.applianceName == 'OTHERS'"
                  />
                </div>
                <div class="col-12 ml-2">
                  <p>{{ items.applianceNickname }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
