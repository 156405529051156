<app-loader *ngIf="loader"></app-loader>

<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-6 p-0 d-flex align-items-center">
            <a routerLink="/analysis">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
          </div>
          <div class="form-inline my-2 my-lg-0"></div>
        </nav>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12 px-4">
        <h3 *ngIf="applianceName" class="mt-4" style="color: #80868b">
          Select your {{ applianceName }}
        </h3>
      </div>
    </div>

    <div
      class="row mt-4"
      *ngFor="let appliance of appliance; let i = index"
      (click)="applianceSelect(i)"
      style="cursor: pointer"
    >
      <div
        class="col-md-12 pb-3 d-flex justify-content-between align-items-center px-4"
        style="border-bottom: 0.5px solid #dedede"
        id="appliance{{ i }}"
      >
        <p class="mb-0" [ngClass]="{ text__color: appliance.selected }">
          {{ appliance.applianceNickname }}
        </p>

        <img
          src="../../../../assets/img/appliance/select.svg"
          alt=""
          class="hide"
          [ngClass]="{ visible: appliance.selected }"
        />
      </div>
    </div>

    <div class="row mt-5" *ngIf="showButton">
      <div class="col-md-12">
        <button class="btn" (click)="tagAppliance()" type="button">
          Save Changes
        </button>
      </div>
    </div>
  </div>

  <div
    class="modal fade modal-bottom pb-5"
    id="selectedAppliance"
    tabindex="-1"
    role="dialog"
    aria-labelledby="selectedApplianceLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div
        class="modal-content pb-5"
        style="border-top-left-radius: 10px; border-top-right-radius: 10px"
      >
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <img
                  src="../../../../assets/img/analysis/success.png"
                  alt=""
                  class="img-fluid d-block m-auto"
                />
              </div>
              <div class="col-md-12">
                <button class="btn success_btn" type="button" (click)="done()">
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade modal-bottom pb-5"
    id="selectedApplianceError"
    tabindex="-1"
    role="dialog"
    aria-labelledby="selectedApplianceErrorLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div
        class="modal-content pb-5"
        style="border-top-left-radius: 10px; border-top-right-radius: 10px"
      >
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <img
                  src="../../../../assets/img/analysis/error.png"
                  alt=""
                  class="img-fluid d-block m-auto"
                />
                <p class="text-center" style="color: #80868b">
                  Oops! Something went wrong.
                </p>
              </div>
              <div class="col-md-12">
                <button
                  class="btn error_btn"
                  type="button"
                  routerLink="/analysis"
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
