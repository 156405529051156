import { MqttService } from "./../../service/mqtt.service";
import { CommonApiService } from "./../../service/common-api.service";
import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { filter, first, pairwise } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { Router, RoutesRecognized } from "@angular/router";


@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  @Input() selectedMeterId: string;
  estimatedConsumption: any;
  mqttService: any;
  liveActivePower: String;
  loader: boolean = false;
  currentDate: any;
  mqttTime: any;
  lastTime: any;
  img__div: boolean = true;
  graph__div: boolean = false;
  am: boolean = false;
  pm: any;
  displayName: any;

  dogStatus = 0; // 1(AM), 2(PM), 3(OFFLINE)
  previousUrl: any;



  constructor(private commonApi: CommonApiService, private router: Router) {}

  meterChangeEvent(ev) {
    this.renderEstimatedConsumption();
    if (this.mqttService) this.mqttService.close();
    this.selectedMeterId = ev;
    this.startLiveData();
  }

  ngOnInit(): void {
    this.renderEstimatedConsumption();
    this.loader = true;
    if (this.mqttService) this.mqttService.close();
    this.selectedMeterId = localStorage.getItem("selected_meter");
    this.startLiveData();
    this.getTime();
    this.dogStatus = 3;
    this.displayName = JSON.parse(localStorage.getItem("user"));

    this.router.events
      .pipe(
        filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((e: any) => {
        console.log(e[0].urlAfterRedirects);
        this.previousUrl = e[0].urlAfterRedirects;
        localStorage.setItem("previousUrl", this.previousUrl);
      });

    // document.getElementById('down').style.display = "none"
  }
  
  ngOnChanges() {
    this.lastTime = "trying to connect...";
    this.dogStatus = 3;
    this.liveActivePower = null;
    this.startLiveData();
    this.renderEstimatedConsumption();
  }
  ngOnDestroy() {
    if (this.mqttService) this.mqttService.close();
  }

  renderEstimatedConsumption() {
    // fetch data from api
    this.commonApi
      .getEstimatedConsumption(false)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.estimatedConsumption = res;
      });
  }

  startLiveData() {
    // close old connection if any
    // start new mqtt subscription
    if (this.mqttService) {
      this.mqttService.close();
      this.mqttService = null;
    }
    this.mqttService = new MqttService(environment.mqtt_host, [
      parseInt(this.selectedMeterId),
    ]);
    this.mqttService.listen().subscribe((data) => {
      this.liveActivePower = data.a.toFixed(1);

      this.mqttTime = moment(data.t);

      // this.getLastUpdateTime();
      let now = moment().hour();
      console.log(now);
      if (now > 6 && now < 18) {
        this.dogStatus = 1;
      } else {
        this.dogStatus = 2;
      }
      if (data.retained) {
        this.dogStatus = 3;
      }

      this.lastTime = moment(data.t * 1000).from(moment());

      localStorage.setItem("lastUpdated", this.lastTime);
    });
  }

  getTime() {
    let currentTiem = moment().format("LT");
    if (
      moment().format("LT") < "6:00 PM" &&
      moment().format("LT") > "6:00 AM"
    ) {
      this.am = true;
    } else {
      this.am = false;
    }
  }

  swipeUp() {
    this.img__div = false;
    this.graph__div = true;
  }
  swipeDown() {
    this.img__div = true;
    this.graph__div = false;
  }
}
