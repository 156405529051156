import { CommonApiService } from './service/common-api.service';
import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ohmassistant-pwa';
  constructor(public afAuth: AngularFireAuth, public router: Router, public commonApi: CommonApiService) {

    this.afAuth.authState.subscribe(user => {
      if (user) {

        // getIdToken(new?)
        // TRUE - issue new token
        // FLASE - return existing token
        // keep this false, if session invalid user will be null
        // user.getIdToken(false).then(token => {
        user.getIdToken(false).then(token => {
          localStorage.setItem("authToken", token)
        }).catch(err => {
        })

        // fetch user data (from sust api)

        // this.commonApi.userUpdate(user).pipe(first()).subscribe(res=> {
        //   this.router.navigate(['/dashboard'])
        // })

        // if user displayName exist -> make him enter displayName

        // if user meter list is empty -> show error
        //  else -> go to dashboard with default meter as meterList[0]

      } else {
        // this.router.navigate(['/unregister'])
        this.router.navigate(['/login'])

      }
    })
  }

}
