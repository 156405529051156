<section>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-3 red__background">
        <h1 class="text-center text-light font-weight-bold">
          <i class="fa fa-check"></i>
        </h1>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12">
        <h4 class="font-weight-bold text-center">All Done!</h4>
        <p class="font-weight-bold">Building your Dashboard...</p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <img
          src="../../../../assets/img/Login/allDone.png"
          alt=""
          class="img-fluid d-block m-auto"
        />
      </div>
    </div>
  </div>
</section>
