<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <app-navbar></app-navbar>
      </div>
    </div>
    <!-- <div class="row top__row mt-5">
      <div class="col-6 d-flex justify-content-center mt-3">
        <p class="text-light">Ohm Assistant</p>
      </div>
    </div> -->
    <div class="row mt-5 pt-5">
      <!-- <div class="col-md-4 col-4 d-flex justify-content-center align-items-center flex-column">
        <a routerLink="/train-your-bot">
          <div class="card" style="position: relative">
            <div class="card-body">
              <img src="../../../assets/img/more/coming.png" alt="" class="img-fluid"
                style="position: absolute; top: 0; right: 0; height: 32px" />
              <img src="../../../assets/img/more/trainyourbot.png" alt="" class="img-fluid" />
            </div>
          </div>
        </a>
        <small class="mt-2">Train your bot</small>
      </div> -->
      <div
        class="col-md-4 col-4 d-flex justify-content-center align-items-center flex-column"
      >
        <a href="https://paytm.com/electricity-bill-payment" target="_blank">
          <div class="card">
            <div class="card-body">
              <img
                src="../../../assets/img/more/payBill.svg"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </a>
        <small class="mt-2">Pay bills</small>
      </div>
      <div
        class="col-md-4 col-4 d-flex justify-content-center align-items-center flex-column"
      >
        <a routerLink="/my-bot">
          <div class="card">
            <div class="card-body">
              <img
                src="../../../assets/img/more/mybot.svg"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </a>
        <small class="mt-2">My Bot(s)</small>
      </div>
      <div
        class="col-md-4 col-4 d-flex justify-content-center align-items-center flex-column"
      >
        <a (click)="diagnosis()">
          <div class="card">
            <div class="card-body">
              <img
                src="../../../assets/img/more/diagnosis.svg"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </a>
        <small class="mt-2">Diagnosis</small>
      </div>
      <div
        class="col-md-4 col-4 d-flex justify-content-center align-items-center flex-column mt-5"
      >
        <a routerLink="/budget">
          <div class="card">
            <div class="card-body">
              <img
                src="../../../assets/img/more/budget.svg"
                alt=""
                class="img-fluid"
                style="width: 30px"
              />
            </div>
          </div>
        </a>
        <small class="mt-2">Budget</small>
      </div>
      <div
        class="col-md-4 col-4 d-flex justify-content-center align-items-center flex-column mt-5"
      >
        <a routerLink="/programs">
          <div class="card">
            <div class="card-body">
              <img
                src="../../../assets/img/more/program.png"
                alt=""
                class="img-fluid"
                style="width: 30px"
              />
            </div>
          </div>
        </a>
        <small class="mt-2">Programs</small>
      </div>
      <div
        class="col-md-4 col-4 d-flex justify-content-center align-items-center flex-column mt-5"
      >
        <a routerLink="/support">
          <div class="card">
            <div class="card-body">
              <img
                src="../../../assets/img/sidebar/support.svg"
                alt=""
                class="img-fluid"
                style="width: 30px"
              />
            </div>
          </div>
        </a>
        <small class="mt-2">Support</small>
      </div>
    </div>
  </div>
  <app-bottom-navbar></app-bottom-navbar>
</section>
