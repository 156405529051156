<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent d-flex justify-content-between align-items-center">
          <div class="col-6 p-0 d-flex align-items-center">
            <a routerLink="/my-bot">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get"> Bot Info </a>
          </div>
          <div class="form-inline my-2 my-lg-0 mr-2">
            <a class="title_get" (click)="botInfoEdit()" style="cursor: pointer"
              >EDIT</a
            >
          </div>
        </nav>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-5">
      <div class="col-12 mt-5 p-4">
        <p class="font-weight-bold">Bot info</p>
        <div class="row">
          <div class="col-md-6">Bot Name</div>
          <div class="col-md-6 d-flex justify-content-end">
            <p class="text__details" *ngIf="bot_name">
              {{ bot_name.nickName }}
            </p>
            <p class="text__details" *ngIf="!bot_name">---</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
