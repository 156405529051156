<app-loader *ngIf="loader"></app-loader>
<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-6 p-0 d-flex align-items-center">
            <a routerLink="/more">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get">Budget</a>
          </div>
          <div class="form-inline my-2 my-lg-0"></div>
        </nav>
      </div>
    </div>
    <div class="row top__row mt-5">
      <div class="col-6 d-flex justify-content-center mt-3">
        <button
          class="btn top_btn"
          [ngClass]="{ active: clicked === true }"
          data-toggle="tab"
          (click)="clicked = true; clicked1 = false"
          (click)="calenderBilling()"
        >
          Calender
        </button>
      </div>
      <div class="col-6 d-flex justify-content-center mt-3">
        <button
          class="btn top_btn"
          [ngClass]="{ active: clicked1 === true }"
          data-toggle="tab"
          (click)="clicked = false; clicked1 = true"
          (click)="utilityBilling()"
        >
          Utility
        </button>
      </div>
    </div>
    <div class="row" style="margin-top: 120px">
      <div class="col-2 d-flex justify-content-center align-items-center">
        <img
          src="../../../assets/img/budget/energy.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-10 p-0 d-flex justify-content-start align-items-center">
        <p class="mt-3">Your consumption this month</p>
      </div>
      <div class="col-md-12 mt-3 d-flex justify-content-center">
        <div class="budget__circle" *ngIf="calender">
          <p class="text-center mt-2" *ngIf="netMonthConsumption">
            <span
              >{{
                netMonthConsumption.thisMonthNetConsumption.toFixed(0)
              }}
              Units</span
            >
          </p>
          <p class="text-center" *ngIf="!netMonthConsumption">
            <span>---</span>
          </p>
        </div>
        <div
          class="budget__circle"
          *ngIf="utility"
          (click)="utilityRoute()"
          style="cursor: pointer"
        >
          <p class="text-center mt-2" *ngIf="netMonthConsumption">
            <span
              >{{
                netMonthConsumption.thisMonthNetConsumption.toFixed(0)
              }}
              Units</span
            >
          </p>
          <p class="text-center" *ngIf="!netMonthConsumption">
            <span>---</span>
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5 percentage_data">
      <div
        class="col-6 d-flex justify-content-center align-items-center flex-column"
      >
        <!-- <h4>LOW</h4> -->
        <h5 *ngIf="netCost">₹{{ netCost.toFixed(0) }}</h5>
        <h5 *ngIf="!netCost">---</h5>
        <p>Bill so far</p>
      </div>
      <div
        class="col-6 d-flex justify-content-center align-items-center flex-column"
      >
        <h5 *ngIf="estimatedCost">₹{{ estimatedCost.toFixed(0) }}</h5>
        <h5 *ngIf="!estimatedCost">---</h5>
        <p>Estimated Bill</p>
      </div>
    </div>
    <div class="row mt-2 energy__row">
      <div class="col-2 d-flex justify-content-center align-items-center">
        <img
          src="../../../assets/img/budget/statment.png"
          alt=""
          class="img-fluid"
          width="45%"
        />
      </div>
      <div class="col-7 p-0">
        <p class="mt-4">
          Energy Statement <br /><span>Your latest energy statement</span>
        </p>
      </div>
      <div class="col-3 d-flex justify-content-center align-items-center">
        <button data-toggle="modal" data-target="#energySelectDateModal">
          View
        </button>
      </div>
    </div>
  </div>
  <!-- <app-bottom-navbar></app-bottom-navbar> -->
</section>

<!-- Energy Statement -->

<div
  class="modal fade modal-bottom pb-5"
  id="energySelectDateModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content pb-5">
      <div class="modal-header border-bottom-0">
        <h6 class="font-weight-bold" id="exampleModalLongTitle">
          Energy Statement
        </h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="selectDate__div" *ngIf="selectDate__div">
          <form
            #form_statement="ngForm"
            class="form_statement"
            id="form_statement"
            name="form_statement"
          >
            <div class="row">
              <div class="col-6">
                <div class="form-group mb-3">
                  <label for="fromData">Start Date</label>
                  <input
                    type="text"
                    onfocus="(this.type='date')"
                    class="form-control"
                    placeholder="From Date"
                    [(ngModel)]="fromDate"
                    id="fromData"
                    name="fromDate"
                    max="{{ today || today }}"
                    required
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mb-3">
                  <label for="toDate">End Date</label>
                  <input
                    type="text"
                    onfocus="(this.type='date')"
                    placeholder="Upto Date"
                    class="form-control"
                    [(ngModel)]="toDate"
                    name="toDate"
                    id="toDate"
                    min="{{
                      form_statement.value.fromDate ||
                        !form_statement.value.fromDate
                    }}"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-6">
                <button
                  type="button"
                  class="generate_btn"
                  (click)="getStatementResult()"
                  [disabled]="form_statement.form.invalid"
                >
                  Generate
                </button>
              </div>
              <div class="col-6">
                <button type="button" data-dismiss="modal" class="not_btn">
                  Not now
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="result__div" *ngIf="result__div">
          <p>
            Ohm recorded your consumption as {{ totalEC.toFixed(1) }} units of
            energy from {{ fromDate | date: "shortDate" }} to
            {{ toDate | date: "shortDate" }}
          </p>
          <div class="row mt-3">
            <div class="col-6">
              <button type="button" class="generate_btn" (click)="getDetails()">
                Get detailed statement
              </button>
            </div>
            <div class="col-6">
              <button type="button" data-dismiss="modal" class="not_btn">
                Not now
              </button>
            </div>
          </div>
        </div>
        <div class="email__div" *ngIf="email__div">
          <form
            #form_email="ngForm"
            class="form_email"
            id="form_email"
            name="form_email"
          >
            <div class="row">
              <div class="col-12">
                <div class="form-group mb-3">
                  <label for="fromData">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Email id "
                    type="text"
                    [(ngModel)]="email"
                    name="email"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-6">
                <button
                  type="button"
                  class="generate_btn"
                  (click)="getStatement()"
                  [disabled]="form_email.form.invalid"
                >
                  Send email
                </button>
              </div>
              <div class="col-6">
                <button type="button" data-dismiss="modal" class="not_btn">
                  Not now
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="allDone__div" *ngIf="allDone__div">
          <img
            src="../../../assets/img/budget/alldone.png"
            alt=""
            class="img-fluid d-block m-auto"
          />
          <p class="text-success text-center mt-3">Done</p>
        </div>
      </div>
    </div>
  </div>
</div>
