<!-- <button class="btn btn-primary" (click)="downloadAsPDF()">Export To PDF</button> -->

<section id="pdfDownload" #pdfDownload>
  <div class="container">
    <div class="row flex-column justify-content-center align-items-center">
      <div class="col-md-12 mt-5">
        <h1 class="font-weight-bold" style="font-size: 28px; color: #4472c4">
          ENERGY EFFICIENCY CLUB
        </h1>
      </div>
    </div>
    <div class="row flex-column justify-content-center align-items-center mt-5">
      <div class="col-md-12">
        <p class="mb-0">
          <strong>Electricity consumption report for:</strong>
        </p>
        <p class="mb-0"><strong>House Number:</strong>HH22</p>
      </div>
      <div class="col-md-12" style="background-color: #d9f7fd">
        <p class="mb-0"><strong>Name:</strong>Nikhil Karkare</p>
        <p class="mb-0">
          <strong>Address:</strong>CM-03, Sine office, Powai, 400076
        </p>
        <p class="mb-0"><strong>House Category:</strong>2Bhk.</p>
        <p class="mt-5">
          <strong>Gifts Locked:</strong>As we progress in this program you will
          be rewarded with some exciting offers.
        </p>
        <p class="mt-5">
          <strong>Quick Tip:</strong>Only fill the kettle with enough water
        </p>

        <img
          src="../../../assets/img/logo.svg"
          alt=""
          class="img-fluid d-block ml-auto mb-4"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p class="text-end">1ST FEBRUARY,2022- 28TH FEBRUARY, 2022</p>
      </div>
      <div class="col-md-12">
        <h2 style="font-size: 24px">
          APPLIANCE LEVEL <br />CONSUMPTION REPORT
        </h2>
        <br />
      </div>
      <div class="col-md-12">
        <canvas id="myDetailedAnalysisChart"></canvas>
      </div>
      <div class="col-md-12">
        <p>
          The Ac consumed <strong>20%</strong> more than the previous week and
          the geyser consumed 15% less in the last week.
        </p>
      </div>
    </div>
    <div
      class="row flex-column justify-content-center align-items-center pb-5 mb-5"
      style="background-color: #d9f7fd"
    >
      <div class="col-md-12">
        <h1 class="mt-5" style="font-size: 24px">
          YOUR WEEKLY ENERGY CONSUMPTION
        </h1>
      </div>
      <div class="col-md-12">
        <canvas id="analysisChart"></canvas>
      </div>
    </div>
  </div>
</section>
