<app-loader *ngIf="loader"></app-loader>

<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-6 p-0 d-flex align-items-center">
            <a routerLink="/analysis">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
          </div>
          <div class="form-inline my-2 my-lg-0"></div>
        </nav>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12 px-4">
        <h3 class="mt-4" style="color: #80868b">Select your appliance</h3>
      </div>
    </div>

    <div class="row mt-4 appliance__row">
      <div class="col-md-12 px-4 " (click)="notSure()">
        <div class="row">
          <div class="col-2">
            <img
              src="../../../../assets/img/analysis/notSure.svg"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-8 p-0">
            <p class="text-danger mb-0">I'm not sure</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 px-4" (click)="selectAppliance('AC')">
        <div class="row">
          <div class="col-2 pr-0">
            <img
              src="../../../../assets/img/appliance/spiltAC.svg"
              alt=""
              class="img-fluid"
              style="opacity: 0.5"
            />
          </div>
          <div class="col-8 p-0">
            <p class="mb-0">Air Condition</p>
          </div>
          <div class="col-2">
            <img
              src="../../../../assets/img/analysis/forward.svg"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12 px-4" (click)="selectAppliance('GEYSER')">
        <div class="row">
          <div class="col-2 pr-0">
            <img
              src="../../../../assets/img/appliance/gyser.svg"
              alt=""
              class="img-fluid"
              style="opacity: 0.5"
            />
          </div>
          <div class="col-8 p-0">
            <p class="mb-0">Geyser</p>
          </div>
          <div class="col-2">
            <img
              src="../../../../assets/img/analysis/forward.svg"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12 px-4" (click)="selectAppliance('WM')">
        <div class="row">
          <div class="col-2 pr-0">
            <img
              src="../../../../assets/img/appliance/wm.svg"
              alt=""
              class="img-fluid"
              style="opacity: 0.5"
            />
          </div>
          <div class="col-8 p-0">
            <p class="mb-0">Washing Machine</p>
          </div>
          <div class="col-2">
            <img
              src="../../../../assets/img/analysis/forward.svg"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12 px-4" (click)="selectAppliance('FRIDGE')">
        <div class="row">
          <div class="col-2 pr-0">
            <img
              src="../../../../assets/img/appliance/fridge.svg"
              alt=""
              class="img-fluid"
              style="opacity: 0.5"
            />
          </div>
          <div class="col-8 p-0">
            <p class="mb-0">Refrigerator</p>
          </div>
          <div class="col-2">
            <img
              src="../../../../assets/img/analysis/forward.svg"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12 px-4" (click)="selectAppliance('OTHERS')">
        <div class="row">
          <div class="col-2 pr-0">
            <img
              src="../../../../assets/img/appliance/induction.svg"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-8 p-0">
            <p class="mb-0">Induction</p>
          </div>
          <div class="col-2">
            <img
              src="../../../../assets/img/analysis/forward.svg"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12 px-4" (click)="selectAppliance('HEATING_DEVICE')">
        <div class="row">
          <div class="col-2 pr-0">
            <img
              src="../../../../assets/img/appliance/oven.svg"
              alt=""
              class="img-fluid"
              style="opacity: 0.5"
            />
          </div>
          <div class="col-8 p-0">
            <p class="mb-0">Microwave Oven</p>
          </div>
          <div class="col-2">
            <img
              src="../../../../assets/img/analysis/forward.svg"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12 px-4" (click)="selectAppliance('HEATING_DEVICE')">
        <div class="row">
          <div class="col-2 pr-0">
            <img
              src="../../../../assets/img/appliance/otg.svg"
              alt=""
              class="img-fluid"
              style="opacity: 0.5"
            />
          </div>
          <div class="col-8 p-0">
            <p class="mb-0">OTG</p>
          </div>
          <div class="col-2">
            <img
              src="../../../../assets/img/analysis/forward.svg"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
