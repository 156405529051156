import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-energy-efficiency",
  templateUrl: "./energy-efficiency.component.html",
  styleUrls: ["./energy-efficiency.component.scss"],
})
export class EnergyEfficiencyComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}

  joinus() {
    this.router.navigate(["energy-efficiency-enroll"]);
  }
}
