import { first } from "rxjs/operators";
import { CommonApiService } from "./../../../service/common-api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
import { Component, OnInit, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";

@Component({
  selector: "app-create-edit-appliance",
  templateUrl: "./create-edit-appliance.component.html",
  styleUrls: ["./create-edit-appliance.component.scss"],
})
export class CreateEditApplianceComponent implements OnInit {
  public clicked: boolean = true;
  public clicked1: boolean = false;
  public clicked2: boolean = false;
  public clicked3: boolean = false;
  public mode: String = "EDIT";
  public currentStep = 0;

  @Input() appliance: any;
  applianceForm: NgForm;
  appliance_id: any;
  f: NgForm;
  constructor(
    public router: Router,
    public api: CommonApiService,
    public route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    if (!Boolean(this.appliance)) {
      this.appliance = this.router.getCurrentNavigation().extras.state;
      // this.appliance_id = this.route.snapshot.params['appliance_id'];
      // this.api.getapplianceById(this.appliance_id).pipe(first()).subscribe(res=> {
      // this.appliance = res;

      // })
      if (!Boolean(this.appliance)) {
        this.appliance_id = this.route.snapshot.params["appliance_id"];

        this.api
          .getapplianceById(this.appliance_id)
          .pipe(first())
          .subscribe((res) => {
            this.appliance = res;
          });
      } else {
        if (!Boolean(this.appliance._id)) {
          // edit mode
          this.mode = "CREATE";
          this.appliance = {};
          this.appliance.applianceName = null;
        }
      }
    }

    this.appliance.profileFilled = () => {
      this.nextStep();
    };

    this.appliance.warrantyFilled = () => {
      this.nextStep();
    };

    this.appliance.serviceFilled = () => {
      this.submitAppliance();
    };
  }

  ngOnInit(): void {}

  nextStep() {
    if (this.mode === "CREATE" && this.currentStep == 0) {
      // create appliance api
      // this.appliance = response
      let create_appliance = {
        applianceBrand: this.appliance.applianceBrand,
        applianceCapacity: this.appliance.applianceCapacity,
        applianceCapacityMeasure: this.appliance.applianceCapacityMeasure,
        applianceDatePurchase: this.appliance.applianceDatePurchase,
        applianceModelNo: this.appliance.applianceModelNo,
        applianceName: this.appliance.applianceName,
        applianceNickname: this.appliance.applianceNickname,
        applianceStar: this.appliance.applianceStar,
        applianceType: this.appliance.applianceType,
        type: this.appliance.type,
        diagnosisThreshold: this.appliance.diagnosisThreshold,
      };

      this.api
        .addAppliance(create_appliance)
        .pipe(first())
        .subscribe((res) => {
          this.appliance = { ...res, ...this.appliance };
          this.currentStep = this.currentStep + 1;
        });
    } else if (this.mode === "EDIT" && this.currentStep == 0) {
      // update appliance api
      // this.appliance = response
      this.api
        .updateAppliance(this.appliance, this.appliance._id)
        .pipe(first())
        .subscribe((res) => {});
    }
    if (this.mode === "CREATE" && this.currentStep == 1) {
      if (!Boolean(this.appliance._id)) {
        console.log("Please fill the appliance details first");

        Swal.fire({
          position: "top-end",
          text: "Please fill the appliance details first",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        console.log("enter to warranty");

        let warrantyObj = {
          purchaseDate: this.appliance.purchaseDate,
          warrantyCoveredBy: this.appliance.warrantyCoveredBy,
          warrantyEndDate: this.appliance.warrantyEndDate,
        };
        // update warranty
        // this.appliance.warranty = response
        this.api
          .updateAppliance(warrantyObj, this.appliance._id)
          .pipe(first())
          .subscribe((res) => {
            this.appliance = { ...res, ...this.appliance };
            // this.router.navigateByUrl(`/view-appliance/${this.appliance._id}`);
            this.currentStep = this.currentStep + 1;
          });
      }
    }
    if (this.mode === "CREATE" && this.currentStep == 2) {
      if (!Boolean(this.appliance._id)) {
        console.log("Please fill the appliance details first");

        Swal.fire({
          position: "top-end",
          text: "Please fill the appliance details first",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        let serviceData = {
          serviceCompany: this.appliance.serviceCompany,
          vendorType: this.appliance.vendorType,
          serviceType: this.appliance.serviceType,
          dateOfService: this.appliance.dateOfService,
          attachmentUrl: this.appliance.attachmentUrl,
          comments: this.appliance.comments,
        };
        this.api
          .addServiceHistory(this.appliance._id, serviceData)
          .pipe(first())
          .subscribe((res) => {
            this.router.navigateByUrl(`/view-appliance/${this.appliance._id}`);
          });
      }
    }

    if (this.mode === "EDIT" && this.currentStep == 1) {
      console.log("enter to warranty Edit");

      let warrantyObj = {
        purchaseDate: this.appliance.purchaseDate,
        warrantyCoveredBy: this.appliance.warrantyCoveredBy,
        warrantyEndDate: this.appliance.warrantyEndDate,
      };
      // update warranty
      // this.appliance.warranty = response
      this.api
        .updateAppliance(warrantyObj, this.appliance._id)
        .pipe(first())
        .subscribe((res) => {
          this.appliance = { ...res, ...this.appliance };
          this.router.navigateByUrl(`/view-appliance/${this.appliance._id}`);
          this.currentStep = this.currentStep + 1;
        });
    } else {
      // this.currentStep = this.currentStep + 1;
    }
  }

  gotoStep(stepNumber) {
    // form valid for current step
    let valid = true;
    if (valid) {
      this.currentStep = stepNumber;
    }
  }

  submitAppliance() {
    if (!Boolean(this.appliance._id)) {
      console.log("Please fill the appliance details first");

      Swal.fire({
        position: "top-end",
        text: "Please fill the appliance details first",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (Boolean(this.appliance._id)) {
      if (this.mode === "CREATE") {
        let serviceData = {
          serviceCompany: this.appliance.serviceCompany,
          vendorType: this.appliance.vendorType,
          serviceType: this.appliance.serviceType,
          dateOfService: this.appliance.dateOfService,
          attachmentUrl: this.appliance.attachmentUrl,
          comments: this.appliance.comments,
        };
        this.api
          .addServiceHistory(this.appliance._id, serviceData)
          .pipe(first())
          .subscribe((res) => {
            // this.appliance = res;
            this.router.navigateByUrl(`/view-appliance/${this.appliance._id}`);
          });
      }
      // use this.appliance
    }
  }
  onSubmit(f: NgForm) {}
}
