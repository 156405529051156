import { Router } from '@angular/router';
import { CommonApiService } from './../../../../service/common-api.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-select-category',
  templateUrl: './select-category.component.html',
  styleUrls: ['./select-category.component.scss'],
})
export class SelectCategoryComponent implements OnInit {
  categories: Array<any> = [
    {
      name: 'Air Conditioner',
      image: '../../../../../assets/img/vyas/appliance/ac.png',
      value: 'AC',
    },
    {
      name: 'Washing Machine',
      image: '../../../../../assets/img/vyas/appliance/wm.png',
      value: 'WM',
    },
    {
      name: 'Fridge',
      image: '../../../../../assets/img/vyas/appliance/fridge.png',
      value: 'FRIDGE',
    },
    {
      name: 'Geyser',
      image: '../../../../../assets/img/vyas/appliance/geyser.png',
      value: 'GEYSER',
    },
    {
      name: 'Heating Device',
      image: '../../../../../assets/img/vyas/appliance/heating.png',
      value: 'HEATING_DEVICE',
    },
    {
      name: 'Water Pumps',
      image: '../../../../../assets/img/vyas/appliance/waterPump.png',
      value: 'WATER_PUMP',
    },
    {
      name: "Miscellaneous",
      image: "../../../../../assets/img/vyas/appliance/other.png",
      value: "OTHERS",
    },
  ];

  ac: Array<any> = [
    {
      name: 'Split',
      image: '../../../../../assets/img/appliance/spiltSC.svg',
      value: 'split',
    },
    {
      name: 'Window',
      image: '../../../../../assets/img/appliance/windowAc.svg',
      value: 'window',
    },
    {
      name: 'Cassette',
      image: '../../../../../assets/img/appliance/cassetteAC.svg',
      value: 'cassette',
    },
    {
      name: 'Floor Standing',
      image: '../../../../../assets/img/appliance/floorAc.svg',
      value: 'floor',
    },
    {
      name: 'VRV',
      image: '../../../../../assets/img/appliance/rvAC.svg',
      value: 'VRV',
    },
  ];

  wm: Array<any> = [
    {
      name: 'Top loader',
      image: '../../../../../assets/img/appliance/TL.svg',
      value: 'top',
    },
    {
      name: 'Front Loader',
      image: '../../../../../assets/img/appliance/wm.svg',
      value: 'front',
    },
    {
      name: 'Semi Automatic',
      image: '../../../../../assets/img/appliance/SA.svg',
      value: 'toploader',
    },
  ];
  geyser: Array<any> = [
    {
      name: 'Instant',
      image: '../../../../../assets/img/appliance/gyser.svg',
      value: 'instant',
    },
    {
      name: 'Storage',
      image: '../../../../../assets/img/appliance/SG.svg',
      value: 'storage',
    },
  ];
  heating_device: Array<any> = [
    {
      name: 'Iron',
      image: '../../../../../assets/img/appliance/iron.svg',
      value: 'iron',
    },
    {
      name: 'Kettle',
      image: '../../../../../assets/img/appliance/kettle.svg',
      value: 'kettle',
    },
    {
      name: 'Microwave',
      image: '../../../../../assets/img/appliance/microwave.svg',
      value: 'microwave',
    },
    {
      name: 'OTG',
      image: '../../../../../assets/img/appliance/otg.svg',
      value: 'otg',
    },
    {
      name: 'Grinder',
      image: '../../../../../assets/img/appliance/grinder.svg',
      value: 'grinder',
    },
    {
      name: 'Toaster',
      image: '../../../../../assets/img/appliance/toasteer.svg',
      value: 'toaster',
    },
  ];
  water_pump: Array<any> = [
    {
      name: 'Immersive',
      image: '../../../../../assets/img/appliance/immersive.svg',
      value: 'immersive',
    },
    {
      name: 'Heat Pumps',
      image: '../../../../../assets/img/appliance/heat.svg',
      value: 'heatPumps',
    },
    {
      name: 'Pressure',
      image: '../../../../../assets/img/appliance/pressure.svg',
      value: 'pressure',
    },
  ];
  odd: any;
  @Input() appliance: any;
  constructor(public api: CommonApiService, public router: Router) {
  }

  ngOnInit(): void {}

  selectCard(category) {
    this.appliance.type = category.name;
    this.appliance.applianceName = category.value;
  }
}
