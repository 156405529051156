import { NgForm } from "@angular/forms";
import { Component, OnInit, Input } from "@angular/core";
import * as moment from "moment";
import { CommonApiService } from "src/app/service/common-api.service";
import { first } from "rxjs/operators";

@Component({
  selector: "app-appliance-warranty",
  templateUrl: "./appliance-warranty.component.html",
  styleUrls: ["./appliance-warranty.component.scss"],
})
export class ApplianceWarrantyComponent implements OnInit {
  public mode: String = "EDIT";
  warrantyCoveredBy: any;
  purchaseDate: any;
  warrantyEndDate: any;
  today: any;
  f: NgForm;
  constructor(private _api: CommonApiService) {}

  @Input() appliance: any;
  ngOnInit(): void {
    this.fileWarranty();
    this.today = new Date().toISOString().split("T")[0];
    if (Boolean(this.appliance._id)) {
      this.mode = "EDIT";
      this.appliance.purchaseDate = moment(this.appliance.purchaseDate).format(
        "YYYY-MM-DD"
      );
      this.appliance.warrantyEndDate = moment(
        this.appliance.warrantyEndDate
      ).format("YYYY-MM-DD");
    }
    if (!Boolean(this.appliance._id)) {
      this.mode = "CREATE";
    }
  }

  show_details() {
    $(".show_details").toggle();
  }

  onSubmit() {
    this.appliance.warrantyFilled();
  }

  fileWarranty() {
    $('.attachment input[type="file"]').on("change", (event) => {
      let el = $(event.target).closest(".attachment").find(".btn-file");

      el.find(".btn-file__actions__item").css({
        padding: "50px",
      });

      el.find(".btn-file__preview").css({
        "background-image":
          "url(" + window.URL.createObjectURL(event.target["files"][0]) + ")",
      });
      if (Boolean(this.appliance._id)) {
        const formData = new FormData();
        formData.append("file", event.target["files"][0]);
        this._api
          .addWarrantyImage(this.appliance._id, formData)
          .pipe(first())
          .subscribe((res) => {
            console.log(res);
          });
      }
    });
  }
}
