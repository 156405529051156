// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: "0.5.1",
  buildId: 24,
  production: false,
  // mqtt_host: "mqtt-dev.ddns.net",
  // baseUrl: "https://ohmassistant-api-dev.ddns.net",
  mqtt_host : "mqtt-server.ddns.net",
  baseUrl : "https://api.sustlabs.com",
  firebaseConfig: {
    apiKey: "AIzaSyDdeS_PO3wtpQBo8w6ouAjRwU5DQqz6CmY",
    authDomain: "ohmassistant-android.firebaseapp.com",
    databaseURL: "https://ohmassistant-android.firebaseio.com",
    projectId: "ohmassistant-android",
    storageBucket: "ohmassistant-android.appspot.com",
    messagingSenderId: "366076925219",
    appId: "1:366076925219:web:dd269ce254d9c72ee6e896",
    measurementId: "G-EEPF2B993Y",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
