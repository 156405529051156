<div class="row" *ngIf="monthWiseHealthHistoryList.length !== 0">
  <div class="col-12 mt-3">
    <p>Previous Weekly Health History</p>

    <div class="row" *ngFor="let data of monthWiseHealthHistoryList; let i = index">
      <div class="col-6">
        <p style="color: #8f8f8f">{{ data.month }}</p>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <p class="health" *ngIf="data.status === 'HEALTHY'">Healthy</p>
        <p class="unhealthy" *ngIf="data.status === 'UNHEALTHY'">unhealthy</p>
        <p class="critical" *ngIf="data.status === 'CRITICAL'">Critical</p>
        <!-- <p class="unknow" *ngIf="data.status === 'UNKNOWN'">Unknown</p> -->
      </div>
    </div>

    <button class="btn w-50 d-block ml-auto mr-auto mt-5 diagnos_btn" (click)="diagnose()">
      Diagnos Now!
    </button>
  </div>
</div>

<!-- <div class="row noHeath" *ngIf="monthWiseHealthHistoryList.length === 0">
  <div class="col-12">
    <img src="../../../../../assets/img/vyas/nohealth.png" alt="No Health" class="img-fluid d-block m-auto" />
    <p class="text-center" style="opacity: 0.8;">Looks like you haven't diagnose this appliance yet. Diagnose now to get
      a detailed health report of your
      appliance</p>
    <button class="btn add_more d-block ml-auto mr-auto fixed-bottom mt-5" data-toggle="modal"
      data-target="#serviceForm" (click)="diagnose()">
      <img src="../../../../../assets/img/vyas/add.png" alt="" class="img-fluid" />
    </button>
  </div>
</div> -->

<div class="row noHeath" *ngIf="monthWiseHealthHistoryList.length == 0">
  <div class="col-12">
    <img src="../../../../../assets/img/vyas/nohealth.png" alt="No heath" class="img-fluid d-block m-auto"
      style="width: 150px; object-fit: contain" />
    <p class="text-center" style="opacity: 0.8;">Looks like you haven't added any servicing details of this appliance.
      Please add past servicing details to help
      us give you better analysis.</p>
    <button class="btn add_more d-block ml-auto mr-auto fixed-bottom mt-5" data-toggle="modal"
      data-target="#serviceForm" (click)="diagnose()">
      <img src="../../../../../assets/img/vyas/add.png" alt="" class="img-fluid" />
    </button>
  </div>
</div>