import { first } from "rxjs/operators";
import { Router, RoutesRecognized, NavigationEnd } from "@angular/router";
import { filter, pairwise } from "rxjs/operators";
import { CommonApiService } from "./../../service/common-api.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-my-bot",
  templateUrl: "./my-bot.component.html",
  styleUrls: ["./my-bot.component.scss"],
})
export class MyBotComponent implements OnInit {
  meters: any;
  previousUrl: any;

  constructor(public _api: CommonApiService, public router: Router) {}

  ngOnInit(): void {
    this.getDetails();
    this.previousUrl = localStorage.getItem("previousUrl");
  }

  getDetails() {
    this._api
      .getUserData()
      .pipe(first())
      .subscribe((res) => {
        this.meters = res["meters"];
      });
  }

  botDetails(meter_id) {
    this.router.navigateByUrl(`/bot-details/${meter_id}`);
  }

  navigation() {
    if (this.previousUrl === "/more") {
      this.router.navigate(["/more"]);
    } else {
      this.router.navigate(["/dashboard"]);
    }
  }
}
