import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import firebase from "firebase/app";

@Injectable({
  providedIn: "root",
})
export class CommonApiService {
  meters: Array<String>;
  meter_id: any = [];
  selected_meter_id: any;
  public confirmationResult: firebase.auth.ConfirmationResult;

  constructor(private http: HttpClient) {}

  @Inject(String) baseUrl: string = environment.baseUrl;
  updateUser: any = this.baseUrl + "/secure/updateUser";
  getUser: any = this.baseUrl + "/secure/getCurrentUser";
  estimatedConsumption: any = this.baseUrl + "/secure/getEstimatedConsumption";
  yesterdayGraph: any = this.baseUrl + "/secure/getYesterdayBreakdown";
  last7days: any = this.baseUrl + "/secure/getLast7DaysBreakdown";
  last30days: any = this.baseUrl + "/secure/getLast30DaysBreakdown";
  // budgetLast7days: any = this.baseUrl + '/secure/getLast7days';
  budgetLast24Hours: any = this.baseUrl + "/secure/getLast24Hours";
  budgetMonths: any = this.baseUrl + "/secure/getLast12months";
  energyStatement: any = this.baseUrl + "/secure/requestStatement";
  requestedPeriodEnergyStatement: any =
    this.baseUrl + "/secure/getEnergyConsumption";
  budgetConsumption: any = this.baseUrl + "/secure/getEstimatedConsumption";

  // analysis
  analysisBreakdown: any = this.baseUrl + "/secure/getBreakDown";
  analysisToday: any = this.baseUrl + "/secure/getDayWiseEnergyConsumption";
  analysisMonth: any = this.baseUrl + "/secure/getEnergyConsumptionMonth";

  // APPLIANCE
  applianceByMeterId: any = this.baseUrl + "/secure/getApplianceByMeterId";
  addApplianceByMeterId: any = this.baseUrl + "/secure/addApplianceByMeterId";
  deleteApplianceByMeterId: any = this.baseUrl + "/secure/deleteApplianceById";
  updateApplianceByMeterId: any = this.baseUrl + "/secure/updateApplianceById";
  applianceById: any = this.baseUrl + "/secure/getApplianceById";

  saveDiagnosis: any = this.baseUrl + "/secure/saveDiagnosisResult";
  getDiagnosis: any = this.baseUrl + "/secure/getMonthwiseDiagnosisHistory";
  // /secure/getApplianceUsageLogs/:meter_id?page=1&count=7
  applianceUsageLogs = this.baseUrl + "/secure/getApplianceUsageLogs";
  // "/secure/verifyApplianceUsage/:question_id?answer=yes&appliance_id=5d31aae29064631e42586331"
  verifyApplianceUsage = this.baseUrl + "/secure/verifyApplianceUsage";
  // /verifyApplianceUsage/:question_id?answer=no
  wrongVerifyApplianceUsage = this.baseUrl + "/secure/verifyApplianceUsage";

  addServiceByApplianceId = this.baseUrl + "/secure/createServiceHistory";
  getServiceHistoryById = this.baseUrl + "/secure/getServiceHistory";

  fetchStaticAppliance = this.baseUrl + "/secure/fetchStaticAppliances";

  getBotInfo = this.baseUrl + "/secure/getBotInfo";

  updateBotInfo = this.baseUrl + "/secure/updateBotInfo";

  utility = this.baseUrl + "/secure/searchUtilityByPincode";

  uptime = this.baseUrl + "/secure/getDaywiseUptime";

  addApplianceMultiple = this.baseUrl + "/secure/addMultipleAppliances";

  mappingMeter = this.baseUrl + "/secure/addMeterToUser";

  getState = this.baseUrl + "/secure/getStateWiseUtility";

  applyForEEP = this.baseUrl + "/secure/applyForEEP";

  uploadWarranty = this.baseUrl + "/secure/uploadWarrantyImage";
  getUploadWarranty = this.baseUrl + "/secure/getWarrantySignedUrl";
  generateTicket = this.baseUrl + "/secure/generateTicket";
  getTicketList = this.baseUrl + "/secure/fetchTickets";

  getSelectedMeterId() {
    if (this.selected_meter_id) {
      return this.selected_meter_id;
    } else {
      localStorage.getItem("selected_meter");
      return localStorage.getItem("selected_meter");
    }
  }

  setSelectedMeterId(meter_id) {
    this.selected_meter_id = meter_id;
    localStorage.setItem("selected_meter", meter_id);
  }

  userUpdate(value) {
    return this.http.put(`${this.updateUser}`, value);
  }

  getUserData() {
    return this.http.get(`${this.getUser}`);
  }

  getStateUtility() {
    return this.http.get(this.getState);
  }

  getEstimatedConsumption(billingCycle) {
    return this.http.get(
      `${
        this.estimatedConsumption
      }/${this.getSelectedMeterId()}?billingCycle=${billingCycle}`
    );
  }

  getYesterdayBreakdown() {
    return this.http.get(`${this.yesterdayGraph}/${this.getSelectedMeterId()}`);
  }
  getLast7DaysBreakdown() {
    return this.http.get(`${this.last7days}/${this.getSelectedMeterId()}`);
  }

  getLast30DaysBreakdown() {
    return this.http.get(`${this.last30days}/${this.getSelectedMeterId()}`);
  }

  getAnalysisToday(startTime, endTime) {
    return this.http.get(
      `${
        this.analysisToday
      }/${this.getSelectedMeterId()}?startTime=${startTime}&endTime=${endTime}`
    );
  }
  getAnalysisMonth(startTime, endTime) {
    return this.http.get(
      `${
        this.analysisMonth
      }/${this.getSelectedMeterId()}?startTime=${startTime}&endTime=${endTime}`
    );
  }

  applianceUsageVerify() {
    return this.http.get(
      `${this.verifyApplianceUsage}/${this.getSelectedMeterId()}`
    );
  }

  getBudgetLast24Hours() {
    return this.http.get(
      `${this.budgetLast24Hours}/${this.getSelectedMeterId()}`
    );
  }
  getBudgetMonths() {
    return this.http.get(`${this.budgetMonths}/${this.getSelectedMeterId()}`);
  }
  getEnergyStatement(to, from, upto) {
    return this.http.get(
      `${
        this.energyStatement
      }/${this.getSelectedMeterId()}?to=${to}&from=${from}&upto=${upto}`
    );
  }
  getBudgetConsumption() {
    return this.http.get(
      `${this.budgetConsumption}/${this.getSelectedMeterId()}`
    );
  }
  getSelectedTimeIntervalEnergyStatement(startTime, endTime) {
    return this.http.get(
      `${
        this.requestedPeriodEnergyStatement
      }/${this.getSelectedMeterId()}?startTime=(${startTime})&endTime=(${endTime})`
    );
  }

  // analysis
  getAnalysis(start, end) {
    return this.http.get(
      `${
        this.analysisBreakdown
      }/${this.getSelectedMeterId()}?start=${start}&end=${end}`
    );
  }

  // APPLIANCE
  getAppliance() {
    return this.http.get(
      `${this.applianceByMeterId}/${this.getSelectedMeterId()}`
    );
  }
  addAppliance(value) {
    return this.http.post(
      `${this.addApplianceByMeterId}/${this.getSelectedMeterId()}`,
      value
    );
  }
  deleteAppliance(id) {
    return this.http.delete(
      `${this.deleteApplianceByMeterId}/${this.getSelectedMeterId()}/${id}`
    );
  }

  getapplianceById(id) {
    return this.http.get(
      `${this.applianceById}/${this.getSelectedMeterId()}/${id}`
    );
  }
  updateAppliance(value, id) {
    return this.http.put(
      `${this.updateApplianceByMeterId}/${this.getSelectedMeterId()}/${id}`,
      value
    );
  }

  diagnosisDataSave(id, value) {
    return this.http.post(
      `${this.saveDiagnosis}/${this.getSelectedMeterId()}/${id}`,
      value
    );
  }
  diagnosisDataGet(id) {
    return this.http.get(
      `${this.getDiagnosis}/${this.getSelectedMeterId()}/${id}`
    );
  }

  getApplianceUsageLogs(page, count) {
    return this.http.get(
      `${
        this.applianceUsageLogs
      }/${this.getSelectedMeterId()}?page=${page}&count=${count}`
    );
  }

  addServiceHistory(id, value) {
    return this.http.post(
      `${this.addServiceByApplianceId}/${this.getSelectedMeterId()}/${id}`,
      value
    );
  }

  getServiceHistory(id) {
    return this.http.get(
      `${this.getServiceHistoryById}/${this.getSelectedMeterId()}/${id}`
    );
  }
  verifyApplianceAnalysis(question_id, answer, appliance_id) {
    return this.http.get(
      `${
        this.verifyApplianceUsage
      }/${this.getSelectedMeterId()}/${question_id}?answer=${answer}&appliance_id=${appliance_id}`
    );
  }

  verifyApplianceUsageWrong(question_id, answer) {
    return this.http.get(
      `${
        this.wrongVerifyApplianceUsage
      }/${this.getSelectedMeterId()}/${question_id}?answer=${answer}`
    );
  }

  staticApplianceFetch(search, applianceName) {
    return this.http.get(
      `${this.fetchStaticAppliance}?search=${search}&applianceName=${applianceName}`
    );
  }

  botInfoGet(meter_id) {
    return this.http.get(`${this.getBotInfo}/${meter_id}`);
  }

  botDetailsUpdate(meter_id, value) {
    return this.http.put(`${this.updateBotInfo}/${meter_id}`, value);
  }

  getUtility(pincode) {
    return this.http.get(`${this.utility}?search=${pincode}`);
  }

  getUpTime(startTime, endTime) {
    return this.http.get(
      `${
        this.uptime
      }/${this.getSelectedMeterId()}?startTime=${startTime}&endTime=${endTime}`
    );
  }
  addMultipleAppliance(value) {
    return this.http.post(
      `${this.addApplianceMultiple}/${this.getSelectedMeterId()}`,
      value
    );
  }

  meterMapping(meterId, value) {
    return this.http.put(`${this.mappingMeter}/${meterId}`, value);
  }

  getSelectedAppliance(applianceName) {
    return this.http.get(
      `${
        this.applianceByMeterId
      }/${this.getSelectedMeterId()}?applianceName=${applianceName}`
    );
  }

  addForEEP(value) {
    return this.http.post(
      `${this.applyForEEP}/${this.getSelectedMeterId()}`,
      value
    );
  }
  addWarrantyImage(appliance_id, value) {
    return this.http.post(
      `${this.uploadWarranty}/${this.getSelectedMeterId()}/${appliance_id}`,
      value
    );
  }

  getWarrantyImage(appliance_id) {
    return this.http.get(
      `${this.getUploadWarranty}/${this.getSelectedMeterId()}/${appliance_id}`
    );
  }

  createTicket(value) {
    return this.http.post(this.generateTicket, value);
  }

  getTicket() {
    return this.http.get(this.getTicketList);
  }
}
