<div class="row" *ngIf="serviceData.length != 0">
  <div class="col-12">
    <div
      class="accordion"
      [attr.id]="'accordion_' + i"
      *ngFor="let service of serviceData; let i = index"
    >
      <div class="card">
        <div class="card-body">
          <div class="card-head" id="headingTwo">
            <h2
              class="mb-0 collapsed font-weight-bold indent"
              data-toggle="collapse"
              [attr.data-parent]="'#accordion_' + i"
              [attr.href]="'#collapseOne_' + i"
            >
            {{ service.dateOfService | date: "mediumDate" }}
            </h2>
          </div>
          <div
            [attr.id]="'collapseOne_' + i"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div class="card-body p-0">
              <div class="row mt-4 mt-md-5 details">
                <div class="col-6">
                  <p class="details_1">Service Company</p>
                </div>
                <div class="col-6">
                  <p class="text-center details_2">Voltas</p>
                </div>
              </div>

              <hr />
              <div class="row details">
                <div class="col-6">
                  <p class="details_1">Repair categoty</p>
                </div>
                <div class="col-6">
                  <p class="text-center details_2">
                    {{ service.serviceType }}
                  </p>
                </div>
              </div>
              <hr />
              <div class="row details">
                <div class="col-6">
                  <p class="details_1">Vendor</p>
                </div>
                <div class="col-6">
                  <p class="text-center details_2">
                    {{ service.vendorType }}
                  </p>
                </div>
              </div>

              <hr />
              <div class="row details">
                <div class="col-6">
                  <p class="details_1">Month of service</p>
                </div>
                <div class="col-6">
                  <p class="text-center details_2">
                    {{ service.dateOfService | date: "mediumDate" }}
                  </p>
                </div>
              </div>

              <hr />

              <div class="row details">
                <div class="col-6">
                  <p class="details_1">Year of service</p>
                </div>
                <div class="col-6">
                  <p class="text-center details_2">2020</p>
                </div>
              </div>
              <hr />
              <div class="row details">
                <div class="col-6">
                  <p class="details_1">Comments</p>
                </div>
                <div class="col-6">
                  <p class="text-center details_2">{{ service.comments }}</p>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-5">
    <!-- <button
      class="btn add_more d-block ml-auto mr-auto"
      data-toggle="modal"
      data-target="#serviceForm"
      (click)="addService()"
    >
      Add More
    </button> -->
    <button
      class="btn add_more d-block ml-auto mr-auto fixed-bottom mt-5"
      data-toggle="modal"
      data-target="#serviceForm"
      (click)="addService()"
    >
      <img
        src="../../../../../assets/img/vyas/add.png"
        alt=""
        class="img-fluid"
      />
    </button>
  </div>
</div>

<div class="row noservice" *ngIf="serviceData.length == 0">
  <div class="col-12">
    <img
      src="../../../../../assets/img/vyas/noService.png"
      alt="noService"
      class="img-fluid d-block m-auto"
      style="width: 150px; object-fit: contain"
    />
    <p class="text-center" style="opacity: 0.8">
      Looks like you haven't added any servicing details of this appliance.
      Please add past servicing details to help us give you better analysis.
    </p>
    <button
      class="btn add_more d-block ml-auto mr-auto fixed-bottom mt-5"
      data-toggle="modal"
      data-target="#serviceForm"
      (click)="addService()"
    >
      <img
        src="../../../../../assets/img/vyas/add.png"
        alt=""
        class="img-fluid"
      />
    </button>
  </div>
</div>
