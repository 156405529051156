import { CommonApiService } from './../../../../service/common-api.service';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-appliance-health-details',
  templateUrl: './appliance-health-details.component.html',
  styleUrls: ['./appliance-health-details.component.scss'],
})
export class ApplianceHealthDetailsComponent implements OnInit {
  @Input() appliance: any;
  appliance_id: any;
  data: any;
  diagnosis: any = [];
  monthWiseHealthHistoryList: any = [];
  loader: boolean = false;
  status: any;
  constructor(
    public _api: CommonApiService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loader = true;
    this.appliance_id = this.route.snapshot.params['appliance_id'];
    this.getDiagnosisData();
  }

  getDiagnosisData() {
    this.loader = true;
    this._api
      .diagnosisDataGet(this.appliance_id)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.data = res;
        let monthWiseHealthHistory = {};
        _.forEach(this.data, (element) => {
          if (monthWiseHealthHistory[element.month]) {
            monthWiseHealthHistory[element.month] = [
              ...monthWiseHealthHistory[element.month],
              ...element.diagnosis,
            ];
          } else {
            monthWiseHealthHistory[element.month] = [];
            monthWiseHealthHistory[element.month] = [
              ...monthWiseHealthHistory[element.month],
              ...element.diagnosis,
            ];
          }
        });
        _.forEach(monthWiseHealthHistory, (val: any, key) => {

          val = _.sortBy(val, 'created');
          this.monthWiseHealthHistoryList.push({
            month: key,
            diagnosis: val,
            status: val[val.length - 1]['status'],
          });
        });
      });
  }

  diagnose() {
    this.router.navigateByUrl(`/diagnose/${this.appliance_id}`);
  }
}
