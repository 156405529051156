import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bot-details',
  templateUrl: './bot-details.component.html',
  styleUrls: ['./bot-details.component.scss'],
})
export class BotDetailsComponent implements OnInit {
  meter_id: any;
  constructor(public _router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.meter_id = this.route.snapshot.params['meter_id'];
  }
  botDetails() {
    
    this._router.navigateByUrl(`/bot-info-details/${this.meter_id}`);
  }
  homeDetails() {
    this._router.navigateByUrl(`/bot-home-details/${this.meter_id}`);
  }
  utilityDetails() {
    this._router.navigateByUrl(`/bot-utility-details/${this.meter_id}`);
  }
}
