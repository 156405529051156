import { Component, OnInit, Input } from "@angular/core";
import { NgForm } from "@angular/forms";
import { first } from "rxjs/operators";
import { CommonApiService } from "src/app/service/common-api.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-energy-efficiency-form",
  templateUrl: "./energy-efficiency-form.component.html",
  styleUrls: ["./energy-efficiency-form.component.scss"],
})
export class EnergyEfficiencyFormComponent implements OnInit {
  participant1Name: any;
  participant1Mobile: any;
  participant2Name: any;
  participant2Mobile: any;
  utilityProvider: any;
  consumerId: any;
  pincode: any;
  emailId: any;
  ngForm: NgForm;
  @Input() details: any;
  constructor(private _api: CommonApiService) {}

  ngOnInit(): void {}
  onSubmit(f: NgForm) {
    if (f.valid) {
      let formData = {
        participant1Name: this.participant1Name,
        participant1Mobile: this.participant1Mobile,
        participant2Name: this.participant2Name,
        participant2Mobile: this.participant2Mobile,
        utilityProvider: this.utilityProvider,
        consumerId: this.consumerId,
        pincode: this.pincode,
        emailId: this.emailId,
      };

      this._api
        .addForEEP(formData)
        .pipe(first())
        .subscribe((res) => {
          Swal.fire({
            text: "Successfully enrolled",
            icon: "success",
            confirmButtonColor: "#E50021",
          });
          f.reset();
        });
    }
  }
}
