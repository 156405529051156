import { UtilityComponent } from "./components/Login-pages/utility/utility.component";
import { BotProfileComponent } from "./components/Login-pages/bot-profile/bot-profile.component";
import { EnergyEfficiencyFormComponent } from "./components/programs/energy-efficiency/energy-efficiency-form/energy-efficiency-form.component";
import { EnergyEfficiencyComponent } from "./components/programs/energy-efficiency/energy-efficiency.component";
import { ProductTrainingComponent } from "./components/programs/product-training/product-training.component";
import { ProgramsComponent } from "./components/programs/programs.component";
import { DetailedBudgetComponent } from "./components/budget/detailed-budget/detailed-budget.component";
import { DetailedAnalysisComponent } from "./components/analysis/detailed-analysis/detailed-analysis.component";
import { VyasOnboardingComponent } from "./components/vyas/vyas-onboarding/vyas-onboarding.component";
import { ApplianceServiceComponent } from "./components/vyas/create-edit-appliance/appliance-service/appliance-service.component";
import { CreateEditApplianceComponent } from "./components/vyas/create-edit-appliance/create-edit-appliance.component";
import { ApplianceDetailsComponent } from "./components/vyas/appliance-details/appliance-details.component";
import { EditProfileComponent } from "./components/user-profile/edit-profile/edit-profile.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { HomeDetailsEditComponent } from "./components/my-bot/bot-edit/home-details-edit/home-details-edit.component";
import { BotUtilityEditComponent } from "./components/my-bot/bot-edit/bot-utility-edit/bot-utility-edit.component";
import { BotInfoEditComponent } from "./components/my-bot/bot-edit/bot-info-edit/bot-info-edit.component";
import { UtilityDetailsComponent } from "./components/my-bot/bot-details/utility-details/utility-details.component";
import { HomeDetailsComponent } from "./components/my-bot/bot-details/home-details/home-details.component";
import { BotInfoDetailsComponent } from "./components/my-bot/bot-details/bot-info-details/bot-info-details.component";
import { BotDetailsComponent } from "./components/my-bot/bot-details/bot-details.component";
import { MyBotComponent } from "./components/my-bot/my-bot.component";
import { VyasComponent } from "./components/vyas/vyas.component";
import { MoreComponent } from "./components/more/more.component";
import { BudgetComponent } from "./components/budget/budget.component";
import { AnalysisComponent } from "./components/analysis/analysis.component";
import { AllDoneComponent } from "./components/Login-pages/all-done/all-done.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { UnregisterComponent } from "./components/Login-pages/unregister/unregister.component";
import { ProfileComponent } from "./components/Login-pages/profile/profile.component";
import { OtpComponent } from "./components/Login-pages/otp/otp.component";
import { LoginComponent } from "./components/Login-pages/login/login.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DiagnosisComponent } from "./components/diagnosis/diagnosis.component";
import { DiagnosisInitateComponent } from "./components/diagnosis/diagnosis-initate/diagnosis-initate.component";
import { ApplianceTaggedComponent } from "./components/analysis/appliance-tagged/appliance-tagged.component";
import { WrongApplianceTaggedComponent } from "./components/analysis/wrong-appliance-tagged/wrong-appliance-tagged.component";
import { TrainYourBotComponent } from "./components/train-your-bot/train-your-bot.component";
import { SelectedApplianceListComponent } from "./components/train-your-bot/selected-appliance-list/selected-appliance-list.component";
import { ApplianceTrainingComponent } from "./components/train-your-bot/appliance-training/appliance-training.component";
import { DataCollectionComponent } from "./components/train-your-bot/data-collection/data-collection.component";
import { SupportComponent } from "./components/support/support.component";
import { TrackIssueComponent } from "./components/support/track-issue/track-issue.component";
import { CreateTicketComponent } from "./components/support/create-ticket/create-ticket.component";
import { FileDownloadComponent } from "./components/file-download/file-download.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "otp", component: OtpComponent },
  { path: "profile", component: ProfileComponent },
  { path: "unregister", component: UnregisterComponent },
  { path: "bot-profile/:meter_id", component: BotProfileComponent },
  { path: "utility/:meter_id", component: UtilityComponent },
  { path: "all-done", component: AllDoneComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "analysis", component: AnalysisComponent },
  { path: "detailed-analysis", component: DetailedAnalysisComponent },
  {
    path: "select-appliance/:question_id/:applianceName",
    component: ApplianceTaggedComponent,
  },
  {
    path: "select-appliance/wrong_detection/:question_id/:applianceName",
    component: ApplianceTaggedComponent,
  },
  {
    path: "all-select-appliance/:question_id",
    component: WrongApplianceTaggedComponent,
  },
  { path: "budget", component: BudgetComponent },
  { path: "detailed-budget", component: DetailedBudgetComponent },
  { path: "more", component: MoreComponent },
  { path: "user-profile", component: UserProfileComponent },
  { path: "edit-profile", component: EditProfileComponent },
  { path: "my-bot", component: MyBotComponent },
  { path: "bot-details/:meter_id", component: BotDetailsComponent },
  { path: "bot-info-details/:meter_id", component: BotInfoDetailsComponent },
  { path: "bot-home-details/:meter_id", component: HomeDetailsComponent },
  { path: "bot-utility-details/:meter_id", component: UtilityDetailsComponent },
  {
    path: "bot-home-details-edit/:meter_id",
    component: HomeDetailsEditComponent,
  },
  { path: "bot-info-edit/:meter_id", component: BotInfoEditComponent },
  { path: "bot-utility-edit/:meter_id", component: BotUtilityEditComponent },
  { path: "vyas", component: VyasComponent },
  {
    path: "view-appliance/:appliance_id",
    component: ApplianceDetailsComponent,
  },
  { path: "create-appliance", component: CreateEditApplianceComponent },
  {
    path: "edit-appliance/:appliance_id",
    component: CreateEditApplianceComponent,
  },
  {
    path: "add-service/:appliance_id",
    component: ApplianceServiceComponent,
  },
  { path: "vyas-onboarding", component: VyasOnboardingComponent },

  { path: "diagnose/:appliance_id", component: DiagnosisComponent },
  {
    path: "diagnose-initate/:appliance_id/:applianceName/:stabilityAverage",
    component: DiagnosisInitateComponent,
  },
  {
    path: "programs",
    component: ProgramsComponent,
  },
  {
    path: "product-training",
    component: ProductTrainingComponent,
  },
  {
    path: "energy-efficiency",
    component: EnergyEfficiencyComponent,
  },
  {
    path: "energy-efficiency-enroll",
    component: EnergyEfficiencyFormComponent,
  },
  { path: "train-your-bot", component: TrainYourBotComponent },
  {
    path: "train-your-bot/:applianceName",
    component: SelectedApplianceListComponent,
  },
  {
    path: "appliance-training/:appliance_id",
    component: ApplianceTrainingComponent,
  },
  {
    path: "data-collection/:appliance_id",
    component: DataCollectionComponent,
  },
  {
    path: "support",
    component: SupportComponent,
  },
  {
    path: "track-issue",
    component: TrackIssueComponent,
  },
  {
    path: "create-ticket",
    component: CreateTicketComponent,
  },
  {
    path: "file-download",
    component: FileDownloadComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
