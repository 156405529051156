import { CommonApiService } from "./../../../service/common-api.service";
import { Component, OnInit } from "@angular/core";
import { first } from "rxjs/operators";

@Component({
  selector: "app-detailed-budget",
  templateUrl: "./detailed-budget.component.html",
  styleUrls: ["./detailed-budget.component.scss"],
})
export class DetailedBudgetComponent implements OnInit {
  public clicked: boolean = true;
  public clicked1: boolean = false;
  loader: boolean = false;
  constructor(public _api: CommonApiService) {}

  ngOnInit(): void {
    this.loader = true;
    this.budgetCalendar();
  }

  budgetCalendar() {
    this._api
      .getBudgetConsumption()
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
      });
  }
}
