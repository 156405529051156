<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent d-flex justify-content-between align-items-center">
          <div class="col-6 p-0 d-flex align-items-center">
            <a routerLink="/my-bot">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get">
              Home Details
            </a>
          </div>
          <div class="form-inline my-2 my-lg-0 mr-2">
            <a class="title_get" (click)="homeEdit()" style="cursor: pointer"
              >EDIT</a
            >
          </div>
        </nav>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row mt-5">
      <div class="col-12 mt-5 px-4">
        <p class="font-weight-bold">Home Details</p>
        <div class="row">
          <div class="col-6">Address</div>
          <div class="col-6 d-flex justify-content-end text-justify">
            <p class="text__details" *ngIf="home_details">
              {{ home_details.address }}
            </p>
            <p class="text__details" *ngIf="!home_details">---</p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">City</div>
          <div class="col-6 d-flex justify-content-end">
            <p class="text__details" *ngIf="home_details">
              {{ home_details.city }}
            </p>
            <p class="text__details" *ngIf="!home_details">---</p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">Pincode</div>
          <div class="col-6 d-flex justify-content-end">
            <p class="text__details" *ngIf="home_details">
              {{ home_details.pincode }}
            </p>
            <p class="text__details" *ngIf="!home_details">---</p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">Home Type</div>
          <div class="col-6 d-flex justify-content-end">
            <p class="text__details" *ngIf="home_details">
              {{ home_details.houseType }}
            </p>
            <p class="text__details" *ngIf="!home_details">---</p>
          </div>
        </div>
        <!-- <div class="row mt-3">
          <div class="col-6">WiFi</div>
          <div class="col-6 d-flex justify-content-end" *ngIf="home_details">
            <p class="text__details">{{ home_details.ssid }}</p>
            <p class="text__details" *ngIf="!home_details">---</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</section>
