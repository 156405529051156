<app-loader *ngIf="loader"></app-loader>
<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <app-navbar></app-navbar>
      </div>
    </div>

    <div style="padding-top: 50px">
      <div
        class="row top__row"
        *ngFor="let items of appliancesWithCount"
        (click)="selectAppliance(items.applianceName)"
      >
        <div class="col-2">
          <img
            src="../../../assets/img/vyas/appliance/ac.png"
            alt="ac"
            class="img-fluid device_img"
            *ngIf="items.applianceName == 'AC'"
          />
          <img
            src="../../../assets/img/vyas/appliance/geyser.png"
            alt="ac"
            class="img-fluid device_img"
            *ngIf="items.applianceName == 'GEYSER'"
          />
          <img
            src="../../../assets/img/vyas/appliance/wm.png"
            alt="ac"
            class="img-fluid device_img"
            *ngIf="items.applianceName == 'WM'"
          />
          <img
            src="../../../assets/img/vyas/appliance/heating.png"
            alt="ac"
            class="img-fluid device_img"
            *ngIf="items.applianceName == 'HEATING_DEVICE'"
          />
          <img
            src="../../../assets/img/vyas/appliance/fridge.png"
            alt="ac"
            class="img-fluid device_img"
            *ngIf="items.applianceName == 'FRIDGE'"
          />
          <img
            src="../../../assets/img/vyas/appliance/waterPump.png"
            alt="ac"
            class="img-fluid device_img"
            *ngIf="items.applianceName == 'WATER_PUMP'"
          />
          <img
            src="../../../assets/img/vyas/appliance/other.png"
            alt="ac"
            class="img-fluid device_img"
            *ngIf="items.applianceName == 'OTHERS'"
          />
        </div>
        <div class="col-8">
          <p class="mb-0">{{ items.applianceName }} ({{ items.count }})</p>
        </div>
        <div class="col-2">
          <img
            src="../../../../assets/img/analysis/forward.svg"
            alt=""
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</section>
