import { CommonApiService } from "./../../../../service/common-api.service";
import { first } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, Input } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-appliance-service-details",
  templateUrl: "./appliance-service-details.component.html",
  styleUrls: ["./appliance-service-details.component.scss"],
})
export class ApplianceServiceDetailsComponent implements OnInit {
  vendorType: any;
  serviceType: any;
  dateOfService: any;
  attachmentUrl: any;
  comments: any;
  url: string | ArrayBuffer;

  @Input() appliance: any;
  serviceData: any = [];
  appliance_id: any;
  displayMonth: any;
  constructor(
    public route: ActivatedRoute,
    public _api: CommonApiService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.appliance_id = this.route.snapshot.params["appliance_id"];
    this._api
      .getServiceHistory(this.appliance_id)
      .pipe(first())
      .subscribe((res) => {
        this.serviceData = res;
        this.displayMonth = moment(this.serviceData.dateOfService).format(
          "MMM YYYY"
        );
      });
  }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
    }
  }

  addService() {
    this.router.navigateByUrl(`/add-service/${this.appliance._id}`);
  }
}
