import { first } from "rxjs/operators";
import { CommonApiService } from "./../../../../service/common-api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, Input } from "@angular/core";
import * as moment from "moment";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-appliance-service",
  templateUrl: "./appliance-service.component.html",
  styleUrls: ["./appliance-service.component.scss"],
})
export class ApplianceServiceComponent implements OnInit {
  url: string | ArrayBuffer;
  appliance_id: any;
  vendorType: any;
  serviceType: any;
  dateOfService: any;
  comments: any;
  serviceCompany: any;
  appliancePurchaseDate: any;

  constructor(
    private route: ActivatedRoute,
    public api: CommonApiService,
    public router: Router
  ) {}
  public mode: String = "EDIT";
  @Input() appliance: any;
  today: any;
  ngOnInit(): void {
    this.appliance_id = this.route.snapshot.params["appliance_id"];
    if (!Boolean(this.appliance_id)) {
      this.mode = "CREATE";
      this.today = new Date().toISOString().split("T")[0];
      // this.createApplianceDetails();
      // this.serviceAdd();
    }
    if (Boolean(this.appliance_id)) {
      this.mode = "EDIT";
      this.today = new Date().toISOString().split("T")[0];
      this.getApplianceDetails();
    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
    }
  }
  show_details() {
    $(".show_details").toggle();
  }

  serviceAdd() {
    let serviceData = {
      serviceCompany: this.serviceCompany,
      vendorType: this.vendorType,
      serviceType: this.serviceType,
      dateOfService: this.dateOfService,
      // attachmentUrl: this.attachmentUrl,
      comments: this.comments,
    };
    this.api
      .addServiceHistory(this.appliance_id, serviceData)
      .pipe(first())
      .subscribe((res) => {
        // this.appliance = res;
        this.router.navigateByUrl(`/view-appliance/${this.appliance_id}`);
      });
  }

  submit() {
    this.appliance.serviceFilled();
  }

  getApplianceDetails() {
    this.api
      .getapplianceById(this.appliance_id)
      .pipe(first())
      .subscribe((res) => {
        this.appliancePurchaseDate = moment(res["purchaseDate"]).format(
          "YYYY-MM-DD"
        );
      });
  }

  // createApplianceDetails() {
  //   this.api
  //     .updateAppliance(this.appliance._id)
  //     .pipe(first())
  //     .subscribe((res) => {
  //       this.appliancePurchaseDate = moment(res['purchaseDate']).format(
  //         'YYYY-MM-DD'
  //       );
  //     });
  // }
  onSubmit(f: NgForm) {
    if (f.valid) {
      this.appliance.profileFilled();
    }
  }
}
