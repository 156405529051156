import { first } from "rxjs/operators";
import { CommonApiService } from "./../../../service/common-api.service";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { Chart, registerables } from "chart.js";

@Component({
  selector: "app-dashboard-graph",
  templateUrl: "./dashboard-graph.component.html",
  styleUrls: ["./dashboard-graph.component.scss"],
})
export class DashboardGraphComponent implements OnInit {
  myChart: any;
  constructor(private _api: CommonApiService) {}

  ngOnInit(): void {
    this.getLast24Hours();
  }
  renderGraph(labels, data) {
    if (this.myChart != undefined) {
      this.myChart.destroy();
    }
    // var ctx = document.getElementById('myChart');
    var canvas: any = document.getElementById("myDashboardChart");
    var ctx = canvas.getContext("2d");
    Chart.register(...registerables);
    this.myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            fill: true,
            backgroundColor: "rgba(187, 0, 27, 0.3)",
            borderColor: "rgba(187, 0, 27, 1)",
            borderDashOffset: 0.0,
            borderWidth: 1,
            pointBorderColor: "rgba(187, 0, 27, 0.3)",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 3,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(187, 0, 27, 0.3)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 4,
            pointRadius: 1,
            pointHitRadius: 10,
          },
        ],
      },

      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: 0.5,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            beginAtZero: true,
          },
        },
      },
    });
  }

  getLast24Hours() {
    this._api
      .getBudgetLast24Hours()
      .pipe(first())
      .subscribe((res: Array<any>) => {
        let labels = [];
        let data = [];
        res.forEach((element) => {
          data.push(element["total_energy"].toFixed(2));
          labels.push(element["hour"].substring(8, 10));
        });
        this.renderGraph(labels, data);
      });
    var endDate = moment().startOf("day").toISOString(); //the current time
    var startDate = moment().subtract(1, "day").startOf("day").toISOString();
  }

  ngOnDestroy() {
    if (this.myChart != undefined) {
      this.myChart.destroy();
    }
  }
}
