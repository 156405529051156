<div class="row">
  <div class="col-12 d-flex justify-content-between">
    <p class="font-weight-bold mb-0">Are you covered under warranty</p>
    <div class="custom-control custom-switch">
      <input
        type="checkbox"
        class="custom-control-input"
        id="customSwitch1"
        checked
        (click)="show_details()"
      />
      <label class="custom-control-label" for="customSwitch1"></label>
    </div>
  </div>
</div>

<div class="show_details row mt-3">
  <!-- <div class="col-12">
    <p>Upload Warranty Card</p>
    <input type="file" placeholder="select img" />
  </div> -->
  <div class="col-md-12">
    <div class="form-group">
      <label for="fileField" class="attachment">
        <div class="row btn-file">
          <div class="btn-file__preview"></div>
          <div class="btn-file__actions">
            <div class="btn-file__actions__item col-xs-12 text-center">
              <div class="btn-file__actions__item--shadow">
                <i class="fa fa-plus fa-lg fa-fw" aria-hidden="true"></i>
                <div class="visible-xs-block"></div>
                <small> Upload <br />Warranty Card</small>
              </div>
            </div>
          </div>
        </div>
        <input
          name="file"
          type="file"
          id="fileField"
          accept="image/jpg, image/jpeg, image/png"
          multiple
        />
      </label>
    </div>
  </div>
  <div class="col-12 mt-3">
    <div class="form-group">
      <label for="nickName" *ngIf="mode == 'EDIT'">Agency Name</label>
      <input
        type="text"
        class="form-control"
        placeholder="Agency name"
        [(ngModel)]="appliance.warrantyCoveredBy"
        name="warrantyCoveredBy"
      />
    </div>
  </div>
  <div class="col-12 mt-3">
    <div class="form-group">
      <label for="nickName" *ngIf="mode == 'EDIT'">Purchase Date</label>
      <input
        type="text"
        onfocus="(this.type='date')"
        class="form-control"
        placeholder="Start Date"
        [(ngModel)]="appliance.purchaseDate"
        name="purchaseDate"
        max="{{ today || !today }}"
      />
    </div>
  </div>
  <div class="col-12 mt-3">
    <div class="form-group">
      <label for="nickName" *ngIf="mode == 'EDIT'">End Date</label>
      <input
        type="text"
        onfocus="(this.type='date')"
        class="form-control"
        placeholder="End Date"
        [(ngModel)]="appliance.warrantyEndDate"
        name="warrantyEndDate"
        min="{{ appliance.purchaseDate || !appliance.purchaseDate }}"
      />
    </div>
  </div>
</div>
<div class="row mt-3 submit_row">
  <div class="col-12">
    <button class="btn next d-block m-auto w-50" (click)="onSubmit()">
      Submit
    </button>
  </div>
</div>
