<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-6 p-0 d-flex align-items-center">
            <a (click)="navigation()" style="cursor: pointer;">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get"> My Bot </a>
          </div>
          <div class="form-inline my-2 my-lg-0">
            <!-- <img src="../../../assets/img/menu.png" alt="" /> -->
          </div>
        </nav>
      </div>
    </div>
    <div class="row mt-5 pb-5">
      <div class="col-12 mt-4">
        <div
          class="bot__list"
          *ngFor="let meter of meters; let i = index"
          (click)="botDetails(meter)"
        >
          <div class="bot_details">
            <p>Bot {{ i + 1 }}</p>
            <a style="cursor: pointer"><i class="fa fa-chevron-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
