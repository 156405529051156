<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav
          class="navbar navbar-expand-lg navbar-light transparent flex-column"
        >
          <div class="col-12 p-0 d-flex align-items-center">
            <a (click)="navPrev()">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get"
              >Training Mode</a
            >
          </div>
          <div class="col-12 p-0">
            <div class="row ml-5 align-items-center">
              <div class="col-12 pl-1">
                <small
                  *ngIf="appliance"
                  class="text-light mb-0"
                  style="font-size: 11px"
                >
                  Let's train your {{ appliance.applianceName }}
                </small>
                <!-- <img
                  src="../../../../assets/img/appliance/spiltAC.svg"
                  alt=""
                  class="img-fluid ml-2"
                  style="filter: invert(100%)"
                /> -->
              </div>
              <div class="col-12 pl-1">
                <small
                  class="text-light"
                  *ngIf="appliance"
                  style="font-size: 11px"
                  >Modal no. {{ appliance.applianceModelNo }}
                </small>
              </div>
            </div>
          </div>
          <div class="form-inline my-2 my-lg-0"></div>
        </nav>
      </div>
    </div>
  </div>
  <div class="container-fluid" id="gif__container" style="margin-top: 150px">
    <div class="row">
      <div class="col-12">
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner mb-5" style="height: 550px">
            <div class="carousel-item active">
              <img
                src="../../../../assets/img/trainYourBot/Gif 1.gif"
                alt=""
                class="img-fluid d-block ml-auto mr-auto mt-5 mb-5"
              />
              <p class="text-center">
                Leave only essential appliances ON, If they are being used.
              </p>
              <p class="text-center">
                Essential appliances eg: Lights, Fans & Wifi
              </p>
            </div>
            <div class="carousel-item">
              <img
                src="../../../../assets/img/trainYourBot/gif-2.gif"
                alt=""
                class="img-fluid d-block ml-auto mr-auto mt-5 mb-5"
              />
              <p class="text-center mt-5">Note:</p>
              <p class="text-center">
                To avoid mistraining, turn OFF all heavy appliances. This covers
                the appliances you'd like to train.
              </p>
              <p class="text-center">Eg: Geysers, ovens, air conditioners</p>
            </div>
            <div class="carousel-item">
              <img
                src="../../../../assets/img/trainYourBot/Comp-1_2.gif"
                alt=""
                class="img-fluid d-block ml-auto mr-auto mt-5 mb-5"
              />

              <p class="text-center">
                Confirm below to start the stability check
              </p>

              <div
                class="custom-control custom-checkbox d-flex flex-column align-items-center"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck1"
                  (click)="showButton()"
                  name="customCheck1"
                />
                <label
                  class="custom-control-label font-weight-bold"
                  for="customCheck1"
                  >I have turned OFF, all heavy appliances</label
                >
              </div>

              <button
                id="train__btn"
                class="mt-4 mb-3 btn stability__btn"
                (click)="intitiateStability()"
              >
                Check stability
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid top__row" id="stability__card">
    <div class="row">
      <div class="col-12">
        <div class="card stability__card">
          <div class="card-body d-flex align-items-center">
            <img
              src="../../../../assets/img/trainYourBot/stability.svg"
              alt=""
              class="img-fluid mr-2"
            />
            Check Stability
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-3" id="graph__container">
    <div class="row">
      <div class="col-12">
        <canvas id="trainChart"></canvas>
      </div>
    </div>
    <div class="row mt-5" id="progress__container">
      <div class="col-12 mt-3">
        <h4>Training Overview</h4>
      </div>
      <div class="col-12">
        <div class="progress mt-2">
          <div
            id="dynamic"
            class="progress-bar progress-bar-success progress-bar-striped active"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: 25%"
          >
            <span id="current-progress"></span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <p style="font-size: 14px; font-weight: bold; text-align: center">
          {{ percentCompleted }} % completed
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 mt-5 d-flex justify-content-end">
        <button class="btn stop__btn" (click)="stopTraining()">
          Stop training
        </button>
      </div>
    </div>
  </div>

  <!-- <button (click)="intitiateStability()"></button> -->
</section>

<!-- Stop Training Modal -->

<div
  class="modal fade modal-bottom pb-5"
  id="stopTrainingModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="stopTrainingModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div
      class="modal-content pb-5"
      style="border-top-left-radius: 10px; border-top-right-radius: 10px"
    >
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p class="mt-3 text-center">Are you sure?</p>
            </div>
          </div>
          <div class="row mt-5">
            <div
              class="col-md-12 d-flex align-items-center"
              style="justify-content: space-evenly"
            >
              <button class="btn common__btn" (click)="stopTrainingNo()">
                No
              </button>
              <button class="btn common__btn" (click)="stopTrainingYes()">
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Training start modal -->

<div
  class="modal fade modal-bottom pb-5"
  id="startApplianceModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="startApplianceModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div
      class="modal-content pb-5"
      style="border-top-left-radius: 10px; border-top-right-radius: 10px"
    >
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <img
                src="../../../../assets/img/trainYourBot/success.png"
                alt=""
                class="img-fluid d-block m-auto"
              />
              <h4 class="font-weight-bold text-center">Woahh !!</h4>
              <p class="text-center">
                100% Stability has been achieved Please, Turn on your appliance.
              </p>

              <div
                class="custom-control custom-checkbox d-flex flex-column align-items-center"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck2"
                  (click)="showNextButton()"
                />
                <label class="custom-control-label" for="customCheck2"
                  >I have turned OFF, all heavy appliances</label
                >

                <button
                  id="train__next__btn"
                  class="btn common__btn mt-3"
                  (click)="startAppliance()"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Trainigg Failed -->
<div
  class="modal fade modal-bottom pb-5"
  id="trainingFailedModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="trainingFailedModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div
      class="modal-content pb-5"
      style="border-top-left-radius: 10px; border-top-right-radius: 10px"
    >
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 d-flex flex-column align-items-center">
              <p>{{ trainingFailedMessage }}</p>

              <button
                id="train__next__btn"
                class="btn common__btn mt-3"
                (click)="trainingFailedOk()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
