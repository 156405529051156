<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-12 p-0 d-flex align-items-center">
            <a routerLink="/more">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get"
              >How Can we help?</a
            >
          </div>
        </nav>
      </div>
    </div>
    <div class="row top__row px-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-2">
                <img
                  src="../../../assets/img/support/community.svg"
                  alt=""
                  class="img-fluid d-block m-auto"
                />
              </div>
              <div class="col-10 p-0">
                <p class="mb-0">Ask the community</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-3">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-2">
                <img
                  src="../../../assets/img/more/FAQs.svg"
                  alt=""
                  class="img-fluid d-block m-auto"
                />
              </div>
              <div class="col-10 p-0">
                <p class="mb-0">FAQs</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-12 mt-3"
        routerLink="/track-issue"
        style="cursor: pointer"
      >
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-2">
                <img
                  src="../../../assets/img/support/issue.svg"
                  alt=""
                  class="img-fluid d-block m-auto"
                />
              </div>
              <div class="col-10 p-0">
                <p class="mb-0">Track issues</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-12 mt-3"
        routerLink="/create-ticket"
        style="cursor: pointer"
      >
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-2">
                <img
                  src="../../../assets/img/support/ticket.svg"
                  alt=""
                  class="img-fluid d-block m-auto"
                />
              </div>
              <div class="col-10 p-0">
                <p class="mb-0">Generate Ticket</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
