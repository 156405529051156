<section>
  <nav class="navbar navbar-expand-lg navbar-light transparent">
    <div class="col-6 p-0 d-flex align-items-center">
      <a routerLink="/vyas">
        <img src="../../../../assets/img/arrow-left.png" alt=""
      /></a>
      <a
        class="mb-0 pl-md-3 pr-md-3 d-lg-inline-block title_get"
        *ngIf="!appliance.applianceName"
        >Add Device</a
      >
      <a
        class="mb-0 pl-md-3 pr-md-3 d-lg-inline-block title_get"
        *ngIf="appliance.applianceName == 'AC'"
        >Air Conditioner</a
      >
      <a
        class="mb-0 pl-md-3 pr-md-3 d-lg-inline-block title_get"
        *ngIf="appliance.applianceName == 'WM'"
        >Washing Machine</a
      >
      <a
        class="mb-0 pl-md-3 pr-md-3 d-lg-inline-block title_get"
        *ngIf="appliance.applianceName == 'GEYSER'"
        >Geyser</a
      >
      <a
        class="mb-0 pl-md-3 pr-md-3 d-lg-inline-block title_get"
        *ngIf="appliance.applianceName == 'FRIDGE'"
        >Refrigerator</a
      >
      <a
        class="mb-0 pl-md-3 pr-md-3 d-lg-inline-block title_get"
        *ngIf="appliance.applianceName == 'OTHERS'"
        >Miscellaneous</a
      >
      <a
        class="mb-0 pl-md-3 pr-md-3 d-lg-inline-block title_get"
        *ngIf="appliance.applianceName == 'WATER_PUMP'"
        >Water pump</a
      >
      <a
        class="mb-0 pl-md-3 pr-md-3 d-lg-inline-block title_get"
        *ngIf="appliance.applianceName == 'HEATING_DEVICE'"
        >Heating Device</a
      >
    </div>
  </nav>
  <div *ngIf="mode == 'CREATE' && appliance.applianceName == null" class="mt-5">
    <app-select-category [(appliance)]="appliance"></app-select-category>
  </div>

  <div class="container-fluid mt-5" *ngIf="appliance.applianceName != null">
    <div class="row top__row">
      <div class="col-12">
        <div class="mt-3 d-flex" style="justify-content: space-around">
          <button
            class="btn top_btn"
            [ngClass]="{ active: currentStep == 0 }"
            data-toggle="tab"
            (click)="gotoStep(0)"
          >
            Profile
          </button>
          <button
            class="btn top_btn second_btn"
            [ngClass]="{ active: currentStep == 1 }"
            data-toggle="tab"
            (click)="gotoStep(1)"
          >
            Warranty
          </button>
          <button
            class="btn top_btn"
            [ngClass]="{ active: currentStep == 2 }"
            *ngIf="mode !== 'EDIT'"
            data-toggle="tab"
            (click)="gotoStep(2)"
          >
            Services
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <!-- <div class="card">
          <div class="card-body"> -->
        <div *ngIf="currentStep == 0">
          <app-appliance-profile
            [(appliance)]="appliance"
          ></app-appliance-profile>
        </div>

        <div *ngIf="currentStep == 1">
          <app-appliance-warranty
            [(appliance)]="appliance"
          ></app-appliance-warranty>
        </div>

        <div *ngIf="currentStep == 2">
          <app-appliance-service
            [(appliance)]="appliance"
          ></app-appliance-service>
        </div>
        <!-- </div>
        </div> -->
      </div>
    </div>
  </div>
</section>
