import { CommonApiService } from './../../../service/common-api.service';
import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  form_profile: NgForm;
  name: any;
  email: any;
  user: any;

  constructor(public _api: CommonApiService, public _router: Router) {}

  ngOnInit(): void {
    this.user = localStorage.getItem('user');
  }

  profile() {
    if (this.user) {
      let obj = {
        displayName: this.name,
      };
      this._api
        .userUpdate(obj)
        .pipe(first())
        .subscribe((res) => {
          localStorage.setItem('user', JSON.stringify(res));
          // this._router.navigate(['/dashboard']);
          this._router.navigate(['/all-done']);
        });
    }
  }
}
