import { first } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonApiService } from "./../../../../service/common-api.service";
import { Component, OnInit } from "@angular/core";
declare var $: any;

@Component({
  selector: "app-bot-utility-edit",
  templateUrl: "./bot-utility-edit.component.html",
  styleUrls: ["./bot-utility-edit.component.scss"],
})
export class BotUtilityEditComponent implements OnInit {
  loader: boolean = false;
  utilityName: any;
  consumerId: any;
  utilityByPincode: any;
  utility: any;
  meter_id: any;

  allStates: any;
  selectedUtility: any;
  selectedUtilityName: any;
  selectedState: any;
  selectedStateName: any;

  boardName: any;
  billingDate: Number = 1;
  state: any;
  slabs: any;
  boardNameSelected: any;
  boardSelected: any;

  constructor(
    public _api: CommonApiService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.meter_id = this.route.snapshot.params["meter_id"];
    // this.getBotUtility();
    this.getBotInfo();
    this.stateUtility();
  }

  stateUtility() {
    this._api
      .getStateUtility()
      .pipe(first())
      .subscribe((res) => {
        this.allStates = res;

        this.selectedState = this.allStates[0];
        this.selectedStateName = this.selectedState["stateName"];

        this.selectedUtility = this.selectedState["utilityBoards"][0];
        this.selectedUtilityName = this.selectedUtility["boardName"];
      });
  }

  stateSelect(e) {
    console.log(e.target.value);
    this.selectedStateName = e.target.value;
    this.allStates.forEach((item) => {
      if (item.stateName == this.selectedStateName) {
        this.selectedState = item;

        this.selectedUtility = this.selectedState["utilityBoards"][0];
        this.selectedUtilityName = this.selectedUtility["boardName"];

        console.log(item);
      }
    });
  }

  boardSelect(e) {
    this.selectedUtilityName = e.target.value;
    this.selectedState["utilityBoards"].forEach((item) => {
      if (this.selectedUtilityName == item["boardName"]) {
        this.selectedUtility = item;
      }
    });
  }
  dateModal() {
    console.log("modal");
    $("#dateModal").modal().show();
  }

  dateSelected(date) {
    console.log(date);
    this.billingDate = date;
    $("#dateModal").modal("hide");
    $(".modal-backdrop").remove();
  }

  // getBotUtility() {
  //   this._api
  //     .getUtility(400)
  //     .pipe(first())
  //     .subscribe((res) => {
  //       this.utilityByPincode = res;
  //     });
  // }

  getBotInfo() {
    this.loader = true;
    this._api
      .botInfoGet(this.meter_id)
      .pipe(first())
      .subscribe((res) => {
        console.log(res);

        this.loader = false;
        this.utility = res["utilityBoardInfo"];
        this.selectedStateName = res["state"];

        this.allStates.forEach((item) => {
          if (item.stateName == this.selectedStateName) {
            this.selectedState = item;
            this.selectedUtility = this.selectedState["utilityBoards"][0];
          }
        });

        this.billingDate = res["billingDate"];
        this.selectedUtility = res["utilityBoardInfo"];
        this.selectedUtilityName = this.selectedUtility["boardName"];
      });
  }

  onUtilityChange(ev) {}

  saveDetails() {
    this.loader = true;
    // let formdata = {
    //   utility: {
    //     consumerId: this.utility.consumerId,
    //     utilityName: this.utility.utilityName,
    //   },
    // };

    let info = {
      state: this.selectedStateName,
      utilityBoardInfo: this.selectedUtility,
      billingDate: this.billingDate,
      consumerId: this.consumerId,
    };

    this._api
      .botDetailsUpdate(this.meter_id, info)
      .pipe(first())
      .subscribe((res) => {
        console.log(res);

        this.loader = false;
        this.router.navigateByUrl(`/bot-utility-details/${this.meter_id}`);
      });
  }
}
