import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { indexOf } from 'lodash';
import { first } from 'rxjs/operators';
import { CommonApiService } from 'src/app/service/common-api.service';

@Component({
  selector: 'app-train-your-bot',
  templateUrl: './train-your-bot.component.html',
  styleUrls: ['./train-your-bot.component.scss'],
})
export class TrainYourBotComponent implements OnInit {
  loader: boolean = false;
  results: any = [];
  appliancesWithCount = [];
  constructor(public _api: CommonApiService, public router: Router) {}

  ngOnInit(): void {
    this.loader = true;
    this.getAppliance();
  }

  getAppliance() {
    this._api
      .getAppliance()
      .pipe(first())
      .subscribe((res:any) => {
        this.loader = false;
        res.forEach(item => {
          
          if(this.results.indexOf(item.applianceName) === -1){
            this.results.push(item.applianceName)
            this.appliancesWithCount.push({ 
              applianceName : item.applianceName,
              count : 1
            })
          } else {
            _.find(this.appliancesWithCount, { applianceName : item.applianceName})["count"]++;
          }
        })
        console.log(this.appliancesWithCount)
      });
  }
  selectAppliance(items) {
    this.router.navigateByUrl(`/train-your-bot/${items}`);
  }
}
