import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonApiService } from './../../../service/common-api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  loader: boolean = false;
  displayName: any;
  email: any;
  profile: any;
  bot: any;
  emailcheck: boolean = false;
  constructor(public api: CommonApiService, public router: Router) {}

  ngOnInit(): void {
    this.loader = true;
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.api
      .getUserData()
      .pipe(first())
      .subscribe((res) => {
        this.profile = res;

        this.bot = res['meters'];
        this.loader = false;
      });
  }

  saveProfile() {
    this.loader = true;
    let formdata = {
      displayName: this.profile.displayName,
      email: this.profile.email,
    };

    var email_reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var emailinput = $('#emailId').val();

    if (email_reg.test(emailinput.toString()) == true) {
      this.api
        .userUpdate(formdata)
        .pipe(first())
        .subscribe((res) => {
          this.router.navigate(['/user-profile']);
          this.loader = false;
        });
    } else {
      this.loader = false;
      this.emailcheck = true;
    }
  }
}
