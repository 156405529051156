import { Component, OnInit, Input } from "@angular/core";
declare var $: any;
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { first } from "rxjs/operators";

import * as moment from "moment";
import * as _ from "lodash";
import { CommonApiService } from "src/app/service/common-api.service";
import { MqttService } from "src/app/service/mqtt.service";

@Component({
  selector: "app-diagnosis",
  templateUrl: "./diagnosis.component.html",
  styleUrls: ["./diagnosis.component.scss"],
})
export class DiagnosisComponent implements OnInit {
  meterId: any;

  mqttService: any;
  liveActivePower: any;
  liveArray = [];
  liveTime: any = [];
  liveChart: any;
  diagnoseAppliance: any;
  MIN_VALUES = 4;
  MAX_STD_DEV = 0.5;
  stdDev = 0;
  data = [];
  appliance_id: any;
  appliance: any;
  loader: boolean = false;

  saveDiagnose: boolean = false;

  stabilityAverage: any = 0;
  previousUrl: any;

  constructor(
    public router: Router,
    public api: CommonApiService,
    private route: ActivatedRoute
  ) {}

  stanDeviate = function (chunk) {
    var i,
      j,
      total = 0,
      mean = 0,
      diffSqredArr = [];
    for (i = 0; i < chunk.length; i += 1) {
      total += chunk[i];
    }
    mean = total / chunk.length;
    for (j = 0; j < chunk.length; j += 1) {
      diffSqredArr.push(Math.pow(chunk[j] - mean, 2));
    }
    return Math.sqrt(
      diffSqredArr.reduce(function (firstEl, nextEl) {
        return firstEl + nextEl;
      }) / chunk.length
    );
  };

  ngOnInit(): void {
    this.loader = true;
    $(".diagnose_btn").hide();
    this.meterId = localStorage.getItem("selected_meter");
    this.appliance_id = this.route.snapshot.params["appliance_id"];
    this.api
      .getapplianceById(this.appliance_id)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.appliance = res;
      });
    this.previousUrl = localStorage.getItem("previousUrl");
  }
  navigation() {
    if (this.previousUrl === "/more") {
      this.router.navigate(["/more"]);
    } else {
      this.router.navigate(["/vyas"]);
    }
  }
  calculateSTDDEV(arr: Array<Number>) {
    if (arr.length < 5) {
      return Infinity;
    }
    let section = arr.slice(arr.length - 5);
    let stddev = this.stanDeviate(section);

    if (stddev > 5) {
      $("#exampleModal").modal().show();
    }
    {
      // stop
      $("#exampleModal").modal("hide");
      // $(".modal-backdrop").remove();
      this.mqttService = null;
      $("#initateModal").modal("show");
      // $(".modal-backdrop").add();

      // this.mqttService.close();
    }
  }

  startLiveData() {
    // close old connection if any
    // start new mqtt subscription

    if (this.mqttService) {
      this.mqttService.close();
      this.mqttService = null;
    }

    this.mqttService = new MqttService(environment.mqtt_host, [this.meterId]);

    this.mqttService.listen().subscribe((data) => {
      try {
        this.data.push(data);

        this.liveActivePower = data.a.toFixed(2);

        // this.liveTime.push(data.t)

        this.liveTime.push(moment(data.t).format("LT"));

        if (this.liveArray.length < 20) {
          this.liveArray.push(parseInt(data.a.toFixed(2)));
        }

        this.calculateSTDDEV(this.liveArray);

        if (this.liveArray) {
          this.stabilityAverage = _.meanBy(this.liveArray);
        }
      } catch (e) {}

      // const average = _.meanBy(this.liveArray, (a) => a.a);
      // console.log("DATA FROM SD", average);
    });
  }

  saveDiagnoseData() {}

  showButton() {
    $(".diagnose_btn").toggle();
  }

  checkStability() {
    $("#exampleModal").modal("show");
    this.startLiveData();
  }

  abort() {
    $("#exampleModal").modal("hide");
    this.liveChart.stop(true);
    this.mqttService.close();
    this.mqttService = null;
  }

  initiate() {
    this.mqttService = null;
    $(".modal-backdrop").remove();
    this.router.navigateByUrl(
      `/diagnose-initate/${this.appliance_id}/${this.appliance["applianceName"]}/${this.stabilityAverage}`
    );
  }
}
