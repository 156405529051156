import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-all-done',
  templateUrl: './all-done.component.html',
  styleUrls: ['./all-done.component.scss'],
})
export class AllDoneComponent implements OnInit {
  user: any;
  constructor(public _router: Router) {}

  ngOnInit(): void {
    this.user = localStorage.getItem('user');
    if (this.user) {
      setTimeout(() => {
        this._router.navigate(['/dashboard']);
      }, 1000);
    }
  }
}
