import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import { CommonApiService } from "./../../../../service/common-api.service";
import { NgForm } from "@angular/forms";
import { Component, OnInit, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import * as $ from "jquery";
import * as moment from "moment";
@Component({
  selector: "app-appliance-profile",
  templateUrl: "./appliance-profile.component.html",
  styleUrls: ["./appliance-profile.component.scss"],
})
export class ApplianceProfileComponent implements OnInit {
  applianceName: "AC";
  applianceNickname: any;
  applianceBrand: any;
  applianceStar: any;
  applianceCapacity: any;
  applianceCapacityMeasure: any;
  applianceModelNo: any;
  applianceType = "";
  diagnosisThreshold: any;
  submitcheck: boolean = false;
  applianceDatePurchase: any;
  items: any;
  updateItems: any;
  starValue: any;
  type = [];
  modal_show: boolean = true;
  rating = [
    { name: 1, value: 1 },
    { name: 1.5, value: 1.5 },
    { name: 2, value: 2 },
    { name: 2.5, value: 2.5 },
    { name: 3, value: 3 },
    { name: 3.5, value: 3.5 },
    { name: 4, value: 4 },
    { name: 4.5, value: 4.5 },
    { name: 5, value: 5 },
  ];
  public mode: String = "EDIT";
  ngForm: NgForm;
  today: any;
  search: any;
  searchResult: any;
  constructor(
    public api: CommonApiService,
    public router: Router,
    private toastr: ToastrService
  ) {}

  @Input() appliance: any;
  ngOnInit(): void {
    // $("modal-fullscreen-xl").removeClass("fade");
    let nowY = new Date().getFullYear(),
      options = "";

    for (var Y = nowY; Y >= 1980; Y--) {
      options += "<option>" + Y + "</option>";
    }

    $("#years").append(options);

    if (Boolean(this.appliance._id)) {
      this.mode = "EDIT";
      this.appliance.applianceDatePurchase = moment(
        this.appliance.applianceDatePurchase
      ).format("YYYY");
    }
    if (!Boolean(this.appliance._id)) {
      this.mode = "CREATE";
    }
    if (this.appliance.applianceName == "AC") {
      this.type = [
        { name: "Spilt AC", value: "AC_SPLIT" },
        { name: "Window AC", value: "AC_WINDOW" },
      ];

      this.appliance.applianceCapacityMeasure = "TON";
    }
    if (this.appliance.applianceName == "WM") {
      this.type = [
        { name: "Frontload", value: "WM_FRONTLOAD" },
        { name: "Topload", value: "WM_TOPLOAD" },
      ];

      this.appliance.applianceCapacityMeasure = "KG";
    }
    if (this.appliance.applianceName == "GEYSER") {
      this.type = [
        { name: "Instant", value: "GEYSER_INSTANT" },
        { name: "Storage", value: "GEYSER_STORAGE" },
      ];

      this.appliance.applianceCapacityMeasure = "LITRE";
    }
    if (this.appliance.applianceName == "FRIDGE") {
      this.type = [
        { name: "Frost Free", value: "FROST_FREE" },
        { name: "Direct Cool", value: "DIRECT_COOL" },
      ];

      this.appliance.applianceCapacityMeasure = "LITRE";
    }
    if (this.appliance.applianceName == "HEATING_DEVICE") {
      this.type = [
        { name: "Iron", value: "IRON" },
        { name: "Kettle", value: "KETTLE" },
        { name: "Toaster", value: "TOASTER" },
        { name: "Oven", value: "OVEN" },
        { name: "Grinder", value: "GRINDER" },
        { name: "OTG", value: "OTG" },
      ];

      this.appliance.applianceCapacityMeasure = "WATTS";
    }
    if (this.appliance.applianceName == "WATER_PUMP") {
      this.type = [
        { name: "Immersive", value: "IMMERSIVE" },
        { name: "Heat", value: "HEAT" },
        { name: "Pressure", value: "PRESSURE" },
      ];

      this.appliance.applianceCapacityMeasure = "HP";
    }
    if (this.appliance.applianceName == "OTHERS") {
      this.type = [
        { value: "DISH_WASHER", name: "Dish Washer" },
        { value: "WATER_PURIFYER", name: "Water Purifyer" },
        { value: "AIR_PURIFYER", name: "Air Purifyer" },
        { value: "TELEVISION", name: "Televsion" },
        { value: "LAPTOP", name: "Laptop" },
        { value: "HEAT_CONVENTION", name: "Heat convention" },
        { value: "EXHAUST_FAN", name: "Exhaust Fan" },
        { value: "FAN", name: "Fan" },
        { value: "LIGHT", name: "Light" },
        { value: "FAN", name: "Fan" },
        { value: "ROUTER", name: "Router" },
        { value: "VACCUM_CLEANER", name: "Vacuum cleaner" },
        { value: "INDUCTION", name: "Induction" },
      ];

      this.appliance.applianceCapacityMeasure = "UNIT";
    }

    this.appliance.onSubmit = (f: NgForm) => {};
  }

  applianceFetch(event: any) {
    this.search = event.target.value;

    if (this.search.length > 0) {
      this.api
        .staticApplianceFetch(this.search, this.appliance.applianceName)
        .pipe(first())
        .subscribe((res) => {
          this.searchResult = res;
        });
    } else {
      this.searchResult = null;
    }
  }

  searchAppliance(search_appliance) {
    this.appliance.applianceName = search_appliance.applianceName;
    this.appliance.applianceBrand = search_appliance.applianceBrand;
    this.appliance.applianceModelNo = search_appliance.applianceModelNo;
    this.appliance.applianceCapacity = search_appliance.applianceCapacity;
    this.appliance.applianceStar = search_appliance.applianceStar;
    this.appliance.applianceType = search_appliance.applianceType;
    // this.appliance.diagnosisThreshold = search_appliance.diagnosisThreshold

    // $('#modal-fullscreen-xl').modal('hide');
    this.modal_show = false;
  }

  modal_number(event) {
    this.appliance.applianceModelNo = this.search;
    // $('#modal-fullscreen-xl').modal('hide');
    this.modal_show = false;
  }

  ngAfterViewInit() {}

  onSubmit(f: NgForm) {
    if (f.valid) {
      this.appliance.profileFilled();
    }
  }
}
