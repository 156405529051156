<section>
    <div class="container-fluid">
        <div class="row">
            <nav class="navbar navbar-expand-lg navbar-light transparent">
                <div class="col-6 p-0 d-flex align-items-center">
                    <a routerLink="/vyas">
                        <img src="../../../../assets/img/arrow-left.png" alt="" /></a>
                    <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get">Diagnosis</a>
                </div>
            </nav>
        </div>
    </div>
    <div class="container mt-5" id="detalis_container">
        <div class="row">
            <div class="col-12 mt-3">
                <div class="card">
                    <div class="card-body">
                        <h5 class="text-danger" *ngIf="appliance">
                            {{ appliance.applianceNickname }}
                        </h5>
                        <p *ngIf="appliance">MODEL: {{ appliance.applianceModelNo }}</p>
                        <p class="text-warning" *ngIf="!appliance">LOADING......</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- GRAPH -->
    <div class="container" id="graph_container">
        <div class="row mt-3">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <p class="text-center" style="font-size: 14px;">
                            Consumption (in Watts)
                        </p>
                        <canvas id="myChart"></canvas>

                        <button class="btn w-50 mt-5 abort_btn" *ngIf="showSaveButton" (click)="saveDiagnoseData()">
                            SAVE DIAGNOSE
                        </button>
                        <button class="btn w-50 mt-5 abort_btn" *ngIf="redoButton" (click)="redo()">
                            Redo Diagnosis
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-bottom-navbar></app-bottom-navbar>