<div *ngFor="let item of sequence">
    <h6 class="header_title" *ngIf="applianceMap">{{ applianceMap[item].readableName }}</h6>
    <div class="container">
        <div class="row">
            <div class="col-3 mt-3" *ngFor="let i of applianceMap[item] | keyvalue">
                <img [src]="applianceMap[item][i.key].imageUrl" alt="" class="img-fluid appliance__image" (click)="toggleAppliance(item, i.key)" *ngIf="i.value.selected === false" />
                <div *ngIf="i.value.selected === true" class="appliance__image__false border-danger">
                    <img src="../../../../../assets/img/vyas/greenRight.png" alt="" class="img-fluid img-1">
                    <img [src]="applianceMap[item][i.key].imageUrl" alt="" class="img-fluid img-2" (click)="toggleAppliance(item, i.key)" />
                </div>
                <br />

                <p class="appliance__text" *ngIf="applianceMap">
                    {{ applianceMap[item][i.key].readableName }}
                </p>
            </div>
        </div>
    </div>
</div>