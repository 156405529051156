import { CommonApiService } from './../../../service/common-api.service';
import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as $ from 'jquery';
import * as intlTelInput from 'intl-tel-input';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavigationExtras, Router } from '@angular/router';
import firebase from 'firebase/app';
import 'firebase/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  phoneNumber: any;
  phoneNumberString: any;
  countryCode: any;
  form_verify: NgForm | undefined;
  iti: any;
  cc: any = [];
  public recaptchaVerifier: any;
  user: firebase.User;
  userSubscription: any;
  confirmationResultOtp: any;

  constructor(
    private httpClient: HttpClient,
    public afAuth: AngularFireAuth,
    public _router: Router,
    private _api: CommonApiService
  ) {}

  ngAfterViewInit() {
    var input = document.querySelector('#phoneNumber');
    if (input != null) {
      this.iti = intlTelInput(input, {
        // any initialisation options go here
        initialCountry: 'IN',
      });
    }
  }
  ngOnInit(): void {
    this.httpClient.get('assets/json/country.json').subscribe((data) => {
      this.cc = data;
    });

    if (localStorage.getItem('user')) {
      this._router.navigate(['/dashboard']);
    } else {
      // this.hideUnregisteredMessage()
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: function (response: any) {
            // reCAPTCHA solved - will proceed with submit function
          },
          'expired-callback': function () {
            // Reset reCAPTCHA?
          },
        }
      );
      this.recaptchaVerifier.render();

      this.userSubscription = this.afAuth.authState.subscribe((user) => {

        if (user) {
          localStorage.setItem('firebaseUser', JSON.stringify(user));
          this.user = user;
          this.userSubscription.unsubscribe();
        }
      });
    }
  }

  async getOtp() {
    const appVerifier = this.recaptchaVerifier;

    this.phoneNumberString =
      '+' + this.iti.selectedCountryData.dialCode + this.phoneNumber;

    try {
      let result = await this.afAuth.signInWithPhoneNumber(
        this.phoneNumberString,
        appVerifier
      );
      this.confirmationResultOtp = result;

      this._api.confirmationResult = result;

      this._router.navigate(['otp'], {
        queryParams: { mobile: this.phoneNumberString },
      });
      // this.resendText();
    } catch (error) {
    }
  }
}
