import { first } from "rxjs/operators";
import { CommonApiService } from "./../../../service/common-api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";

@Component({
  selector: "app-appliance-details",
  templateUrl: "./appliance-details.component.html",
  styleUrls: ["./appliance-details.component.scss"],
})
export class ApplianceDetailsComponent implements OnInit {
  appliance: any;
  serviceData: any;
  appliance_id: any;
  loader: boolean = false;
  constructor(
    public router: Router,
    public _api: CommonApiService,
    private route: ActivatedRoute
  ) {
    // this.appliance = this.router.getCurrentNavigation().extras.state;
  }
  public currentStep = 0;
  ngOnInit(): void {
    this.loader = true;
    this.appliance_id = this.route.snapshot.params["appliance_id"];
    this._api
      .getapplianceById(this.appliance_id)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.appliance = res;
      });

    this._api
      .getServiceHistory(this.appliance_id)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
      });

    this._api
      .diagnosisDataGet(this.appliance_id)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
      });
  }

  gotoStep(stepNumber) {
    // form valid for current step
    let valid = true;
    if (valid) {
      this.currentStep = stepNumber;
    }
  }
  deleteAppliance(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this._api
          .deleteAppliance(id)
          .pipe(first())
          .subscribe((res) => {
            this.router.navigate(['vyas']);
            // Swal.fire(
            //   "Deleted!",
            //   "Your Appliance has been deleted.",
            //   "success"
            // );
          });
      }
    });
  }

}
