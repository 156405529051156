<section>
  <div class="container-fluid">
    <div class="row">
      <nav class="navbar navbar-expand-lg navbar-light transparent">
        <div class="col-12 p-0 d-flex align-items-center">
          <a (click)="navigation()" style="cursor: pointer">
            <img
              src="../../../assets/img/arrow-left.png"
              alt=""
              class="img-fluid d-block m-auto"
          /></a>
          <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get"> Diagnosis</a>
        </div>
      </nav>
    </div>
  </div>
  <div class="container mt-5" id="detalis_container">
    <div class="row mt-5">
      <div class="col-12 mt-3">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-3 pr-0">
                <img
                  src="../../../assets/img/appliance/spiltAC.svg"
                  alt=""
                  class="img-fluid appliance__img"
                  *ngIf="appliance.applianceName === 'AC'"
                />
                <img
                  src="../../../assets/img/appliance/gyser.svg"
                  alt=""
                  class="img-fluid appliance__img"
                  *ngIf="appliance.applianceName === 'GEYSER'"
                />
              </div>
              <div class="col-9 pl-0">
                <h5 class="text-dark" *ngIf="appliance">
                  {{ appliance.applianceNickname }}
                </h5>
                <p class="mb-0" *ngIf="appliance" style="color: #aaaaaa">
                  MODEL: {{ appliance.applianceModelNo || "----" }}
                </p>
                <p class="text-warning" *ngIf="!appliance">LOADING......</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <div class="card details_card">
          <div class="card-body">
            <p class="font-weight-bold" style="color: #aaaaaa">
              ENSURE THE FOLLOWING BEFORE DIAGNOSIS
            </p>
            <div class="d-flex">
              <span class="mr-2">1.</span>
              <p>
                Leave only essential appliances ON, if they are being used.
                <br />For eg: WiFi, Fans , Lights and TV.
              </p>
            </div>
            <div class="d-flex">
              <span class="mr-2">2.</span>
              <p>
                Turn OFF, all heavy appliances to prevent erroneous results This
                includes the appliance that you wish to diagnose. <br />For eg:
                Air Conditioners, Refrigerators, Geysers, Ovens, Toasters,
                Kettle, Washing Machine, Hair Dryer , Iron etc.
              </p>
            </div>

            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customCheck1"
                (click)="showButton()"
              />
              <label class="custom-control-label" for="customCheck1"
                >I confirm that I have turned of all heavy appliances</label
              >
            </div>

            <button
              class="btn diagnose_btn mt-5 mt-md-5"
              (click)="checkStability()"
            >
              Diagnose
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div
  class="modal fade modal-bottom"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  data-keyboard="false"
  data-backdrop="static"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <p class="text-center" style="color: #80868b">
          Should be ready in a minute.
        </p>
        <img
          src="../../../assets/img/vyas/Diagnoses.gif"
          alt=""
          class="img-fluid d-block m-auto w-50"
        />
        <!-- <div class="row cf">
                    <div class="three col">
                        <div class="loader" id="loader-1"></div>
                    </div>
                </div> -->
        <button type="button" class="btn abort_btn w-50" (click)="abort()">
          ABORT
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade modal-bottom"
  id="initateModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="initateModalLabel"
  aria-hidden="true"
  data-keyboard="false"
  data-backdrop="static"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <p class="mb-0 text-center">We appreciate your patience</p>
        <p class="text-center mb-0" style="color: #80868b">
          You may now TURN ON the appliance !
        </p>
        <!-- <img
          src="../../../assets/img/vyas/Diagnoses.gif"
          alt=""
          class="img-fluid d-block m-auto w-50"
        /> -->
        <button
          type="button"
          class="btn abort_btn w-50 mt-5"
          (click)="initiate()"
        >
          Initiate
        </button>
      </div>
    </div>
  </div>
</div>
