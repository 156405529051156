import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vyas-onboarding-page-one',
  templateUrl: './vyas-onboarding-page-one.component.html',
  styleUrls: ['./vyas-onboarding-page-one.component.scss'],
})
export class VyasOnboardingPageOneComponent implements OnInit {
  applianceMap: any;
  sequence = []
  constructor(private http: HttpClient) {}

  @Input() selectApplianceTypes;
  ngOnInit(): void {
    this.http
      .get('../../../../assets/json/vyasApplianceList.json')
      .subscribe((applianceMap) => {
        this.applianceMap = applianceMap;
        console.log(this.applianceMap);
        this.sequence = ["AC", "FRIDGE", "WM", "GEYSER", "HEATING_DEVICE", "WATER_PUMP", "OTHERS"]
      });
  }

  toggleAppliance(applianceName, applianceType) {
    this.selectApplianceTypes[applianceName][applianceType]['selected'] = !this
      .selectApplianceTypes[applianceName][applianceType]['selected'];

      this.applianceMap[applianceName][applianceType]['selected'] = !this
      .applianceMap[applianceName][applianceType]['selected'];
  }
}
