<div *ngFor="let item of applianceSelected | keyvalue">
  <h6 class="header_title mb-3">{{ applianceMap[item.key].readableName }}</h6>

  <div *ngFor="let appliance of item.value; let idx = index">
    <div class="row mt-2">
      <div class="col-2 mt-2 mb-2">
        <img src="{{
            applianceMap[appliance.applianceName][appliance.applianceType]
              .imageUrl
          }}" alt="" class="img-fluid appliance__image d-block m-auto" />
      </div>
      <div class="col-4 d-flex align-items-center">
        <input type="text" placeholder="{{ appliance.applianceNickname }}" [(ngModel)]="appliance.applianceNickname" />
      </div>
      <div class="col-6 d-flex justify-content-end">
        <a class="quantity__minus" (click)="removeElement(appliance.applianceName, idx)"><span>-</span></a>
      </div>
    </div>
  </div>
</div>