<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-12 p-0 d-flex align-items-center">
            <a routerLink="/more">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get"
              >Program Training</a
            >
          </div>
          <div class="form-inline my-2 my-lg-0"></div>
        </nav>
      </div>
    </div>
    <div class="row top__row mt-5">
      <div class="col-md-12 mt-5 more px-4">
        <div
          class="row more__card border-bottom pb-4 d-flex align-items-center"
        >
          <div class="col-10">
            <p>Energy Efficiency Program</p>
            <small
              >Reduce your consumption and we provide you incentives based on
              percentage saved</small
            >
          </div>
          <div class="col-2 d-flex justify-content-end">
            <a routerLink="/energy-efficiency">
              <img
                src="../../../assets/img/more/back.png"
                alt=""
                class="img-fluid"
                style="width: 14px"
              />
            </a>
          </div>
        </div>

        <div
          class="row more__card border-bottom pb-4 pt-4 d-flex align-items-center"
        >
          <div class="col-10">
            <p>Product Demo sessions</p>
            <small>Connect with Us to get your queries resolved</small>
          </div>
          <div class="col-2 d-flex justify-content-end">
            <a routerLink="/product-training">
              <img
                src="../../../assets/img/more/back.png"
                alt=""
                class="img-fluid"
                style="width: 14px"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-bottom-navbar></app-bottom-navbar>
</section>
