import { HttpConfigInterceptor } from './service/interceptor.service';
import { environment } from './../environments/environment.prod';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/Login-pages/login/login.component';
import { OtpComponent } from './components/Login-pages/otp/otp.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { ProfileComponent } from './components/Login-pages/profile/profile.component';
import { UnregisterComponent } from './components/Login-pages/unregister/unregister.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AllDoneComponent } from './components/Login-pages/all-done/all-done.component';
import { BottomNavbarComponent } from './components/bottom-navbar/bottom-navbar.component';
import { TopNavbarComponent } from './components/top-navbar/top-navbar.component';
import { DashboardGraphComponent } from './components/Graphs/dashboard-graph/dashboard-graph.component';
import { AnalysisComponent } from './components/analysis/analysis.component';
import { BudgetComponent } from './components/budget/budget.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MoreComponent } from './components/more/more.component';
import { VyasComponent } from './components/vyas/vyas.component';
import { NavbarComponent } from './components/top-navbar/navbar/navbar.component';
import { MyBotComponent } from './components/my-bot/my-bot.component';
import { BotDetailsComponent } from './components/my-bot/bot-details/bot-details.component';
import { BotInfoDetailsComponent } from './components/my-bot/bot-details/bot-info-details/bot-info-details.component';
import { HomeDetailsComponent } from './components/my-bot/bot-details/home-details/home-details.component';
import { UtilityDetailsComponent } from './components/my-bot/bot-details/utility-details/utility-details.component';
import { BotEditComponent } from './components/my-bot/bot-edit/bot-edit.component';
import { BotInfoEditComponent } from './components/my-bot/bot-edit/bot-info-edit/bot-info-edit.component';
import { BotUtilityEditComponent } from './components/my-bot/bot-edit/bot-utility-edit/bot-utility-edit.component';
import { HomeDetailsEditComponent } from './components/my-bot/bot-edit/home-details-edit/home-details-edit.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { EditProfileComponent } from './components/user-profile/edit-profile/edit-profile.component';
import { ApplianceDetailsComponent } from './components/vyas/appliance-details/appliance-details.component';
import { ApplianceHealthDetailsComponent } from './components/vyas/appliance-details/appliance-health-details/appliance-health-details.component';
import { ApplianceProfileDetailsComponent } from './components/vyas/appliance-details/appliance-profile-details/appliance-profile-details.component';
import { ApplianceServiceDetailsComponent } from './components/vyas/appliance-details/appliance-service-details/appliance-service-details.component';
import { ApplianceWarrantyDetailsComponent } from './components/vyas/appliance-details/appliance-warranty-details/appliance-warranty-details.component';
import { CreateEditApplianceComponent } from './components/vyas/create-edit-appliance/create-edit-appliance.component';
import { ApplianceProfileComponent } from './components/vyas/create-edit-appliance/appliance-profile/appliance-profile.component';
import { ApplianceServiceComponent } from './components/vyas/create-edit-appliance/appliance-service/appliance-service.component';
import { ApplianceWarrantyComponent } from './components/vyas/create-edit-appliance/appliance-warranty/appliance-warranty.component';
import { SelectCategoryComponent } from './components/vyas/create-edit-appliance/select-category/select-category.component';
import { VyasOnboardingComponent } from './components/vyas/vyas-onboarding/vyas-onboarding.component';
import { VyasOnboardingPageOneComponent } from './components/vyas/vyas-onboarding/vyas-onboarding-page-one/vyas-onboarding-page-one.component';
import { VyasOnboardingPageTwoComponent } from './components/vyas/vyas-onboarding/vyas-onboarding-page-two/vyas-onboarding-page-two.component';
import { VyasOnboardingPageThreeComponent } from './components/vyas/vyas-onboarding/vyas-onboarding-page-three/vyas-onboarding-page-three.component';
import { DetailedAnalysisComponent } from './components/analysis/detailed-analysis/detailed-analysis.component';
import { DetailedBudgetComponent } from './components/budget/detailed-budget/detailed-budget.component';
import { DiagnosisComponent } from './components/diagnosis/diagnosis.component';
import { DiagnosisInitateComponent } from './components/diagnosis/diagnosis-initate/diagnosis-initate.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { ProductTrainingComponent } from './components/programs/product-training/product-training.component';
import { LoaderComponent } from './components/loader/loader.component';
import { EnergyEfficiencyComponent } from './components/programs/energy-efficiency/energy-efficiency.component';
import { EnergyEfficiencyFormComponent } from './components/programs/energy-efficiency/energy-efficiency-form/energy-efficiency-form.component';
import { BotProfileComponent } from './components/Login-pages/bot-profile/bot-profile.component';
import { UtilityComponent } from './components/Login-pages/utility/utility.component';
import { ApplianceTaggedComponent } from './components/analysis/appliance-tagged/appliance-tagged.component';
import { WrongApplianceTaggedComponent } from './components/analysis/wrong-appliance-tagged/wrong-appliance-tagged.component';
import { TrainYourBotComponent } from './components/train-your-bot/train-your-bot.component';
import { SelectedApplianceListComponent } from './components/train-your-bot/selected-appliance-list/selected-appliance-list.component';
import { ApplianceTrainingComponent } from './components/train-your-bot/appliance-training/appliance-training.component';
import { DataCollectionComponent } from './components/train-your-bot/data-collection/data-collection.component';
import { SupportComponent } from './components/support/support.component';
import { TrackIssueComponent } from './components/support/track-issue/track-issue.component';
import { CreateTicketComponent } from './components/support/create-ticket/create-ticket.component';
import { FileDownloadComponent } from './components/file-download/file-download.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OtpComponent,
    ProfileComponent,
    UnregisterComponent,
    DashboardComponent,
    AllDoneComponent,
    BottomNavbarComponent,
    TopNavbarComponent,
    DashboardGraphComponent,
    AnalysisComponent,
    BudgetComponent,
    MoreComponent,
    VyasComponent,
    NavbarComponent,
    MyBotComponent,
    BotDetailsComponent,
    BotInfoDetailsComponent,
    HomeDetailsComponent,
    UtilityDetailsComponent,
    BotEditComponent,
    BotInfoEditComponent,
    BotUtilityEditComponent,
    HomeDetailsEditComponent,
    UserProfileComponent,
    EditProfileComponent,
    ApplianceDetailsComponent,
    ApplianceHealthDetailsComponent,
    ApplianceProfileDetailsComponent,
    ApplianceServiceDetailsComponent,
    ApplianceWarrantyDetailsComponent,
    CreateEditApplianceComponent,
    ApplianceProfileComponent,
    ApplianceServiceComponent,
    ApplianceWarrantyComponent,
    SelectCategoryComponent,
    VyasOnboardingComponent,
    VyasOnboardingPageOneComponent,
    VyasOnboardingPageTwoComponent,
    VyasOnboardingPageThreeComponent,
    DetailedAnalysisComponent,
    DetailedBudgetComponent,
    DiagnosisComponent,
    DiagnosisInitateComponent,
    ProgramsComponent,
    ProductTrainingComponent,
    LoaderComponent,
    EnergyEfficiencyComponent,
    EnergyEfficiencyFormComponent,
    BotProfileComponent,
    UtilityComponent,
    ApplianceTaggedComponent,
    WrongApplianceTaggedComponent,
    TrainYourBotComponent,
    SelectedApplianceListComponent,
    ApplianceTrainingComponent,
    DataCollectionComponent,
    SupportComponent,
    TrackIssueComponent,
    CreateTicketComponent,
    FileDownloadComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFirePerformanceModule,
    AngularFireStorageModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [
    AngularFirePerformanceModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
  exports: [FormsModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
