<section>
  <div class="container mt-5">
    <div class="row">
      <div class="col-12 mt-5">
        <h4 class="font-weight-bold text-center">Verify your phone number</h4>
        <p class="text-center mt-3">Enter your OTP code here</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <form
          #opt_form="ngForm"
          class="opt_form mt-5"
          id="opt_form"
          name="opt_form"
        >
          <div class="row">
            <div class="col-2 form-group">
              <input
                class="form-control"
                id="codeBox1"
                type="number"
                maxlength="1"
                (keyup)="onKeyUpEvent(1, $event)"
                (focus)="onFocusEvent(1)"
                [(ngModel)]="code1"
                name="code1"
                required
              />
            </div>
            <div class="col-2 form-group">
              <input
                class="form-control"
                id="codeBox2"
                type="number"
                maxlength="1"
                (keyup)="onKeyUpEvent(2, $event)"
                (focus)="onFocusEvent(2)"
                [(ngModel)]="code2"
                name="code2"
                required
              />
            </div>
            <div class="col-2 form-group">
              <input
                class="form-control"
                id="codeBox3"
                type="number"
                maxlength="1"
                (keyup)="onKeyUpEvent(3, $event)"
                (focus)="onFocusEvent(3)"
                [(ngModel)]="code3"
                name="code3"
                required
              />
            </div>
            <div class="col-2 form-group">
              <input
                class="form-control"
                id="codeBox4"
                type="number"
                maxlength="1"
                (keyup)="onKeyUpEvent(4, $event)"
                (focus)="onFocusEvent(4)"
                [(ngModel)]="code4"
                name="code4"
                required
              />
            </div>
            <div class="col-2 form-group">
              <input
                class="form-control"
                id="codeBox5"
                type="number"
                maxlength="1"
                (keyup)="onKeyUpEvent(5, $event)"
                (focus)="onFocusEvent(5)"
                [(ngModel)]="code5"
                name="code5"
                required
              />
            </div>
            <div class="col-2 form-group">
              <input
                class="form-control"
                id="codeBox6"
                type="number"
                maxlength="1"
                (keyup)="onKeyUpEvent(6, $event)"
                (focus)="onFocusEvent(6)"
                [(ngModel)]="code6"
                name="code6"
                required
              />
            </div>
          </div>
          <!-- <div id="countdown" class="text-danger font-weight-bold"></div> -->
          <div class="row">
            <div class="col-md-12">
              <p class="text-center mt-5" style="font-size: 12px">
                Didn’t you receive any code ?
                <span
                  id="countdown"
                  class="text-danger font-weight-bold"
                ></span>
                <br />
                <a
                  id="countdownOpt"
                  class="text-danger font-weight-bold mt-3"
                  style="cursor: pointer"
                  (click)="resendOTP()"
                >
                  Resend new code
                </a>
              </p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <div class="opt_btn mt-4">
                <button type="submit" class="btn w-100" (click)="sendOTP()">
                  Submit OTP
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
