import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CommonApiService } from 'src/app/service/common-api.service';

@Component({
  selector: 'app-appliance-training',
  templateUrl: './appliance-training.component.html',
  styleUrls: ['./appliance-training.component.scss'],
})
export class ApplianceTrainingComponent implements OnInit {
  appliance_id: any;
  appliance: any;
  constructor(
    private api: CommonApiService,
    private route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.appliance_id = this.route.snapshot.params['appliance_id'];
    this.api
      .getapplianceById(this.appliance_id)
      .pipe(first())
      .subscribe((res) => {
        this.appliance = res;
      });
    $('#train__btn').prop('disabled', true);
  }

  navPrev() {
    this._router.navigateByUrl(
      `/train-your-bot/${this.appliance.applianceName}`
    );
  }

  showButton() {
    $('#train__btn').prop('disabled', false);
  }

  startTraining() {
    this._router.navigateByUrl(`/data-collection/${this.appliance._id}`);
  }
}
