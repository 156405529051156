<div class="container-fluid">
  <div class="row top__row">
    <div class="col-12">
      <p class="text-light mt-4">Select apppliance category</p>
    </div>
  </div>

  <div class="row mt-4">
    <div
      class="col-6 card_items mt-3 mb-3"
      *ngFor="let category of categories; let index = odd"
      [ngClass]="odd ? 'odd' : 'even'"
    >
      <div class="card" (click)="selectCard(category)">
        <div class="card-body p-0">
          <img
            src="{{ category.image }}"
            alt="ac"
            class="img-fluid d-block m-auto"
          />
          <p class="text-center font-weight-bold mt-2">{{ category.name }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
