import { first } from 'rxjs/operators';
import { CommonApiService } from './../../../../service/common-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-details',
  templateUrl: './home-details.component.html',
  styleUrls: ['./home-details.component.scss'],
})
export class HomeDetailsComponent implements OnInit {
  meter_id: any;
  home_details: any;
  constructor(
    public router: Router,
    public _api: CommonApiService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.meter_id = this.route.snapshot.params['meter_id'];
    this.botInfoDetails();
  }
  botInfoDetails() {
    this._api
      .botInfoGet(this.meter_id)
      .pipe(first())
      .subscribe((res) => {
        this.home_details = res;
      });
  }

  homeEdit() {
    this.router.navigateByUrl(`/bot-home-details-edit/${this.meter_id}`);
  }
}
