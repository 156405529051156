import { CommonApiService } from "./../../../service/common-api.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { first } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-unregister",
  templateUrl: "./unregister.component.html",
  styleUrls: ["./unregister.component.scss"],
})
export class UnregisterComponent implements OnInit {
  botid: any;
  confirmbotid: any;
  meter_id: any;
  constructor(public _api: CommonApiService, public _router: Router) {}

  ngOnInit(): void {}

  updateUser() {
    let formdata = {
      meters: this.confirmbotid,
    };

    this._api
      .userUpdate(formdata)
      .pipe(first())
      .subscribe((res) => {
        if (res) {
          localStorage.setItem("user", JSON.stringify(res));
          // this.hideUnregisteredMessage();
          this.meter_id = res["meters"];
          this._api.setSelectedMeterId(res["meters"][0]);
          this._api.meter_id = this.meter_id;
          let displayName = Boolean(res["displayName"]);

          if (displayName === false) {
            this._router.navigate(["profile"]);
          } else {
            this._router.navigate(["/dashboard"]);
          }
        }
      });
  }

  mapping() {
    this._api
      .meterMapping(this.confirmbotid, {})
      .pipe(first())
      .subscribe(
        (data) => {
          
          Swal.fire({
            text: "Mapping Successful",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#E50021",
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.isConfirmed) {
              //  if(err["error"]['messaage'] == "")
              this.updateUser();
              this._router.navigateByUrl(`/bot-profile/${this.meter_id}`);
            }
          });
        },
        (err) => {
          Swal.fire({
            text: err["error"]["message"],
            icon: "error",
            confirmButtonColor: "#E50021",
          });
        }
      );
  }
}
