<app-loader *ngIf="loader"></app-loader>
<section>
  <app-top-navbar
    [selectedMeterId]="selectedMeterId"
    (meterChange)="meterChangeEvent($event)"
  ></app-top-navbar>

  <div class="container">
    <div class="row mt-md-5 d-flex align-items-center">
      <div class="col-2">
        <img
          src="../../../assets/img/dashboard/on.gif"
          alt=""
          class="img-fluid"
          *ngIf="lastTime === 'a few seconds ago'"
        />
        <img
          src="../../../assets/img/dashboard/off.svg"
          alt=""
          class="img-fluid"
          style="width: 25px"
          *ngIf="lastTime !== 'a few seconds ago'"
        />
      </div>
      <div class="col-8 p-0">
        <p class="mb-0">Hey {{ displayName.displayName }}</p>
      </div>
    </div>
    <div class="row mt-md-4 mt-3">
      <div class="col-md-11 ml-2">
        <small *ngIf="lastTime !== 'a few seconds ago'"
          >Last updated: {{ lastTime }}</small
        >
        <small *ngIf="lastTime === 'a few seconds ago'"
          >Your home is running at</small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-md-11 ml-2">
        <h3 *ngIf="liveActivePower">
          {{ liveActivePower }} <small style="font-size: 14px">Watt</small>
        </h3>
        <h3 *ngIf="!liveActivePower">
          --- <small style="font-size: 14px">Watt</small>
        </h3>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-11 ml-2">
        <p class="month__consumption" *ngIf="estimatedConsumption">
          This month:
          {{ estimatedConsumption.thisMonthNetConsumption.toFixed(0) }} Units
        </p>
      </div>
    </div>
    <div class="row mt-md-5" id="top" *ngIf="img__div">
      <div class="col-md-12 img__row">
        <!-- <img
          src="../../../assets/img/dashboard/am.png"
          alt=""
          class="img-fluid d-block m-auto"
          *ngIf="lastTime === 'a few seconds ago' || am"
        /> -->
        <!-- <img
          src="../../../assets/img/dashboard/pm.png"
          alt=""
          class="img-fluid"
          *ngIf="lastTime === 'a few seconds ago' || !am"
        /> -->
        <!-- <img
          src="../../../assets/img/dashboard/bg_with_no_dog.png"
          alt=""
          class="img-fluid d-block m-auto"
          *ngIf="lastTime !== 'a few seconds ago'"
        /> -->
        <img
          src="../../../assets/img/dashboard/am.png"
          alt=""
          class="img-fluid d-block m-auto"
          *ngIf="dogStatus == 1"
        />
        <img
          src="../../../assets/img/dashboard/pm.png"
          alt=""
          class="img-fluid"
          *ngIf="dogStatus == 2"
        />
        <img
          src="../../../assets/img/dashboard/bg_with_no_dog.png"
          alt=""
          class="img-fluid d-block m-auto"
          *ngIf="dogStatus == 3"
        />
        <button (click)="swipeUp()" class="swipeUp">
          <i class="fa fa-angle-up"></i>
        </button>
      </div>
    </div>
    <div class="row" id="down" *ngIf="graph__div">
      <div class="col-md-12 img__row">
        <button (click)="swipeDown()" class="swipeDown">
          <i class="fa fa-angle-down"></i>
        </button>
        <p class="font-weight-bold">Last 24Hours (units)</p>
        <app-dashboard-graph></app-dashboard-graph>
      </div>
    </div>
  </div>

  <app-bottom-navbar></app-bottom-navbar>
</section>
