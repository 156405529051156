<app-loader *ngIf="loader"></app-loader>
<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-12 p-0 d-flex align-items-center">
            <a routerLink="/support">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get"
              >Track Issues</a
            >
          </div>
        </nav>
      </div>
    </div>
    <div class="row top__row">
      <div class="col-md-6 d-block ml-auto px-4">
        <div class="form-group">
          <select
            class="form-control minimal"
            id="exampleFormControlSelect1"
            (change)="filterBy($event)"
          >
            <option selected disabled>Filter by status</option>
            <option value="All">All</option>
            <option value="New">New</option>
            <option value="In process">In process</option>
            <option value="On hold">On hold</option>
            <option value="Resolved">Resolved</option>
            <option value="Closed">Closed</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 mt-4 px-4" *ngFor="let ticket of tickets">
        <div
          class="card"
          [ngStyle]="{
            color: ticket.status === 'Closed' ? '#AAAAAA' : '#292929'
          }"
        >
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-12">
                <p *ngIf="ticket.meter_id === null">Bot Id : --</p>
                <p *ngIf="ticket.meter_id !== null">
                  Bot Id : {{ ticket.meter_id }}
                </p>
                <p>Issue type : {{ record_type_id }}</p>
                <p>Sub-Type : {{ ticket.sub_type }}</p>
                <p *ngIf="ticket.status === null">
                  Status : <span style="color: #e50021">New</span>
                </p>
                <p *ngIf="ticket.status !== null">
                  Status :
                  <span
                    *ngIf="
                      ticket.status == 'New' || ticket.status == 'In process'
                    "
                    style="color: #e50021"
                    >{{ ticket.status }}</span
                  >
                  <span
                    *ngIf="ticket.status == 'On hold'"
                    style="color: #fd3b1f"
                    >{{ ticket.status }}</span
                  >
                  <span
                    *ngIf="ticket.status == 'Resolved'"
                    style="color: #00a912"
                    >{{ ticket.status }}</span
                  >
                  <span
                    *ngIf="ticket.status == 'Closed'"
                    style="color: #aaaaaa"
                    >{{ ticket.status }}</span
                  >
                </p>
                <p>Updated: {{ ticket.last_update }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-5">
        <p class="text-center" *ngIf="noData">No data found</p>
      </div>
    </div>
  </div>
</section>
