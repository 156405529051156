import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Chart, registerables } from "chart.js";
import * as moment from "moment";
import { first } from "rxjs/operators";
import { CommonApiService } from "src/app/service/common-api.service";
import { MqttService } from "src/app/service/mqtt.service";
import { environment } from "src/environments/environment";
declare var $: any;

@Component({
  selector: "app-data-collection",
  templateUrl: "./data-collection.component.html",
  styleUrls: ["./data-collection.component.scss"],
})
export class DataCollectionComponent implements OnInit {
  mqttService: MqttService;
  meterId: Number;
  PUBLISH_TOPIC = "tyb/mobile";
  appliance: any;
  appliance_id: any;
  liveChart: any;
  plotLabels = [];
  plotValues = [];
  timerInterval: any;
  percentCompleted: number;
  trainingFailedMessage: any;
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private api: CommonApiService
  ) {}

  ngOnInit(): void {
    this.appliance_id = this.route.snapshot.params["appliance_id"];
    this.getApplianceDetails();
    this.meterId = parseInt(localStorage.getItem("selected_meter"));
    this.PUBLISH_TOPIC = `${this.PUBLISH_TOPIC}/${this.meterId}`;
    this.mqttService = new MqttService(environment.mqtt_host, [this.meterId]);
    this.mqttService.listenTYB().subscribe((message) => {
      this.onMessage(message);
    });

    $("#graph__container").hide();
    $("#progress__container").hide();
    $("#stability__card").hide();
    $("#train__btn").prop("disabled", true);
    $("#train__next__btn").prop("disabled", true);

    // define and load graph
    this.graph();
  }

  intitiateStability() {
    $("#gif__container").hide();
    $("#progress__container").hide();
    $("#graph__container").show();
    $("#stability__card").show();
    // INITIATE
    // Sends server packet to inform start of TYB cycles
    this.mqttService.publish(this.PUBLISH_TOPIC, {
      event_name: "initiate_training",
      event_details: {
        m_id: this.meterId.toString(),
        appliance_data: this.appliance,
        last_retained_packet: localStorage.getItem("lastMessage"),
      },
      event_ts: moment().toISOString(),
      platform: "web",
    });

    this.timerInterval = setInterval(() => {
      this.mqttService.publish(this.PUBLISH_TOPIC, {
        event_name: "beacon",
        event_details: {
          m_id: this.meterId.toString(),
          appliance_data: this.appliance,
          last_retained_packet: localStorage.getItem("lastMessage"),
        },
        event_ts: moment().toISOString(),
        platform: "web",
      });
    }, 20000);
  }

  graph() {
    // var ctx = document.getElementById('myChart');
    var canvas: any = document.getElementById("trainChart");
    var ctx = canvas.getContext("2d");
    Chart.register(...registerables);
    this.liveChart = new Chart(ctx, {
      // The type of chart we want to create
      type: "line",

      // The data for our dataset
      data: {
        labels: this.plotLabels,
        datasets: [
          {
            data: this.plotValues,
            fill: true,
            backgroundColor: "rgba(187, 0, 27, 0.3)",
            borderColor: "rgba(187, 0, 27, 1)",
            borderDashOffset: 0.0,
            borderWidth: 1,
            pointBorderColor: "rgba(187, 0, 27, 0.3)",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 3,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(187, 0, 27, 0.3)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 4,
            pointRadius: 1,
            pointHitRadius: 10,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: 0.5,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            beginAtZero: true,
          },
        },
      },
    });
  }

  onMessage(message): void {
    console.log(message);
    switch (message.event_name) {
      case "packet_plot":
        // PLOT
        let time = moment(message.event_ts).format("MM:SS");
        this.plot(time, message.event_details.current_packet.a);
        this.updateProgressLoader(
          message.event_details.training_percent_complete
        );
        break;

      case "stability":
        // based on message.stability == 0 or 1
        if (message.event_details.stability == 1) {
          // stability Achived
          this.stabilitySuccess();
        } else if (message.event_details.stability == 0) {
          // stability Failed
          this.stabilityFailed();
        }
        break;

      case "finish":
        if (message.event_details.anomaly == 0) {
          // SUCCESS
          this.trainingSucess();
        } else {
          // FAILED
          $("#trainingFailedModal").modal().show();
          this.trainingFailed(message);
        }
        break;
    }
  }

  trainingSucess() {}

  trainingFailed(message) {
    console.log(message.event_details.msg);

    this.trainingFailedMessage = message.event_details.msg;
  }

  trainingFailedOk() {
    this.mqttService.publish(this.PUBLISH_TOPIC, {
      event_name: "abort",
      event_details: {
        m_id: this.meterId.toString(),
        reason: "training_failed",
      },
      event_ts: moment().toISOString(),
    });
    $(".modal-backdrop").remove();
    this._router.navigateByUrl(
      `/train-your-bot/${this.appliance.applianceName}`
    );
    clearInterval(this.timerInterval);
  }

  stabilitySuccess() {
    $("#startApplianceModal").modal().show();
  }

  stabilityFailed() {}

  startAppliance() {
    $("#startApplianceModal").modal().hide();
    $(".modal-backdrop").remove();
    $("#graph__container").addClass("top__row");
    $("#progress__container").show();
    this.mqttService.publish(this.PUBLISH_TOPIC, {
      event_name: "start_data_collection",
      event_details: {
        m_id: this.meterId.toString(),
      },
      event_ts: moment().toISOString(),
      platform: "web",
    });
    $("#stability__card").hide();
    $(".progress__container").show();
  }

  stopTraining() {
    $("#stopTrainingModal").modal().show();
  }

  stopTrainingYes() {
    this.mqttService.publish(this.PUBLISH_TOPIC, {
      event_name: "abort",
      event_details: {
        m_id: this.meterId.toString(),
        reason: "user_stopped",
      },
      event_ts: moment().toISOString(),
    });
    $(".modal-backdrop").remove();
    this._router.navigateByUrl(
      `/train-your-bot/${this.appliance.applianceName}`
    );
    clearInterval(this.timerInterval);
  }

  stopTrainingNo() {}

  plot(label: string, dataPoint: Number) {
    // update the graph (keep append)
    this.plotLabels.push(label);
    this.plotValues.push(dataPoint);
    this.liveChart.update();
  }

  updateProgressLoader(percentCompleted: number) {
    // Update the green loader
    $("#dynamic").css({ width: percentCompleted });
    this.percentCompleted = percentCompleted;
  }

  ngOnDestroy(): void {
    if (this.mqttService) {
      this.mqttService.publish(this.PUBLISH_TOPIC, {
        event_name: "abort",
        event_details: { m_id: this.meterId.toString() },
        event_ts: moment().toISOString(),
        platform: "web",
      });
      this.mqttService.close();
    }
    clearInterval(this.timerInterval);
  }

  getApplianceDetails() {
    this.api
      .getapplianceById(this.appliance_id)
      .pipe(first())
      .subscribe((res) => {
        this.appliance = res;
      });
  }

  navPrev() {
    this._router.navigateByUrl(
      `/train-your-bot/${this.appliance.applianceName}`
    );
  }

  showButton() {
    // $("#train__btn").prop("disabled", false);
    $("input:checkbox[name=customCheck1]").is(":checked");
    if ($("input:checkbox[name=customCheck1]").is(":checked") == true) {
      $("#train__btn").prop("disabled", false);
    }
    if ($("input:checkbox[name=customCheck1]").is(":checked") == false) {
      $("#train__btn").prop("disabled", true);
    }
  }
  showNextButton() {
    $("#train__next__btn").prop("disabled", false);
  }
}
