export const environment = {
  production: true,
  version: "0.5.1",
  buildId: 24,
  // mqtt_host: "mqtt-dev.ddns.net",
  // baseUrl: "https://ohmassistant-api-dev.ddns.net",
  mqtt_host: "mqtt-server.ddns.net",
  baseUrl: "https://api.sustlabs.com",
  firebaseConfig: {
    apiKey: "AIzaSyDdeS_PO3wtpQBo8w6ouAjRwU5DQqz6CmY",
    authDomain: "ohmassistant-android.firebaseapp.com",
    databaseURL: "https://ohmassistant-android.firebaseio.com",
    projectId: "ohmassistant-android",
    storageBucket: "ohmassistant-android.appspot.com",
    messagingSenderId: "366076925219",
    appId: "1:366076925219:web:dd269ce254d9c72ee6e896",
    measurementId: "G-EEPF2B993Y",
  },
};
