<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-8">
        <p
          class="font-weight-bold text-light text-uppercase"
          *ngIf="appliance"
          style="margin-bottom: -8px"
        >
          {{ appliance.applianceNickname }}
        </p>
        <small>OHM ASSISTANT</small>
      </div>
      <div
        class="col-4 d-flex align-items-baseline"
        style="justify-content: flex-end"
      >
        <a (click)="edit()" style="cursor: pointer; font-size: 14px">EDIT</a>
        <!-- <a (click)="edit()" style="cursor: pointer"
          ><img
            src="../../../../../assets/img/vyas/edit.svg"
            alt="edit"
            style="width: 30px"
        /></a> -->
        <!-- <a style="cursor: pointer" (click)="deleteAppliance(appliance._id)">
          <img
            src="../../../../../assets/img/vyas/delete.svg"
            alt="delete"
            class="img_fluid delete_img"
        /></a> -->
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 mt-2">
        <p>
          <span class="sub_para">Model | </span>
          <span class="sub_para1" *ngIf="appliance">{{
            appliance.applianceModelNo
          }}</span>
        </p>
      </div>
      <div class="col-12 mt-2">
        <p>
          <span class="sub_para">Brand | </span>
          <span class="sub_para1" *ngIf="appliance">{{
            appliance.applianceBrand
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="sub_para">Type | </span>
          <span class="sub_para1" *ngIf="appliance">{{
            appliance.applianceType
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p cla>
          <span class="sub_para">Capacity | </span>
          <span class="sub_para1" *ngIf="appliance"
            >{{ appliance.applianceCapacity }}
            {{ appliance.applianceCapacityMeasure }}
          </span>
        </p>
      </div>
    </div>
    <!-- <hr class="mt-0" /> -->
    <div class="row mt-4">
      <div class="col-6">
        <p class="mb-0 sub_para">Year of purchase</p>
        <p class="sub_para1 mb-0" *ngIf="appliance">
          {{ appliance.applianceDatePurchase | date: "y" }}
        </p>
      </div>
      <div class="col-6">
        <p class="mb-0 sub_para">Star Rating</p>
        <p class="sub_para1 mb-0" *ngIf="appliance">
          {{ appliance.applianceStar }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="row mt-4 mt-md-5 details">
  <div class="col-6">
    <p class="ml-3 details_1">Warranty Expiry</p>
  </div>
  <div class="col-6">
    <p class="text-center details_2" *ngIf="appliance">
      {{ appliance.warrantyEndDate | date: "mediumDate" }}
    </p>
    <p class="text-center details_2" *ngIf="!appliance">---</p>
  </div>
</div>

<hr />
<div class="row details">
  <div class="col-6">
    <p class="ml-3 details_1">Covered by</p>
  </div>
  <div class="col-6">
    <p class="text-center details_2" *ngIf="appliance">
      {{ appliance.warrantyCoveredBy }}
    </p>
  </div>
</div>

<hr />
<div class="row details">
  <div class="col-6">
    <p class="ml-3 details_1">Last Serviced</p>
  </div>
  <div class="col-6">
    <p class="text-center details_2" *ngIf="serviceData">
      {{ serviceData | date: "mediumDate" }}
    </p>
    <p class="text-center details_2" *ngIf="!serviceData">---</p>
  </div>
</div>

<hr />

<div class="row details">
  <div class="col-6">
    <p class="ml-3 details_1">Status</p>
  </div>
  <div class="col-6">
    <p class="text-center details_2 text-lowercase" *ngIf="diagnosisStatus">
      {{ diagnosisStatus }}
    </p>
    <p class="text-center details_2 text-lowercase" *ngIf="!diagnosisStatus">
      UNHEALTHY
    </p>
  </div>
</div>
