<div class="create_action" *ngIf="mode !== 'EDIT'">
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <p class="font-weight-bold mb-0">Are you covered under service</p>
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          id="customSwitch1"
          checked
          (click)="show_details()"
        />
        <label class="custom-control-label" for="customSwitch1"></label>
      </div>
    </div>
  </div>

  <div class="show_details row mt-2">
    <div class="col-12 mt-2">
      <div class="form-group">
        <label for="serviceCompany" *ngIf="mode == 'EDIT'"
          >Service Company</label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Service company"
          [(ngModel)]="appliance.serviceCompany"
        />
      </div>
    </div>
    <div class="col-12 mt-2">
      <div class="form-group">
        <label for="typeAppliance" *ngIf="mode == 'EDIT'">Vendor Type</label>
        <select
          class="form-control"
          id="typeAppliance"
          [(ngModel)]="appliance.vendorType"
          name="vendorType"
        >
          <option disabled selected value="undefined">Vendor Type</option>
          <option value="LOCAL_TECHINIAN">Local Techinian</option>
          <option value="AMC_PROVIDER">AMC Provider</option>
          <option value="MANUFACTURER_SERVICE">Manufacturer Service</option>
          <option value="ON_DEMAND_SERVICE">On Demand Service</option>
        </select>
      </div>
    </div>
    <div class="col-12 mt-2">
      <div class="form-group">
        <label for="typeAppliance" *ngIf="mode == 'EDIT'">Service Type</label>
        <select
          class="form-control"
          id="typeAppliance"
          [(ngModel)]="appliance.serviceType"
          name="serviceType"
        >
          <option disabled selected value="undefined">Service Type</option>
          <option value="MINOR_REPAIRS">Minor Repairs</option>
          <option value="ROUTINE_SERVICE">Routine Service</option>
          <option value="MAJOR_REPAIRS">Major Repairs</option>
          <option value="PART_REPLACMENT">Part Replacement</option>
        </select>
      </div>
    </div>
    <div class="col-12 mt-2">
      <div class="form-group">
        <label for="dateOfService" *ngIf="mode == 'EDIT'"
          >Date of Service</label
        >
        <input
          type="text"
          onfocus="(this.type='date')"
          class="form-control"
          placeholder="Date of service"
          [(ngModel)]="appliance.dateOfService"
          name="dateOfService"
          min="{{ appliancePurchaseDate || !appliancePurchaseDate }}"
          max="{{ today || today }}"
        />
      </div>
    </div>
    <div class="col-12 mt-2">
      <div class="form-group">
        <label for="comments" *ngIf="mode == 'EDIT'">Comments</label>
        <input
          type="text"
          class="form-control"
          placeholder="Comments"
          [(ngModel)]="appliance.comments"
        />
      </div>
    </div>
  </div>
  <div class="row mt-3 submit_row">
    <div class="col-12">
      <button class="btn next d-block m-auto w-50" (click)="submit()">
        Submit
      </button>
    </div>
  </div>
</div>

<section class="add_action" *ngIf="mode == 'EDIT'">
  <nav class="navbar navbar-expand-lg navbar-light transparent">
    <div class="col-6 p-0 d-flex align-items-center">
      <a routerLink="/vyas">
        <img src="../../../assets/img/arrow-left.png" alt=""
      /></a>
      <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get">Add Service</a>
    </div>
  </nav>
  <div class="container-fluid">
    <form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
      <div class="row mt-5">
        <div class="col-12 mt-5">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 d-flex justify-content-between">
                  <p class="font-weight-bold mb-0">
                    Are you covered under service
                  </p>
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitch1"
                      checked
                      (click)="show_details()"
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitch1"
                    ></label>
                  </div>
                </div>
              </div>

              <div class="show_details row mt-2">
                <div class="col-12 mt-2">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Service company"
                      [(ngModel)]="serviceCompany"
                      required
                    />
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="typeAppliance"
                      [(ngModel)]="vendorType"
                      name="vendorType"
                      required
                    >
                      <option disabled selected value="undefined">
                        Vendor Type
                      </option>
                      <option value="LOCAL_TECHINIAN">Local Techinian</option>
                      <option value="AMC_PROVIDER">AMC Provider</option>
                      <option value="MANUFACTURER_SERVICE">
                        Manufacturer Service
                      </option>
                      <option value="ON_DEMAND_SERVICE">
                        On Demand Service
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="typeAppliance"
                      [(ngModel)]="serviceType"
                      name="serviceType"
                      required
                    >
                      <option disabled selected value="undefined">
                        Service Type
                      </option>
                      <option value="MINOR_REPAIRS">Minor Repairs</option>
                      <option value="ROUTINE_SERVICE">Routine Service</option>
                      <option value="MAJOR_REPAIRS">Major Repairs</option>
                      <option value="PART_REPLACMENT">Part Replacement</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <div class="form-group">
                    <input
                      type="text"
                      onfocus="(this.type='date')"
                      class="form-control"
                      placeholder="Date of service"
                      [(ngModel)]="dateOfService"
                      name="dateOfService"
                      min="{{
                        appliancePurchaseDate || !appliancePurchaseDate
                      }}"
                      max="{{ today || today }}"
                      required
                    />
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Comments"
                      [(ngModel)]="comments"
                      required
                    />
                  </div>
                </div>

                <button
                  class="btn next d-block m-auto w-50"
                  [disabled]="!f.form.valid"
                  (click)="serviceAdd()"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
