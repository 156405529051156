<section class="profile_section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-md-12">
              <nav class="navbar navbar-expand-lg navbar-light transparent">
                <div class="col-6 p-0 d-flex align-items-center">
                  <a routerLink="/user-profile">
                    <img
                      src="../../../assets/img/arrow-left.png"
                      alt=""
                      class="img-fluid d-block m-auto"
                  /></a>
                  <a class="mb-0 pl-3 pr-3 d-lg-inline-block"> Back </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid p-0 edit__container">
    <div class="row">
      <div class="col-12 mt-5">
        <div class="card">
          <div class="card-body">
            <div class="row top__row">
              <div class="col-6">
                <a routerLink="/user-profile" class="title_get">Cancel</a>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <a
                  class="title_get"
                  (click)="saveProfile()"
                  style="cursor: pointer"
                  >Done</a
                >
              </div>
            </div>

            <div class="row profile__img__row mt-4">
              <div
                class="col-4 d-flex justify-content-center align-items-center p-0"
              >
                <img
                  src="https://www.w3schools.com/howto/img_avatar.png"
                  alt="Avatar"
                  class="avatar"
                />
              </div>
              <div
                class="col-8 d-flex justify-content-center align-items-center p-0 mt-3"
              >
                <p>Enter your name and add an optional profile picture</p>
              </div>
            </div>

            <form>
              <div class="row profile__edit mt-5">
                <div class="col-6">Name</div>
                <div
                  class="col-6 d-flex justify-content-end align-items-center"
                  *ngIf="profile"
                >
                  <input
                    type="text"
                    [(ngModel)]="profile.displayName"
                    name="displaName"
                    minlength="2"
                    maxlength="20"
                    autofocus
                  />
                </div>
                <div
                  class="col-6 d-flex justify-content-end align-items-center"
                  *ngIf="!profile"
                >
                  <p>---</p>
                </div>
              </div>
              <div class="row profile__edit mt-3">
                <div class="col-4">Email</div>
                <div
                  class="col-8 d-flex justify-content-end align-items-center flex-column"
                  *ngIf="profile"
                >
                  <input
                    type="email"
                    [(ngModel)]="profile.email"
                    name="email"
                    id="emailId"
                  />
                  <p
                    *ngIf="emailcheck"
                    class="text-danger text-center"
                    style="font-size: 12px; margin-left: -50px"
                  >
                    Please check the email ID
                  </p>
                </div>
                <div
                  class="col-6 d-flex justify-content-end align-items-center"
                  *ngIf="!profile"
                >
                  <p>---</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
