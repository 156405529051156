import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vyas-onboarding-page-three',
  templateUrl: './vyas-onboarding-page-three.component.html',
  styleUrls: ['./vyas-onboarding-page-three.component.scss'],
})
export class VyasOnboardingPageThreeComponent implements OnInit {
  applianceMap: any;
  constructor(private http: HttpClient) {}

  @Input() applianceSelected;
  ngOnInit(): void {
    this.http
      .get('../../../../assets/json/vyasApplianceList.json')
      .subscribe((applianceMap) => {
        this.applianceMap = applianceMap;
      });
  }

  removeElement(applianceName, index) {
    this.applianceSelected[applianceName].splice(index, 1);
    if (this.applianceSelected[applianceName].length == 0) {
      delete this.applianceSelected[applianceName];
    }
  }
}
