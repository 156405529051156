<section>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-3 red__background">
        <h1 class="text-center text-light font-weight-bold">1</h1>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12 mt-5">
        <h4 class="font-weight-bold text-center">Mapping</h4>
        <p class="mb-0 mt-3 text-center">
          Now it’s time to map your number with the bot.
        </p>
        <p class="text-center">
          Kindly enter the 4 digit bot ID and get started.
        </p>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12 mt-3">
        <form
          #form_mapping="ngForm"
          class="form_mapping"
          id="form_mapping"
          name="form_mapping"
        >
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Enter your Bot ID"
              id="botid"
              [(ngModel)]="botid"
              name="botid"
              minlength="4"
              maxlength="4"
              pattern="\d{10}"
              required
            />
          </div>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Confirm Bot ID"
              id="confirmbotid"
              [(ngModel)]="confirmbotid"
              name="confirmbotid"
              minlength="4"
              maxlength="4"
              pattern="\d{10}"
              required
            />
          </div>

          <div class="row mt-5">
            <div class="col-md-12">
              <button type="button" class="btn w-100 mt-5" (click)="mapping()">
                <img src="../../../../assets/img/Login/button.png" alt="" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
