<!-- <app-loader *ngIf="loader"></app-loader> -->
<div class="row" *ngIf="purchaseDate">
  <div class="col-md-12" id="details">
    <div class="row mt-4 mt-md-5 details">
      <div class="col-6">
        <p class="ml-3 details_1">Warranty Card</p>
      </div>
      <div class="col-6 d-flex justify-content-end align-items-lg-baseline">
        <button class="view_btn" type="button" (click)="view()">View</button>
      </div>
    </div>

    <hr />
    <div class="row details">
      <div class="col-6">
        <p class="ml-3 details_1">Start date</p>
      </div>
      <div class="col-6 d-flex align-items-center justify-content-end">
        <p class="text-right details_2" *ngIf="appliance">
          <!-- {{ appliance.purchaseDate | date: "dd/MM/yyyy" }} -->
          {{ purchaseDate }}
        </p>
        <p
          class="text-right details_2 font-weight-bold"
          *ngIf="purchaseDate == null"
        >
          ---
        </p>
      </div>
    </div>

    <hr />
    <div class="row details">
      <div class="col-6">
        <p class="ml-3 details_1">Duration</p>
      </div>
      <div class="col-6 d-flex align-items-center justify-content-end">
        <p class="text-right details_2" *ngIf="appliance">
          <!-- {{ appliance.warrantyEndDate | date: "dd/MM/yyyy" }} -->
          {{ warrantyEndDate }} days
        </p>
        <p
          class="text-right details_2 font-weight-bold"
          *ngIf="warrantyEndDate == null"
        >
          ---
        </p>
      </div>
    </div>

    <hr />
  </div>

  <div class="col-md-12" id="warrantImage">
    <img src="{{ signedUrl }}" alt="" class="img-fluid w-100" />
    <button (click)="close()" class="btn close__btn d-block ml-auto mr-auto mt-5">
      Close
    </button>
  </div>
</div>

<div class="row noservice" *ngIf="!purchaseDate">
  <div class="col-12">
    <img
      src="../../../../../assets/img/vyas/nowarrraty.png"
      alt="noService"
      class="img-fluid d-block m-auto"
      style="width: 150px; object-fit: contain"
    />
    <p class="text-center" style="opacity: 0.8">
      Oops! Looks like Warranty details for this product has not been added yet.
      Please enter warranty details and forget the hassle of remembering it in
      long term
    </p>
    <button
      class="btn add_more d-block ml-auto mr-auto fixed-bottom mt-5"
      data-toggle="modal"
      data-target="#serviceForm"
      (click)="addWarranty()"
    >
      <img
        src="../../../../../assets/img/vyas/add.png"
        alt=""
        class="img-fluid"
      />
    </button>
  </div>
</div>

<!-- File View -->
<div
  class="modal fade"
  id="fileModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="fileModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="fileModalLabel">Warranty Card</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="signedUrl">
        <img src="{{ signedUrl }}" alt="" class="img-fluid w-100" />
      </div>
    </div>
  </div>
</div>
