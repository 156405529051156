<section>
  <div class="container mt-5">
    <div class="row">
      <div class="col-12 mt-5">
        <h4 class="font-weight-bold text-center">Log in to</h4>
        <h4 class="font-weight-bold text-center">keep a track of your</h4>
        <h4 class="font-weight-bold text-center">energy usage.</h4>
        <p class="text-center mt-3">Feel the heatbeat of your home</p>
      </div>
    </div>
    <div class="row mt-5 d-flex justify-content-center">
      <div class="col-11">
        <form
          #form_verify="ngForm"
          class="form_verfiy"
          id="form_verfigy"
          name="form_verfigy"
        >
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Enter 10 digit mobile number"
              id="phoneNumber"
              [(ngModel)]="phoneNumber"
              name="phoneNumber"
              minlength="10"
              maxlength="10"
              pattern="\d{10}"
              required
            />
          </div>
          <!-- <div id="recaptcha-container" class="g-recaptcha"></div> -->
          <div class="form-group">
            <div
              id="recaptcha-container"
              class="g-recaptcha"
              data-callback="verifyRecaptchaCallback"
              data-expired-callback="expiredRecaptchaCallback"
            ></div>
            <input
              class="form-control d-none"
              data-recaptcha="true"
              required
              data-error="Please complete the Captcha"
            />
            <div class="help-block with-errors"></div>
          </div>

          <div class="opt_btn mt-5">
            <button type="submit" class="btn w-100" (click)="getOtp()">
              Send OTP
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row pp__tc">
      <div class="col-md-12 d-flex justify-content-center">
        <p class="mb-0">
          By clicking "Next" you are agreeing to Ohm Assistant's
        </p>
      </div>
      <div class="col-md-12 d-flex align-items-center justify-content-center">
        <a
          href="https://ohmassistant.com/Terms-and-Conditions"
          target="_blank"
          class="mr-3"
          >Terms of use</a
        >
        <a
          href="https://ohmassistant.com/Privacy-Policy-SustLabs"
          target="_blank"
          >Privacy policy</a
        >
      </div>
    </div>
  </div>
</section>
