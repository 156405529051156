import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CommonApiService } from 'src/app/service/common-api.service';

@Component({
  selector: 'app-selected-appliance-list',
  templateUrl: './selected-appliance-list.component.html',
  styleUrls: ['./selected-appliance-list.component.scss'],
})
export class SelectedApplianceListComponent implements OnInit {
  applianceName: any;
  appliance: any = [];
  constructor(
    public _router: Router,
    private route: ActivatedRoute,
    private _api: CommonApiService
  ) {}

  ngOnInit(): void {
    this.applianceName = this.route.snapshot.params['applianceName'];
    this.selectApplianceList();
  }

  selectApplianceList() {
    this._api
      .getSelectedAppliance(this.applianceName)
      .pipe(first())
      .subscribe((res) => {
        this.appliance = res;
        this.appliance.forEach((element) => {
          element.selected = false;
        });
      });
  }

  selectAppliance(appliance) {
    this._router.navigateByUrl(`/data-collection/${appliance._id}`);
  }
}
