import { first } from "rxjs/operators";
import { CommonApiService } from "./../../service/common-api.service";
import { Component, OnInit } from "@angular/core";
import { Chart, registerables } from "chart.js";
import * as moment from "moment";
import * as _ from "lodash";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-analysis",
  templateUrl: "./analysis.component.html",
  styleUrls: ["./analysis.component.scss"],
})
export class AnalysisComponent implements OnInit {
  public clicked: boolean = true;
  public clicked1: boolean = false;
  data: any;
  myChart: any;
  uptimePercentage: any;
  page: Number = 0;
  count: Number = 10;
  applianceUsage: any = [];
  total_energy: any;
  loader: boolean = false;
  output: any;
  answered: any;
  currentMonth: any;
  applianceDateTime: any;
  applianceTag: any;
  selectedApplianceDateTime: any;
  constructor(private _api: CommonApiService, private _router: Router) {}

  ngOnInit(): void {
    this.loader = true;
    this.getYesterday();
    this.getApplianceUsageLog();
    this.getAnalysis();

    this.currentMonth = moment().format("MMMM");
  }

  renderGraph(labels, data, background) {
    if (this.myChart != undefined) {
      this.myChart.destroy();
    }
    // var ctx = document.getElementById('myChart');
    var canvas: any = document.getElementById("myAnalysisChart");
    var ctx = canvas.getContext("2d");
    Chart.register(...registerables);
    this.myChart = new Chart(ctx, {
      type: "doughnut",

      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: background,
            borderWidth: 4,
          },
        ],
      },

      options: {
        cutout: 100,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
            beginAtZero: true,
          },
        },
      },
    });
  }

  getYesterday() {
    this.getAnalysis();
    let startTime = moment().startOf("day").toISOString();
    let endTime = moment().endOf("day").toISOString();

    this._api
      .getAnalysis(startTime, endTime)
      .pipe(first())
      .subscribe((res: Array<any>) => {
        let labels = [];
        let data = [];
        let background = [
          "#B298DC",
          "#FF9860",
          "#F9C74F",
          "#5DD39E",
          "#6DDAFF",
        ];
        this.loader = false;
        this.data = res["devices"];
        this.data = _.sortBy(this.data, "consumption").reverse();
        // this.total_energy = res["total_energy"];
        // this.data = this.data.filter((element) => {
        //   return element['device_name'] != 'Others';
        // });
        this.data.forEach((element) => {
          data.push(element["consumption"].toFixed(2));
          labels.push(element["device_name"]);
          // this.total_energy = element["energy"];
        });

        this.renderGraph(labels, data, background);
      });
    // var endDate = moment().startOf('day').toISOString();
    // var startDate = moment().subtract(1, 'day').startOf('day').toISOString();
    var startDate = moment().startOf("day").toISOString();
    var endDate = moment().endOf("day").toISOString();

    this._api
      .getUpTime(startDate, endDate)
      .pipe(first())
      .subscribe((res) => {
        this.uptimePercentage = res["uptimePercent"];
      });
  }
  getLast30days() {
    this.getAnalysisMonth();
    let startTime = moment().startOf("month").toISOString();
    let endTime = moment().endOf("month").toISOString();
    this._api
      .getAnalysis(startTime, endTime)
      .pipe(first())
      .subscribe((res: Array<any>) => {
        let labels = [];
        let data = [];
        let background = [
          "#B298DC",
          "#FF9860",
          "#F9C74F",
          "#5DD39E",
          "#6DDAFF",
        ];
        this.loader = false;
        this.data = res["devices"];
        this.data = _.sortBy(this.data, "consumption").reverse();
        // this.total_energy = res["total_energy"];
        // this.data = this.data.filter((element) => {
        //   return element['device_name'] != 'Others';
        // });
        this.data.forEach((element) => {
          data.push(element["consumption"].toFixed(2));
          labels.push(element["device_name"]);
          background.push(element["color"]);
        });
        this.renderGraph(labels, data, background);
      });
    // var endDate = moment().startOf("day").toISOString();
    // var startDate = moment().subtract(30, "day").startOf("day").toISOString();
    var endDate = moment().endOf("month").toISOString(); //the current time
    var startDate = moment().startOf("month").toISOString();

    this._api
      .getUpTime(startDate, endDate)
      .pipe(first())
      .subscribe((res) => {
        this.uptimePercentage = res["uptimePercent"];
      });
  }

  getAnalysis() {
    let startTime = moment().startOf("day").toISOString();
    let endTime = moment().endOf("day").toISOString();

    this._api
      .getAnalysisToday(startTime, endTime)
      .pipe(first())
      .subscribe((res: Array<any>) => {
        console.log(res);
        this.total_energy = res[0]["energy"];
      });
  }

  getAnalysisMonth() {
    let startTime = moment().startOf("month").format("MMYYYY");
    let endTime = moment().endOf("month").format("MMYYYY");

    this._api
      .getAnalysisMonth(startTime, endTime)
      .pipe(first())
      .subscribe((res: Array<any>) => {
        console.log(res);
        this.total_energy = res[0]["energy"];
      });
  }

  getApplianceUsageLog() {
    this._api
      .getApplianceUsageLogs(this.page, this.count)
      .pipe(first())
      .subscribe((res) => {
        this.loader = false;
        this.applianceUsage = res;

        // console.log(moment(dateTime).format('Do MMM YYYY, h:mm a'));
      });
  }

  ngOnDestroy() {
    if (this.myChart != undefined) {
      this.myChart.destroy();
    }
  }

  applianceTagModal(applianceUsage) {
    $("#energySelectDateModal").modal().show();
    this.applianceTag = applianceUsage;
    let dateTime = applianceUsage["start"];
  }

  tagAppliance(applianceTag) {
    this._router.navigateByUrl(
      `/select-appliance/${applianceTag._id}/${applianceTag.applianceName}`
    );
    $(".modal-backdrop").remove();
  }
  wrongDetection(applianceTag) {
    this._router.navigateByUrl(`/all-select-appliance/${applianceTag._id}`);
    $(".modal-backdrop").remove();
  }
}
