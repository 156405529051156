<section>
  <nav class="navbar navbar-expand-lg navbar-light transparent">
    <div class="col-6 p-0 d-flex align-items-center">
      <a routerLink="/vyas">
        <img src="../../../../assets/img/arrow-left.png" alt=""
      /></a>
      <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get">Add Device</a>
    </div>
  </nav>

  <div class="container-fluid mt-5">
    <div class="row top__row">
      <div class="col-12">
        <p class="text_color mt-4" *ngIf="step == 1">
          Select apppliance category
        </p>
        <p class="text_color mt-4" *ngIf="step == 2">
          How many appliance do you have?
        </p>
        <p class="text_color mt-4" *ngIf="step == 3">
          Enter appliances nicknames
        </p>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <!-- <div class="card">
          <div class="card-body"> -->
        <app-vyas-onboarding-page-one
          *ngIf="step == 1"
          [selectApplianceTypes]="selectApplianceTypes"
        ></app-vyas-onboarding-page-one>
        <app-vyas-onboarding-page-two
          *ngIf="step == 2"
          [applianceCounts]="applianceCounts"
        ></app-vyas-onboarding-page-two>
        <app-vyas-onboarding-page-three
          *ngIf="step == 3"
          [applianceSelected]="applianceSelected"
        ></app-vyas-onboarding-page-three>
        <button
          class="btn add_btn text-light w-50 d-block ml-auto mr-auto mt-5 mb-5"
          (click)="nextStep()"
        >
          NEXT
        </button>
        <!-- </div>
        </div> -->
      </div>
    </div>
  </div>
</section>
