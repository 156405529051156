import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

import { Chart, registerables } from "chart.js";
import * as moment from "moment";
import * as _ from "lodash";
import { first } from "rxjs/operators";
import { CommonApiService } from "src/app/service/common-api.service";

import * as puppeteer from "puppeteer";

@Component({
  selector: "app-file-download",
  templateUrl: "./file-download.component.html",
  styleUrls: ["./file-download.component.scss"],
})
export class FileDownloadComponent implements OnInit {
  @ViewChild("pdfDownload")
  pdfDownload!: ElementRef;
  public clicked: boolean = true;
  public clicked1: boolean = false;
  myChart: Chart;
  data: any;
  dataAnalysis: any;
  total_energy: any;
  energy_consumption: any;
  startDate: any;
  endDate: any;
  breakDownData: any = [];
  uptimePercentage: any;
  yearsFrom2018 = (moment().year() - 2018) * -1;
  breakDownBackground = ["#FF9860", "#F9C74F", "#5DD39E", "#6DDAFF", "#B298DC"];
  weekBgColors = [
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
  ];
  yearBgColors = [
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
    "#5DD39E",
  ];
  disableLeftClickWeek = false;
  disableLeftClickMonth = false;
  startTime: any;
  endTime: any;
  background: any;
  weekOfSet = 0;
  monthOfSet = 0;
  yearOfSet = 0;
  dailyRightClick = false;
  loader: boolean = false;
  page: Number = 0;
  count: Number = 6;
  applianceUsage: any;
  todayDate: any;
  todayMonth: any;
  selectedMonth: any;
  selectedDate: any;
  constructor(private _api: CommonApiService) {}

  ngOnInit(): void {
    this.getBreakdownOfAppliances();
    this.getAnalysisDaily();
  }

  downloadAsPDF() {
    const pdfDownload = this.pdfDownload.nativeElement;
    console.log(pdfDownload);

    var html = htmlToPdfmake(pdfDownload.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download();
  }

  renderGraph(labels, data, background) {
   

    var canvas: any = document.getElementById("myDetailedAnalysisChart");

    var ctx = canvas.getContext("2d");
    Chart.register(...registerables);
    this.myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            borderColor: background,
            backgroundColor: background,
            borderWidth: 1,
            barPercentage: 0.6,
          },
        ],
      },
      options: {
        indexAxis: "y",
        plugins: {
          title: {
            display: true,
            text: "Total consumption",
          },
          legend: {
            display: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            beginAtZero: true,

            grid: {
              display: false,
            },
          },
          y: {
            stacked: false,
          },
        },
      },
    });
  }
  getBreakdownOfAppliances() {
    this.getAnalysis;
    let startTime = moment().startOf("day").toISOString();
    let endTime = moment().endOf("day").toISOString();
    this._api
      .getAnalysis(startTime, endTime)
      .pipe(first())
      .subscribe((res: Array<any>) => {
        let labels = [];
        let data = [];
        let background = [
          "#B298DC",
          "#FF9860",
          "#F9C74F",
          "#5DD39E",
          "#6DDAFF",
        ];
        this.loader = false;
        this.data = res["devices"];
        this.data = _.sortBy(this.data, "consumption").reverse();
        // this.total_energy = res["total_energy"];
        // this.data = this.data.filter((element) => {
        //   return element['device_name'] != 'Others';
        // });
        this.data.forEach((element) => {
          data.push(element["consumption"].toFixed(2));
          labels.push(element["device_name"]);
          // this.total_energy = element["energy"];
        });

        this.renderGraph(labels, data, background);
      });
  }

  getAnalysis() {
    let startTime = moment().startOf("day").toISOString();
    let endTime = moment().endOf("day").toISOString();

    this._api
      .getAnalysisToday(startTime, endTime)
      .pipe(first())
      .subscribe((res: Array<any>) => {
        console.log(res);
        this.total_energy = res[0]["energy"];
      });
  }

  renderDailyGraph(labels, data, background) {
  

    var canvas: any = document.getElementById("analysisChart");
    console.log(canvas);
    

    var ctx = canvas.getContext("2d");
    Chart.register(...registerables);
    this.myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            borderColor: background,
            backgroundColor: this.weekBgColors,
            borderWidth: 1,
            barPercentage: 0.4,
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "Total consumption",
          },
          legend: {
            display: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            beginAtZero: true,

            grid: {
              display: false,
            },
          },
          y: {
            stacked: false,
          },
        },
      },
    });
  }
  getAnalysisDaily() {
    this.startTime = moment()
      .startOf("week")
      .add(this.weekOfSet * 7 + 1, "day")
      .toISOString();
    this.endTime = moment()
      .endOf("week")
      .add(this.weekOfSet * 7 + 1, "day")
      .toISOString();

    // Fetch data for all the days in week
    this._api
      .getAnalysisToday(this.startTime, this.endTime)
      .pipe(first())
      .subscribe((res: Array<any>) => {
        this.dataAnalysis = res;

        let labels = [];
        let data = [];

        // enable or disable left/right click buttons

        if (res.length < 7) {
          this.disableLeftClickWeek = true;
        } else {
          this.disableLeftClickWeek = false;
        }
        this.loader = false;
        let background = "#DFDFDF";

        // maintain a flag for last colored (pre-selected item)
        let preselectedDay = false;
        this.todayDate = moment()
          .add(this.weekOfSet * 7, "day")
          .format("ll");
        // res.push(res.shift());
        res.forEach((element, index) => {
          data.push(element["energy"].toFixed(2));
          labels.push(element["day"]);

          // If day in array is today, preselect (colored)
          if (
            moment(element["date"]).format("YYYYMMDD") ==
            moment().format("YYYYMMDD")
          ) {
            this.selectedDate = moment().format("DD MMM, YYYY");
            this.weekBgColors[index] = "#1F6B3A";
            this.total_energy = element["energy"].toFixed(0);

            preselectedDay = true;
          } else {
            this.weekBgColors[index] = "#5DD39E";
          }
        });

        if (!preselectedDay) {
          this.weekBgColors[res.length - 1] = "#1F6B3A";
          this.selectedDate = moment(res[res.length - 1]["date"]).format(
            "DD MMM, YYYY"
          );
        }

        // this.renderGraph(labels, data);

        this.renderDailyGraph(labels, data, background);
      });
  }
}
