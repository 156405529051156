<app-loader *ngIf="loader"></app-loader>
<section class="profile_section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-md-12">
              <nav class="navbar navbar-expand-lg navbar-light transparent">
                <div class="col-6 p-0 d-flex align-items-center">
                  <a routerLink="/my-bot">
                    <img
                      src="../../../assets/img/arrow-left.png"
                      alt=""
                      class="img-fluid d-block m-auto"
                  /></a>
                  <a class="mb-0 pl-3 pr-3 d-lg-inline-block"> Back </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid p-0 edit__container">
    <div class="row">
      <div class="col-12 mt-5 px-4">
        <div class="card">
          <div class="card-body">
            <div class="row top__row">
              <div class="col-6">
                <a routerLink="/my-bot" class="title_get">Cancel</a>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <a
                  class="title_get"
                  style="cursor: pointer"
                  (click)="saveDetails()"
                  >Done</a
                >
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <p class="font-weight-bold">Utility details</p>
              </div>
            </div>

            <form>
              <div class="row profile__edit mt-3">
                <div class="col-4">State</div>
                <div
                  class="col-8 d-flex justify-content-end align-items-center"
                >
                  <select
                    name="state"
                    [(ngModel)]="selectedStateName"
                    (change)="stateSelect($event)"
                  >
                    <option *ngFor="let state of allStates">
                      {{ state.stateName }}
                    </option>
                  </select>
                </div>
                <div
                  class="col-8 d-flex justify-content-end align-items-center"
                >
                  <p *ngIf="!allStates">---</p>
                </div>
              </div>
              <div class="row profile__edit mt-3">
                <div class="col-4">Utility Name</div>
                <div
                  class="col-8 d-flex justify-content-end align-items-center"
                  *ngIf="utility"
                >
                  <select
                    class="mt-3 mb-3"
                    aria-placeholder="Select Board Name"
                    name="boardName"
                    [(ngModel)]="selectedUtilityName"
                    (change)="boardSelect($event)"
                    style="width: 100%"
                  >
                    <option disabled>Select Board Name</option>
                    <option
                      *ngFor="let board of selectedState.utilityBoards"
                      value="{{ board.boardName }}"
                    >
                      {{ board.boardName }}
                    </option>
                  </select>
                </div>
                <div
                  class="col-8 d-flex justify-content-end align-items-center"
                >
                  <p *ngIf="!utility">---</p>
                </div>
              </div>
              <div class="row profile__edit mt-3">
                <div class="col-4">Consumer ID</div>
                <div
                  class="col-8 d-flex justify-content-end align-items-center"
                  *ngIf="utility"
                >
                  <input
                    type="text"
                    [(ngModel)]="utility.consumerId"
                    name="consumerId"
                    autofocus
                  />
                </div>
              </div>
              <div class="row profile__edit mt-3">
                <div class="col-4">Billing cycle</div>
                <div
                  class="col-8 d-flex justify-content-end align-items-center"
                  *ngIf="utility"
                >
                  <input
                    type="text"
                    [(ngModel)]="billingDate"
                    name="billingDate"
                    (click)="dateModal()"
                  />
                </div>
                <div
                  class="col-8 d-flex justify-content-end align-items-center"
                >
                  <p *ngIf="!utility">---</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div
  class="modal fade modal-bottom"
  id="dateModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dateModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div
      class="modal-content pb-5"
      style="border-top-left-radius: 10px; border-top-right-radius: 10px"
    >
      <div class="modal-body d-flex justify-content-center">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <table class="table">
                <tbody>
                  <tr>
                    <td (click)="dateSelected(1)">1</td>
                    <td (click)="dateSelected(2)">2</td>
                    <td (click)="dateSelected(3)">3</td>
                    <td (click)="dateSelected(4)">4</td>
                    <td (click)="dateSelected(5)">5</td>
                    <td (click)="dateSelected(6)">6</td>
                    <td (click)="dateSelected(7)">7</td>
                  </tr>
                  <tr>
                    <td (click)="dateSelected(8)">8</td>
                    <td (click)="dateSelected(9)">9</td>
                    <td (click)="dateSelected(10)">10</td>
                    <td (click)="dateSelected(11)">11</td>
                    <td (click)="dateSelected(12)">12</td>
                    <td (click)="dateSelected(13)">13</td>
                    <td (click)="dateSelected(14)">14</td>
                  </tr>
                  <tr>
                    <td (click)="dateSelected(15)">15</td>
                    <td (click)="dateSelected(16)">16</td>
                    <td (click)="dateSelected(17)">17</td>
                    <td (click)="dateSelected(18)">18</td>
                    <td (click)="dateSelected(19)">19</td>
                    <td (click)="dateSelected(20)">20</td>
                    <td (click)="dateSelected(21)">21</td>
                  </tr>
                  <tr>
                    <td (click)="dateSelected(22)">22</td>
                    <td (click)="dateSelected(23)">23</td>
                    <td (click)="dateSelected(24)">24</td>
                    <td (click)="dateSelected(25)">25</td>
                    <td (click)="dateSelected(26)">26</td>
                    <td (click)="dateSelected(27)">27</td>
                    <td (click)="dateSelected(28)">28</td>
                  </tr>
                  <tr>
                    <td (click)="dateSelected(29)">29</td>
                    <td (click)="dateSelected(30)">30</td>
                    <td (click)="dateSelected(31)">31</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
