<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light transparent">
          <div class="col-6 p-0 d-flex align-items-center">
            <a routerLink="/energy-efficiency">
              <img
                src="../../../assets/img/arrow-left.png"
                alt=""
                class="img-fluid d-block m-auto"
            /></a>
            <a class="mb-0 pl-3 pr-3 d-lg-inline-block title_get"
              >Energy Efficiency</a
            >
          </div>
          <div class="form-inline my-2 my-lg-0">
            <!-- <img src="../../../assets/img/menu.png" alt="" /> -->
          </div>
        </nav>
      </div>
    </div>
    <div class="row mt-4 more__card">
      <div class="col-md-12 mt-4 px-4">
        <h5 class="font-weight-bold mt-4">Energy efficiency program</h5>
      </div>
      <form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
        <div class="col-md-12 mt-4">
          <h6>Participant 1</h6>
        </div>
        <div class="col-md-12 mt-3">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Ex-Rohan Gupta"
                  name="participant1Name"
                  [(ngModel)]="participant1Name"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Mobile number</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Ex-8976020242"
                  name="participant1Mobile"
                  [(ngModel)]="participant1Mobile"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <h6>Participant 2 (Female Mandatory)</h6>
        </div>
        <div class="col-md-12 mt-3">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Ex-Vartika Gupta"
                  name="participant2Name"
                  [(ngModel)]="participant2Name"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Mobile number</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Ex-8976020242"
                  name="participant2Mobile"
                  [(ngModel)]="participant2Mobile"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <h6>Other details</h6>
        </div>
        <div class="col-md-12 mt-3">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Utility provider</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Ex-MSBE"
                  name="utilityProvider"
                  [(ngModel)]="utilityProvider"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Pincode</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Ex-400078"
                  name="pincode"
                  [(ngModel)]="pincode"
                  required
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="email">Consumer ID</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Ex-604711458023056"
                  name="consumerId"
                  [(ngModel)]="consumerId"
                  required
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="email">Email ID</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Ex-shikha.aggarwal@sustlabs.com "
                  name="emailId"
                  [(ngModel)]="emailId"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-12 btn__bottom d-flex justify-content-center mt-4 mb-3"
        >
          <button class="btn" [disabled]="!f.form.valid">Submit Details</button>
        </div>
      </form>
    </div>
  </div>
</section>
